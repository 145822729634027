//IMPORTS
import React, {
	useState,
	useEffect
} from "react"
import {
	useSelector
} from "react-redux"
import { default as SelectComponent } from "react-select";
import {
	RootState
} from "./../../../globals"
import Constants from "./../../../constants"

//STYLES
import { styles }  from "./styles";

//INTERFACE COMPONENT
export interface UpdateCampusInfoProps extends React.HTMLAttributes<HTMLDivElement> {
    saveLock: {
        saveLock: any,
        setSaveLock: any
    };
    currentStudentUpdate: any;
    addNewCampusData: {
        addNewCampusData: any,
        setAddNewCampusData: any
    };
}

//COMPONENT
const UpdateCampusInfo: React.FC<UpdateCampusInfoProps> = ({ saveLock, currentStudentUpdate, addNewCampusData })=>{

    //useSelector
    const campusOptions: any = useSelector((state: RootState) => state.rootReducer.catalog.campuses);

    //useState
	const [activeCampuses, setActiveCampuses] = useState<any>([]);
	const [availableCampuses, setAvailableCampuses] = useState<any>([]);

    //useEffect
    useEffect(() => {
		if (currentStudentUpdate && currentStudentUpdate.campuses) {
			const activeCampusesId = currentStudentUpdate.campuses.map((campus: any) => campus.campus_id);
			const activeCampuses = campusOptions.filter((campus: any) => activeCampusesId.includes(campus.id));
			const availableCampuses = campusOptions.filter((campus: any) => !activeCampusesId.includes(campus.id));
			setActiveCampuses(activeCampuses);
			setAvailableCampuses(availableCampuses);
		}
	}, [currentStudentUpdate])

	useEffect(() => {
		if (addNewCampusData.addNewCampusData && addNewCampusData.addNewCampusData.campus && addNewCampusData.addNewCampusData.campus.id) {
			saveLock.setSaveLock(false);
		}else{
			saveLock.setSaveLock(true);
		}
	}, [addNewCampusData.addNewCampusData])

    //VIEW
	return(
        <React.Fragment>
            {
                <div className=" updateCampusInfo col-md-6 d-flex flex-column">
                    <div className="student d-flex flex-row mb-3">
                        <label 
                            className="title"
                            style={styles.updateCampusInfo.student.title.v}>
                            {Constants.Common.name} : 
                        </label>
                        {currentStudentUpdate?
                            <span
                                className="name"
                                style={styles.updateCampusInfo.student.name.v}>
                                {currentStudentUpdate.student.first_name} {currentStudentUpdate.student.last_name}
                            </span>
                            :null
                        }
                    </div>
                    <div className="activeCampuses d-flex flex-column mb-3">
                        <label 
                            className="title"
                            style={styles.updateCampusInfo.activeCampuses.title.v}>
                            {Constants.Common.activeCampuses} :
                        </label>
                        {
                            activeCampuses.length ?
                            activeCampuses.map((campus: any) => 
                                <span
                                    key={campus.id}
                                    className="campus"
                                    style={styles.updateCampusInfo.activeCampuses.campus.v}>
                                    {campus.label}
                                </span>
                            )
                            :null
                        }
                    </div>
                    <div className="availableCampuses d-flex flex-column mb-3">
                        <label
                            className="title"
                            style={styles.updateCampusInfo.availableCampuses.title.v}>
                            {Constants.Common.addToCampus} :
                        </label>
                        <SelectComponent
                            id="campusesOptions"
                            options={availableCampuses}
                            onChange={(value) => {
                                addNewCampusData.setAddNewCampusData({
                                    ...addNewCampusData.addNewCampusData,
                                    campus: value,
                                    student: currentStudentUpdate.student
                                });
                            }}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default UpdateCampusInfo