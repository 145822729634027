import React,
{
	useRef,
	useState,
	useEffect,
	useReducer,
	FormEvent
}
	from "react"
import {useLocation}
	from "react-router-dom"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import
{
	solid,
	regular
}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useSelector}
	from "react-redux"
import
{
	OverlayTrigger,
	Tab,
	Tabs,
	Tooltip
}
	from "react-bootstrap"
import {Popover}
	from "react-tiny-popover"
import Multiselect
	from "multiselect-react-dropdown"
import _
	from "lodash"

import {RootState}
	from "./../globals"
import CustomIcon
	from "./customIcon"
	
import UnderlineHeading
	from "./underlineHeading"
import {SelectReact}
	from "./select"
import LeDatePicker
	from "./leDatePicker"

import "./dropdown/style.css"

import Modal
	from "react-modal"

import
{
	borderUI,
	buttonClickHandling,
	buttonUI,
	colorUI,
	iconUI,
	keyHandling,
	imageTypes,
	isEmpty,
	messageUI,
	monthRef,
	textUI,
	themes,
	toAscendingDate,
	UIState,
	validateField,
}
	from "./../common"

import defaultUserImage
	from "./../assets/images/user.png"
import {sprintf} from "sprintf-js"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type  Action = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled" : return{ ...state, isButtonDisabled : action.payload}
	}
}

export const DataDisplayModal = (props : any)=>
{
	const
	{
		secondAction,
		headIcon,
		entityName,
		uneditFn,
		unfilledFields,
		unchangedRecord,
		wrongValueOnFields,
		cornerDismissalCallback,
		searchFn,
		allowedUpdate,
		clearSearchFn,
		clearSearchLock,
		edittingRefusal,
		children,
		processingError,
		saveButtonRef,
		searchButtonRef,
		saveLock,
		saveDataFn,
		editableRecord,
		saveSuccess,
		promptCloseFn,
		UIStatus,
		dataEditInit
	} = props

	const themeToggle    : boolean = useSelector((state : RootState) => state.themeToggle.value)
	const idleLocked     : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)
	//Variable bypass because of building issues-----------------------------------------------------------------------------
	let   saveOptRef     : any     = useRef<HTMLButtonElement>(null)
	let   searchOptRef   : any     = useRef<HTMLButtonElement>(null)
	let   saveCmdRef     : any     = saveButtonRef || saveOptRef
	let   searchCmdRef   : any     = searchButtonRef || searchOptRef
	const optionalAction : any     = secondAction ? secondAction : uneditFn

	return <Modal appElement={document.getElementById("root") as HTMLElement} className={themes[ +themeToggle ]}
	  style={{overlay : {zIndex : 100}, content : {height : "min-content", background : "var(--main-bg)", borderRadius : "15px"}}}
	  isOpen
	>
		<div style={{padding : "12px 15px 7px 15px"}}>
			<div style={{display : "table-row"}}>
				<div style={{display : "table-cell", color : "var(--txt-color)", width : "100%", paddingTop : "5px"}}><table>
					<tbody><tr>
						<td >
							{headIcon || ""}
						</td>
						<td>
							<span className="d-none d-sm-inline-block" style={{paddingLeft : "5px"}}>
								{entityName || ""}
							</span>
							&nbsp;
							{
								unfilledFields
										?
									<b className="text-danger">
										[Campos requeridos sin llenar]
									</b>
										:
									(
										unchangedRecord
												?
											<>
												<b className="text-danger d-none d-md-inline-block">
													[Sin cambios registrados]
												</b>
												<span className="d-md-none d-sm-inline-block">
													<FontAwesomeIcon icon={solid("equals")} />
												</span>
											</>
												:
											(
												wrongValueOnFields
														?
												<>
													<b className="text-danger d-none d-md-inline-block">
														[Valores incorrectos]
													</b>
													<span className="d-md-none d-sm-inline-block">
														<FontAwesomeIcon icon={solid("triangle-exclamation")} />
													</span>
												</>
														:
												<>
													<span className="d-none d-md-inline-block">{
														searchFn ? "(Búsqueda avanzada)" :
														(
															(
																editableRecord &&
																	((editableRecord.id && "(Edición)") || "(Nuevo registro)")
															)
																||
															"(Información)"
														)
													}</span>
													<span className="d-md-none d-sm-inline-block">{
														searchFn ? <CustomIcon name={"advancedSearch"} /> :
														<FontAwesomeIcon icon=
														  {
															(
																editableRecord &&
																(
																	(editableRecord.id && solid("pen-to-square")) ||
																		solid("asterisk")
																)
															)
																||
															solid("circle-info")
														  }
														/>
													}</span>
												</>
											)
								)
							}
						</td>
					</tr></tbody>
				</table></div>
				<div style={{display : "table-cell", verticalAlign : "top"}}><button disabled={idleLocked || saveSuccess}
				  className="button btn btn-default" type="button" onClick=
				  {
					()=>
					{
						promptCloseFn()

						if(cornerDismissalCallback)
						{
							cornerDismissalCallback()
						}
					}
				  } style=
				  {{
					color        : "var(--txt-color)",
					background   : "var(--main-bg)",
					borderRadius : "50%",
					width        : "40px",
					border       : "1px solid #CCCCCC",
					padding      : "2px 0px 0px 2px"
				  }}
				>
					<FontAwesomeIcon icon={solid("times")} size="2x" />
				</button></div>
			</div>
			{
				(searchFn == null && (editableRecord == null && (dataEditInit == null || allowedUpdate != true)))
						?
					(
						isEmpty(edittingRefusal) ? "" : <div className="badge badge-info" style={{position : "relative", zIndex : 3}}>
							<FontAwesomeIcon icon={solid("circle-info")} />
							<span style={{paddingLeft : "5px"}}>
								{edittingRefusal}
							</span>
						</div>
					)
						:
					<div style={{display : "inline-block", position : "absolute", zIndex : 2}}>
						<div style={{position : "relative", bottom : "12px", display : "flex"}}>
							<div className="hexL">
								<div className="hexL0" />
							</div>
							<div className="hex" style={{paddingTop : "1px"}}>{
								searchFn
										?
									<div style={{display : "inline-flex"}}>
										<div style={{display : "inline-grid", margin : "0px 5px", width : "fit-content"}}>
											<button type="button"
											  disabled={idleLocked || UIStatus == UIState.SUCCESS} className="rounded-pill btn btn-default"
											  style={{display : "table-row", border : "2px solid #8F91DA", color : "#8F91DA"}} id="secondaryAction"
											  onClick={() => clearSearchFn(true)}
											>
												<span style={{display : "table-cell"}}>
													<FontAwesomeIcon icon={solid("circle-xmark")} />
												</span>
												<span style={{paddingLeft : "10px"}} className="d-none d-md-table-cell">
													Limpiar
												</span>
											</button>
										</div>
										<div style={{display : "inline-grid", margin : "0px 5px", width : "fit-content"}}>
											<button type="button"
											  disabled={clearSearchLock} className="rounded-pill btn btnPurple" id="mainAction"
											  style={{display : "table-row"}} onClick={searchFn} ref={searchCmdRef}
											>
												<span style={{display : "table-cell"}}>
													<FontAwesomeIcon icon={solid("magnifying-glass")} />
												</span>
												<span className="d-none d-md-table-cell" style={{paddingLeft : "10px"}}>
													Buscar
												</span>
											</button>
										</div>
									</div>
										:
									(
										editableRecord == null
												?
											<div style={{display : "inline-grid", margin : "0px 5px", width : "fit-content"}}>
												<button style={{display : "table-row", color : "#FFFFFF"}} className="rounded-pill btn btn-info"
												  onClick={dataEditInit} disabled={idleLocked || UIStatus == UIState.SUCCESS}
												  type="button"
												>
													<span style={{display : "table-cell"}}>
														<FontAwesomeIcon icon={solid("pencil")} />
													</span>
													<span style={{paddingLeft : "10px", width : "73px"}} className="d-none d-md-table-cell">
														Editar
													</span>
												</button>
											</div>
												:
											<div style={{textAlign : "right", position : "sticky", left : "0px"}}>
												<div style={{display : "inline-flex"}}>
													{
														(secondAction == null && uneditFn == null) ? "" : <div
														  style={{display : "inline-grid", margin : "0px 5px", width : "fit-content"}}
														><button style={{display : "table-row", border : "2px solid #8F91DA", color : "#8F91DA"}}
														  disabled={idleLocked || UIStatus == UIState.SUCCESS} onClick={optionalAction}
														  className="rounded-pill btn btn-default" id="secondaryAction" type="button"
														>
															<span style={{display : "table-cell"}}>
																<FontAwesomeIcon icon={secondAction ? solid("play-circle") : solid("ban")} />
															</span>
															<span style={{paddingLeft : "10px"}} className="d-none d-md-table-cell">
																{secondAction != null ? "Guardar y continuar" : "Deshabilitar"}
															</span>
														</button></div>	
													}
													<div style={{display : "inline-grid", margin : "0px 5px", width : "fit-content"}}>
														<button
														  ref={saveCmdRef}
														  className="rounded-pill btn btnPurple"
														  disabled={saveLock}
														  onClick={saveDataFn}
														  style={{display : "table-row"}}
														  type="button"
														  id="mainAction"
														>
															<span style={{display : "table-cell"}}>
																<FontAwesomeIcon icon={solid("check-circle")} />
															</span>
															<span
															  className="d-none d-md-table-cell"
															  style={{paddingLeft : "10px"}}
															>
																Guardar
															</span>
														</button>
													</div>
												</div>
											</div>
									)
							}</div>
							<div className="hexR">
								<div className="hexR0" />
							</div>
						</div>
					</div>
			}
		</div>
		<div className={"card " + (UIStatus == null ? "" : borderUI[UIStatus])}
		  style={{width : "100%", padding : "unset", maxHeight : "calc(90vh - 4em)"}}
		>
			<div style={{padding : "30px 30px 10px", overflowY : "auto"}}>
				{children}
				<br />
			</div>
			{
				(UIStatus == UIState.NORMAL || UIStatus == null) ? "" : <div className={buttonUI[UIStatus]}>
					<div style={{margin : "unset", borderRadius : "0px 0px 5px 5px", color : "#FFFFFF"}}
					  className={"alert " + textUI[UIStatus]}
					>
						<div style=
						  {{
							border       : "1px solid #" + colorUI[UIStatus],
							display      : "inline-block",
							width        : "20px",
							color        : "#000000",
							height       : "20px",
							background   : "#FFFFFF",
							borderRadius : "50%",
							margin       : "auto",
							paddingTop   : "2px"
						  }}
						>
							<FontAwesomeIcon style={{position : "relative", bottom : "3px"}} icon={iconUI[UIStatus]} />
						</div>
						&nbsp;
						<span className={textUI[UIStatus]} style={{position : "relative", bottom : "4px"}}>
							{messageUI[UIStatus]}{(processingError || "")}
						</span>
					</div>
				</div>
			}
		</div>
	</Modal>
}

export const UserPrompt = (props : any)=>
{
	const
	{
		selectedUser,
		promptCloseFn,
		additional,
		saveDataFn,
		unfilledFields,
		profileView,
		employeeView,
		allowed
	} = props

	const basicInfo         : any                 = useSelector((state : RootState) => state.basicInfo.value)
	const idleLocked        : boolean             = useSelector((state : RootState) => state.idleLockSwitch.value)
	const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                 = useSelector((state : RootState) => state.campusRef.value)
	const [currentTab, setCurrentTab]             = useState<string | null>("mainInfo")
	const [saveSuccess, setSaveSuccess]           = useState<boolean>(false)
	const [saveError, setSaveError]               = useState<any>(null)
	const [editableUser, setEditableUser]         = useState<any>(null)
	const [UIStatus , setUIStatus]                = useState<number>(UIState.NORMAL)
	const [state, trigger]                        = useReducer(reducer, initialState)
	const [noCityProvided, setNoCityProvided]     = useState<boolean>(false)
	const [unfilledMainData, setUnfilledMainData] = useState<boolean>(false)
	let   userFormRef                             = useRef<HTMLFormElement >(null)
	let   firstNameRef                            = useRef<HTMLInputElement>(null)
	let   lastNameRef                             = useRef<HTMLInputElement>(null)

	let   zipcodeRef                   = useRef<HTMLInputElement>(null)
	let   locationRef                  = useRef<HTMLInputElement>(null)
	let   imageRef                     = useRef<HTMLInputElement>(null)
	let   countrySelRef                = useRef<HTMLSelectElement>(null)
	let   nationalitySelRef            = useRef<HTMLSelectElement>(null)
	let   stateSelRef                  = useRef<HTMLSelectElement>(null)
	let   stateNameRef           : any = useRef<HTMLInputElement>(null)
	let   cityNameRef            : any = useRef<HTMLInputElement>(null)
	let   addressRef                   = useRef<HTMLInputElement>(null)
	let   emailRef                     = useRef<HTMLInputElement>(null)
	let   identificationCodeRef        = useRef<HTMLInputElement>(null)
	let   passwordRef                  = useRef<HTMLInputElement>(null)
	let   phoneRef                     = useRef<HTMLInputElement>(null)
	let   idClase365Ref                = useRef<HTMLInputElement>(null)
	let   lastSchoolNameRef            = useRef<HTMLInputElement>(null)
	let   academicGradeRef             = useRef<HTMLInputElement>(null)
	let   currentOccupationRef         = useRef<HTMLInputElement>(null)
	let   observationsRef              = useRef<HTMLTextAreaElement>(null)
	let   tabDivRef                    = useRef<HTMLDivElement>(null)
	let   statusPrefix    : string[]   = ["SS_", "ES_"]
	let   statusIndex     : string[]   = ["studentStatusRef", "employeeStatusRef"]
	let   title           : string[]   = ["Alumno", "Profesor"]
	let   fileReader : FileReader
	const [linkInfo, setLinkInfo] = useState<any>
	({
		facebook  : "Facebook",
		instagram : "Instagram"
	})
	// console.log('deste top nav', currentUserRolSlice)
	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(<>
					Sólo se permiten imágenes con las siguientes características:
					<br />
					&emsp;Tamaño &lt;= 5MB, &nbsp;Formato: [BMP, GIF, JPEG, PNG, WEBP]
				</>)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let user             = {...editableUser, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any)=>
				{
					setEditableUser({...editableUser, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if(imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		setEditableUser
		({
			...editableUser, image : null,
			imagePreview           : null,
		})
	}

	const handleRegistryChange = (event : any)=>
	{
		if(event && event.target)
		{
			setEditableUser({...editableUser, [event.target.name] : event.target.value})
		}
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(editableUser)
			{
				trigger
				({
					type    : "setIsButtonDisabled",
					payload : (UIStatus == UIState.SUCCESS || unfilledFields) || noCityProvided
				})
			}
		},
		[editableUser, UIStatus, unfilledFields, noCityProvided]
	)

	useEffect
	(
		()=>
		{
			if(selectedUser.id == null)
			{
				setEditableUser(selectedUser)
			}
		},
		[selectedUser]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	return <DataDisplayModal editableRecord={editableUser} saveSuccess={saveSuccess} uneditFn={() => setEditableUser(null)}
	  allowed={allowed} promptCloseFn={promptCloseFn} entityName={title[ +(employeeView == true) ]} saveLock={state.isButtonDisabled}
	  processingError={saveError} dataEditInit={() => setEditableUser({...selectedUser, password : ""})} saveDataFn={saveDataFn}
	  headIcon={employeeView ? <FontAwesomeIcon icon={solid("person-chalkboard")} /> : <CustomIcon name="student" />}
	  UIStatus={UIStatus} unfilledFields={unfilledFields}
	><div className="container"><div className="row">
		<div className="col-md-4 text-center">
			<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
				<img
				  className="relatedPicture"
				  src=
				  {
					(editableUser == null ? selectedUser.imagePreview : editableUser.imagePreview)
						|| defaultUserImage
				  }
				/>
				{
					editableUser == null ? "" : <>
						<input ref={imageRef} onChange={(event) => imageLoad(event)} name="image" style={{display : "none"}}
						  type="file" id="image" accept="image/*"
						/>
						{
							editableUser.image ? "" : <button className="btn btn-danger rounded-pill" type="button"
							  onClick={imageDisposal} disabled={idleLocked} style=
							  {{
								position   : "relative",
								bottom     : "40px",
								left       : "15%",
								paddingTop : "5px"
							  }}
							>
								<FontAwesomeIcon icon={solid("times")} fill="unset" size="2x"/>
							</button>
						}
						<button onClick={() => buttonClickHandling(imageRef)} disabled={idleLocked} className="btn rounded-pill"
						  type="button" style=
						  {{
							border     : "1px solid var(--main-bg-color)",
							color      : "var(--secondary-txt-color)",
							position   : "relative",
							background : "#C7C7C7",
							height     : "56px",
							bottom     : "40px",
							left       : "16%",
						  }}
						>
							<FontAwesomeIcon icon={solid("camera")} size="2x" fill="unset" style={{color : "#000000"}} />
						</button>
					</>
				}
			</div>
			<br />
			<br />
			<div className="inputContainer text-start">
				<label htmlFor="status_id" style={{position : "relative"}}>
					Correlativo
				</label>
				<div className="roView">
					{selectedUser.student?.identity_code || "-"}
				</div>
			</div>
			<div className="inputContainer text-start">
				<label htmlFor="correlativo_clase_365">
					Correlativo Clase365
				</label>
				<div className="roView">
					<br />
					{selectedUser?.correlativo_clase_365 || "-"}
				</div>
			</div>
			{
				employeeView ? "" : <>{
					editableUser || selectedUser.campuses== null ? "" : <div style={{textAlign : "left"}}>
						<fieldset style={{padding : "0px 15px"}}>
							<legend>
								<b style={{color : "#5154AE", fontSize : "14px", whiteSpace : "nowrap"}}>
									Saldo mensualidades
								</b>
							</legend>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Vencido
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", whiteSpace : "nowrap", color : selectedUser.expiredMonthlyBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.expiredMonthlyBalance)}
								</div>
							</div>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Total
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", whiteSpace : "nowrap", color : selectedUser.monthlyBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.monthlyBalance)}
								</div>
							</div>
						</fieldset>
						<div style={{padding : "15px"}}>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Saldo kits
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", flexBasis : "50%", whiteSpace : "nowrap", color : selectedUser.kitsBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.kitsBalance)}
								</div>
							</div>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Saldo otros
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", flexBasis : "50%", flexGrow : 1, whiteSpace : "nowrap", color : selectedUser.othersBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.othersBalance)}
								</div>
							</div>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Crédito a favor
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", flexBasis : "50%", flexGrow : 1, whiteSpace : "nowrap", color : selectedUser.positiveBalance == 0 ? "#000000" : "#00FF00"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.positiveBalance)}
								</div>
							</div>
						</div>
					</div>
				}</>
			}
			{
				employeeView ? "" : <>{
					editableUser || selectedUser.campuses== null ? "" : <div style={{textAlign : "left"}}>
						<fieldset style={{padding : "0px 15px"}}>
							<legend>
								<b style={{color : "#5154AE", fontSize : "14px", whiteSpace : "nowrap"}}>
									Saldo mensualidades
								</b>
							</legend>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Vencido
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", whiteSpace : "nowrap", color : selectedUser.expiredMonthlyBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.expiredMonthlyBalance)}
								</div>
							</div>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Total
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", whiteSpace : "nowrap", color : selectedUser.monthlyBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.monthlyBalance)}
								</div>
							</div>
						</fieldset>
						<div style={{padding : "15px"}}>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Saldo kits
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", flexBasis : "50%", whiteSpace : "nowrap", color : selectedUser.kitsBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.kitsBalance)}
								</div>
							</div>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Saldo otros
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", flexBasis : "50%", flexGrow : 1, whiteSpace : "nowrap", color : selectedUser.othersBalance == 0 ? "#000000" : "#C32722"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.othersBalance)}
								</div>
							</div>
							<div className="fluentBox">
								<div className="fluentBlockXs" style={{padding : "0px 5px"}}>
									<b>
										Crédito a favor
									</b>
								</div>
								<div className="text-end fluentBlockXs" style={{padding : "0px 5px", flexBasis : "50%", flexGrow : 1, whiteSpace : "nowrap", color : selectedUser.positiveBalance == 0 ? "#000000" : "#00FF00"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									&nbsp;
									{sprintf(" %.02f", +selectedUser.positiveBalance)}
								</div>
							</div>
						</div>
					</div>
				}</>
			}
			{
				basicInfo.tags.length< 1 || selectedUser.tags == null ? "" : <div className="card card-default"
				  style={{padding : "15px"}}
				>
					<div className="underlineHeadingContainer" style={{marginBottom : "unset"}}>
						Intereses
					</div>
					{
						editableUser == null
							?
						<div>{
							selectedUser.tags.length < 1 ? "-" : selectedUser.tags.map
							(
								(record : any) => <div className="badge badge-pill bg-primary"
								  key={"cr_" + record.id}
								>
									{record.name}
								</div>
							)
						}</div>
							:
						<>
							<label htmlFor="tags" style={{position : "unset"}}>
								Actualice
							</label>
							<Multiselect emptyRecordMsg="No hay más opciones" selectedValues={editableUser.tags}
							  onSelect={(tags) => setEditableUser({...editableUser, tags : tags})} options={basicInfo.tags}
							  onRemove={(tags) => setEditableUser({...editableUser, tags : tags})} displayValue="name"
							/>
						</>
					}
				</div>
			}
		</div>
		<div className="col-md-8">
			{
				additional == null ? "" : <div ref={tabDivRef} tabIndex={-1} style=
				  {{
					borderBottom : "1px solid var(--main-txt-color)",
					alignItems   : "stretch",
					display      : "flex",
					flexFlow     : "row"
				  }}
				>
					<Popover isOpen={unfilledMainData && currentTab != "mainInfo"} positions={["bottom"]} content=
					  {<div style={{background: "var(--main-bg)", padding: "5px 5px 0px 5px"}}>
						<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")} />
						</div>
						&nbsp;
						Requerido: Aún hay campos por llenar
					  </div>}
					><button onClick={() => setCurrentTab("mainInfo")} disabled={idleLocked || currentTab == "mainInfo"}
					  style={{flex : "1", verticalAlign : "top"}} className="btn btn-default"
					><span style={{display : "block", position : "relative", width : "100%", height : "100%", verticalAlign : "top"}}>
						<FontAwesomeIcon icon={solid("user")} />
						<br />
						<span className="d-none d-sm-inline-block" style={{ paddingLeft: "10px" }}>
							Información principal
						</span>
					</span></button></Popover>
					{
						additional.map
						(
							(addition : any) => <React.Fragment key={addition.code + "_h"}><Popover positions={["bottom"]}
							  isOpen={addition.fillingIssues && currentTab == "mainInfo"} content=
							  {<div style={{background : "var(--main-bg)", padding: "5px 5px 0px 5px"}}>
								<div className="requirementAlert">
									<FontAwesomeIcon icon={solid("exclamation")} />
								</div>
								&nbsp;
								Requerido:
								{addition.fillingIssueExplain}
							  </div>}
							><button onClick={() => setCurrentTab(addition.code)} style={{flex : "1", verticalAlign : "top"}}
							  disabled={idleLocked || currentTab == addition.code} className="btn btn-default"
							><span style={{display : "block", position : "relative", width : "100%", height : "100%", verticalAlign : "top"}}>
								{addition.icon}
								<br />
								<span className="d-none d-sm-inline-block" style={{ paddingLeft: "10px" }}>
									{addition.title}
								</span>
							</span></button></Popover></React.Fragment>
						)
					}
				</div>
			}
			<Tabs defaultActiveKey="mainInfo" activeKey={currentTab!!} onSelect={(key) => setCurrentTab(key)} id="studentForm"
			  className="mb-3 hidden displayNone"
			>
				{
					additional == null ? "" : additional.map
					(
						(addition : any) => <Tab key={addition.code + "_c"} eventKey={addition.code} title="">
							{addition.content}
						</Tab>
					)
				}
				<Tab eventKey="mainInfo" title=""><form ref={userFormRef} className="form">
					<UnderlineHeading name={"Datos personales"} />
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableUser != null ? "*" : ""}
								Nombres
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.first_name}
								</div>
									:
								<input ref={firstNameRef} value={editableUser.first_name} placeholder="Nombres" type="text"
								  onBlur={validateField} name="first_name" disabled={idleLocked} style={{width : "100%"}}
								  onChange={handleRegistryChange} onFocus={validateField} id="first_name" required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableUser != null ? "*" : ""}
								Apellidos
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.last_name}
								</div>
									:
								<input onBlur={validateField} style={{width : "100%"}} placeholder="Apellidos" name="last_name"
								  value={editableUser.last_name} ref={lastNameRef} type="text" id="last_name" disabled={idleLocked}
								  onChange={handleRegistryChange} onFocus={validateField} required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="identification_type_id">
								Tipo de identificación
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{
										(
											selectedUser.identification_type &&
												selectedUser.identification_type.name
										) || "-"
									}
								</div>
									:
								<>
									<select onChange={handleRegistryChange} id="identification_type_id"
									  value={editableUser.identification_type_id} name="identification_type_id" disabled={idleLocked}
									>{
										basicInfo.identificationTypes.map
										(
											(record : any, i : any) => <option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}</select>
									<div className="iconRight">
										<CustomIcon name={"rowDown"} />
									</div>
								</>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableUser != null ? "*" : ""}
								Código de identificación
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.identification_code || "-"}
								</div>
									:
								<input onFocus={validateField} ref={identificationCodeRef}
								  onChange={handleRegistryChange} disabled={idleLocked} placeholder="Código"
								  value={editableUser.identification_code} name="identification_code" id="identification_code"
								  onBlur={validateField} style={{width : "100%"}} type="text" required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="birthday">
								{editableUser != null ? "*" : ""}
								Fecha de nacimiento
							</label>
							<div className="roView">
								<br />
								{
									editableUser == null
										?
									(
										(selectedUser == null || selectedUser.birthday == null) ? "-" : toAscendingDate
											(new Date(selectedUser.birthday + " 23:00:00"))
									)
										:
									<LeDatePicker value={editableUser.birthdayValue} years={basicInfo.yearRange} months={monthRef}
									  onBlur={validateField} name="birthdayValue" id="birthdayValue" onFocus={validateField}
									  onChange=
									  {
										(e : any)=>handleRegistryChange
										({
											target:
											{
												name  : "birthdayValue",
												value : e
											}
										})
									  } required
									/>
								}
							</div>
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="gender_id">
								{editableUser != null ? "*" : ""}
								Género
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{(selectedUser.gender && selectedUser.gender.name) || "-"}
								</div>
									:
								<>
									<select onChange={handleRegistryChange} disabled={idleLocked}
									  value={editableUser.gender_id} id="gender_id" name="gender_id"
									>{
										basicInfo.genders.map
										(
											(record : any, i : any)=>
											<option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}</select>
									<div className="iconRight">
										<CustomIcon name={"rowDown"} />
									</div>
								</>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="phone1">
								{editableUser != null ? "*" : ""}
								Teléfono 1
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.phone1 || "-"}
								</div>
									:
								<input id="phone1" ref={phoneRef} name="phone1" onChange={handleRegistryChange} type="tel"
								  onFocus={validateField} disabled={idleLocked} onBlur={validateField} value={editableUser.phone}
								  style={{width : "100%"}} placeholder="Teléfono 1" required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="phone2">
								{editableUser != null ? "*" : ""}
								Teléfono 2
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.phone2 || "-"}
								</div>
									:
								<input onFocus={validateField} value={editableUser.phone} onChange={handleRegistryChange}
								  onBlur={validateField} style={{width : "100%"}} type="tel" placeholder="Teléfono 2"
								  id="phone2" ref={phoneRef} name="phone2" disabled={idleLocked} required
								/>
							}
						</div></div>
					</div>
					{
						employeeView ? "" : <>
							<br />
							<UnderlineHeading name={"Último grado de estudios terminado"} />
							<div className="inputContainer">
								<label htmlFor="last_school_name" style={{position : "relative"}}>
									{editableUser == null ? "" : "*"}
									Último grado de estudios terminado
								</label>
								<div className="fluentBox" style={{position : "relative", bottom : "13px"}}><div
								  className="fluentBlock" style={{padding : "0px 5px"}}><div style={{margin : "0px"}}
								  className="selectContainer"
								>
									<br />
									{
										editableUser == null
											?
										<div className="roView">
											<br />
											{
												(
													(selectedUser && selectedUser.academic_degree)
													&& selectedUser.academic_degree.name
												)
												|| "-"
											}
										</div>
											:
										<>
											<select disabled={idleLocked} name="academic_degree_id" id="academic_degree_id"
											  className="btn btn-primary text-start" value={editableUser.academic_degree_id}
											  style={{height : "30px"}} onChange={handleRegistryChange}
											>{
												Object.values(basicInfo.academicDegreeRef).map
												(
													(record: any, i: any) => <option key={i} value={record.id}>
														{record.name}
													</option>
												)
											}</select>
											<div className="iconRight" style={{ pointerEvents: "none" }}>
												<CustomIcon name="rowDown" />
											</div>
										</>
									}
								</div></div></div>
							</div>
							<br />
							<div className="row">
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="last_school_name">
										{editableUser == null ? "" : "*"}
										Última escuela
									</label>
									{
										editableUser == null || profileView
												?
											<div className="roView">
												<br />
												{selectedUser.last_school_name || "-"}
											</div>
												:
											<input ref={lastSchoolNameRef} name="last_school_name" style={{width : "100%"}}
											  onBlur={validateField} disabled={idleLocked} value={editableUser.last_school_name}
											  onFocus={validateField} id="last_school_name" placeholder="Última escuela"
											  onChange={handleRegistryChange} type="text" required
											/>
									}
								</div></div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="current_occupation">
										{editableUser == null ? "" : "*"}
										Ocupación actual
									</label>
									{
										editableUser == null || profileView
												?
											<div className="roView">
												<br />
												{selectedUser.current_occupation || "-"}
											</div>
												:
											<input placeholder="ocupación actual" disabled={idleLocked} id="current_occupation"
											  onBlur={validateField} value={editableUser.current_occupation} type="text"
											  onFocus={validateField} style={{width: "100%"}} ref={currentOccupationRef}
											  onChange={handleRegistryChange}  name="current_occupation"required
											/>
									}
								</div></div>
							</div>
						</>
					}
					<br />
					<UnderlineHeading name={"Ubicación"} />
					<div className="row">
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="nationality_id">
								{editableUser != null ? "*" : ""}
								Nacionalidad
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.nationality?.name || "-"}
								</div>
									:
								<>
									<select onChange={handleRegistryChange} name="nationality_id" id="nationality_id"
									  disabled={idleLocked} ref={nationalitySelRef} value={editableUser.nationality_id}
									>{
										basicInfo.countries.filter((country : any) => country.bool_campus_exclusive_use < 1).map
										(
											(record : any, i : any) => <option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}</select>
									<div className="iconRight">
										<CustomIcon name={"rowDown"} />
									</div>
								</>
							}
						</div></div>
						<div className="col-md-6">
							<div className="selectContainer">
								<label htmlFor="country_id">
									{editableUser ? "*" : ""}
									País de residencia
								</label>
								{
									editableUser == null
										?
									<div className="roView">
										<br />
										{selectedUser?.country?.name || "-"}
									</div>
										:
									<>
										<select
										  className="btn btn-primary text-start"
										  value={editableUser.country_id}
										  style={{height : "30px"}}
										  disabled={idleLocked}
										  ref={countrySelRef}
										  name="country_id"
										  id="country_id"
										  onChange=
										  {
											(event : any) => setEditableUser
											({
												...editableUser, country_id : event.target.value,
												state_id                        : "",
												state_name                      : "",
												city                            : null,
												city_name                       : ""
											})
										  }
										>{
											basicInfo.countries.filter((country : any) => country.bool_campus_exclusive_use < 1).map
											(
												(country : any, i : any) => <option key={i} value={country.id}>
													{country.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div style={{margin : "unset", width : "100%"}}
							  className=
							  {
								(
									(editableUser == null && basicInfo.countryRef["C_" + selectedUser.country_id].states.length < 1)
										||
									(editableUser && basicInfo.countryRef["C_" + editableUser.country_id].states.length < 1)
								)
									? "inputContainer"
										: "selectContainer"
							  }
							>
								<label htmlFor="state_id">
									{editableUser == null ? "" : "*"}
									Estado/Depto./Provincia...
								</label>
								<div className="roView" style={{width : "100%"}}>{
									editableUser == null
											?
										<>
											<br />
											{
												(
													(selectedUser && selectedUser.state)
															?
														selectedUser.state.name
															:
														selectedUser.state_name
												)
													|| "-"
											}
										</>
											:
										(
											(
												(editableUser == null && basicInfo.countryRef["C_" + selectedUser.country_id].states.length < 1)
													||
												(editableUser && basicInfo.countryRef["C_" + editableUser.country_id].states.length < 1)
											)
													?
												<>
													<br />
													<input
													  type="text"
													  maxLength={50}
													  id="state_name"
													  name="state_name"
													  ref={stateNameRef}
													  disabled={idleLocked}
													  onBlur={validateField}
													  onFocus={validateField}
													  style={{position : "relative", width : "100%"}}
													  onChange={handleRegistryChange}
													  value={editableUser.state_name}
													  required
													/>
												</>
													:
												<>
													<select
													  id="state_id"
													  name="state_id"
													  ref={stateSelRef}
													  disabled={idleLocked}
													  onBlur={validateField}
													  onFocus={validateField}
													  style={{height : "30px"}}
													  className="btn btn-primary text-start"
													  defaultValue={editableUser.state_id}
													  onChange=
													  {
														(event : any) => setEditableUser
														({
															...editableUser, state_id : event.target.value,
															state_name                    : "",
															city                          : null,
															city_name                     : ""
														})
													  }
													  required
													>
														<option key={"SOC_" + editableUser.country_id} value="">
															&emsp;
															Seleccione...
														</option>
														{
															basicInfo.countryRef["C_" + editableUser.country_id] &&
															basicInfo.countryRef["C_" + editableUser.country_id].states.map
															(
																(record : any, i : number) => <option
																  value={record.id}
																  key={record.id}
																>
																	{record.name}
																</option>
															)
														}
													</select>
													<div className="iconRight" style={{pointerEvents : "none"}}>
														<CustomIcon name="rowDown" />
													</div>
												</>
										)
								}</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className=
							  {
								(
									(editableUser == null && basicInfo.countryRef["C_" + selectedUser.country_id].states.length < 1)
										||
									(editableUser && basicInfo.countryRef["C_" + editableUser.country_id].states.length < 1)
								)
									? "inputContainer"
										: "selectContainer"
							  }
							>
								<label htmlFor="city">
									{editableUser == null ? "" : "*"}
									Ciudad/Municipio/Distrito
								</label>
									<div className="roView" style={{width : "100%", paddingTop : "15px"}}>{
										editableUser == null
												?
											<div style={{paddingTop : "10px"}}>{
												(
													(
														selectedUser && selectedUser.city
															? selectedUser.city.name
																: selectedUser.city_name
													) || "-"
												)
											}</div>
												:
											(
												(
													(
														basicInfo.countryRef["C_" + editableUser.country_id].states.length > 0
															&&
														(editableUser.state_id == null || basicInfo.countryRef["C_" + editableUser.country_id].stateRef["S_" + editableUser.state_id] == null)
													)
														||
													(
														basicInfo.countryRef["C_" + editableUser.country_id].states.length < 1
															&&
														isEmpty(editableUser.state_name)
													)
												)
													?
														<div style={{paddingTop : "10px"}}>
															&lt;Proporcione estado &gt;
														</div>
													:
												(
													(
														basicInfo.countryRef["C_" + editableUser.country_id].states.length < 1
															||
														basicInfo.countryRef["C_" + editableUser.country_id].stateRef["S_" + editableUser.state_id].cities.length < 1
													)
															?
														<input
														  type="text"
														  maxLength={50}
														  id="city_name"
														  name="city_name"
														  ref={cityNameRef}
														  disabled={idleLocked}
														  onBlur={validateField}
														  onFocus={validateField}
														  style={{width : "100%"}}
														  onChange={handleRegistryChange}
														  value={editableUser.city_name}
														  required
														/>
															:
														<Popover
														  positions={["bottom"]}
														  isOpen={noCityProvided}
														  content=
														  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
															<div className="requirementAlert">
																<FontAwesomeIcon icon={solid("exclamation")}/>
															</div>
															&nbsp;
															Requerido: Elija una opción
														  </div>}
														>
															<div style={{width : "100%"}}>
																<SelectReact
																  id="city"
																  name="city"
																  placeholder="Ciudad"
																  disabled={idleLocked}
																  value={editableUser.city}
																  onChange={handleRegistryChange}
																  children={Object.values(basicInfo.countryRef["C_" + editableUser.country_id].stateRef["S_" + editableUser.state_id].cityRef)}
																/>
															</div>
														</Popover>
												)
											)
									}</div>
							</div>
						</div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableUser != null ? "*" : ""}
								Dirección actual
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.address || "-"}
								</div>
									:
								<input onFocus={validateField} style={{width : "100%"}} onChange={handleRegistryChange}
								  onBlur={validateField} placeholder="Dirección postal" value={editableUser.address}
								  name="address" id="address" type="text" ref={addressRef} disabled={idleLocked} required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Código postal
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.zipcode || "-"}
								</div>
									:
								<input value={editableUser.zipcode} ref={zipcodeRef} name="zipcode" style={{width : "100%"}}
								  onChange={handleRegistryChange} step={1} placeholder="Código postal" id="zipcode" min={1}
								  type="number" max={100000}
								/>
							}
						</div></div>
						{
							Object.keys(linkInfo).map
							(
								(record : any, index : number) => <div className="col-md-6" key={record}><div
								  className="inputContainer"
								>
									<label htmlFor={record}>
										{linkInfo[record]}
									</label>
									{
										editableUser == null
												?
											<div className="roView">
												<br />
												{(selectedUser && selectedUser[record]) || "-"}
											</div>
												:
											<input value={editableUser[record]} name={record} style={{width : "100%"}} type="text"
											  onChange={handleRegistryChange} placeholder={linkInfo[record]} disabled={idleLocked}
											  id={record}
											/>
									}
								</div></div>
							)
						}
					</div>
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="email">
								{editableUser != null ? "*" : ""}
								Correo electrónico
							</label>
							{
								editableUser == null
									?
								<div className="roView">
									<br />
									{selectedUser.email || "-"}
								</div>
									:
								<input value={editableUser.email} style={{width : "100%"}} type="email" disabled={idleLocked}
								  onFocus={validateField} placeholder="Correo electrónico" onChange={handleRegistryChange}
								  onBlur={validateField} name="email" ref={emailRef} id="email" required
								/>
							}
						</div></div>
						{
							editableUser == null ? "" : <div className="col-md-6"><div className="inputContainer">
								<label htmlFor="name">
									Contraseña
								</label>
								<input onChange={handleRegistryChange} name="password" id="password"
								  style={{width : "100%"}} disabled={idleLocked} value={editableUser.password} type="password"
								  placeholder="Contraseña" ref={passwordRef}
								/>
							</div></div>
						}
					</div>
					<br />
					<UnderlineHeading name={"Observaciones"} />
					{
						editableUser == null || profileView ? (selectedUser.observations || "-") : <textarea id="observations"
						  onChange={handleRegistryChange} value={editableUser.observations} ref={observationsRef}
						  onBlur={validateField} className="notesField form-control"
						  disabled={idleLocked} name="observations"
						/>
					}
				</form></Tab>
			</Tabs>
		</div>
	</div></div></DataDisplayModal>
}

const SectionContainer = (props : any)=>
{
	const
	{
		managementOverride,
		pagination,
		children,
		advancedSearchForm,
		additionalClasses,
		additionalStyle,
		clearSearchFn,
		defaultSearch,
		customSearch,
		searchFn,
		removalFn,
		selectedRecords,
		clearSearchRequested,
		searchChangeFn,
		addingPromptFn,
		generalPlaceHolder,
		extendedFilterShown,
		setExtendedFilterShown,
		extendedFilterViewClosing,
		filterSummary,
		allowed,
		searchError,
		UIStatus,
		searchFormRef,
		searchIcon,
		additionalOptions,
		saveButtonRef,
		extendedMultipleFunctionality
	} = props

	const idleLocked : boolean                                        = useSelector((state : RootState) => state.idleLockSwitch.value)
	const userProfile       : any                                     = useSelector((state : RootState) => state.userProfile.value)
	const baseStyle  : any                                            = additionalStyle == null ? {padding : "30px"} : {padding : "30px", ...additionalStyle}
	const [wrongValueOnFields, setWrongValueOnFields]                 = useState<boolean>(false)
	const [multipleRecordOptionsShown, setMultipleRecordOptionsShown] = useState<boolean>(false)
	const [advancedSearchOptionsShown, setAdvancedSearchOptionsShown] = useState<boolean>(false)
	let   searchButtonRef : any                                       = useRef<HTMLButtonElement>(null)
	const title : string = userProfile.roleMap[userProfile.currentRole].permits[useLocation().pathname.substr(1)].section.name

	return <>
		<div style={baseStyle} className={additionalClasses}>
			<div className="fluentBox">
				<div className="fluentBlockSm"><h2 className="pageHeader">
					{title}
				</h2></div>
				<div className="fluentBlockLg"><table style={{padding : "unset", background : "#F3F8FF", width : "100%", border : "1px solid #8F91DA"}}>
					<tbody><tr>
						<td style={{padding : "3px"}}><button disabled={idleLocked || _.isEqual(defaultSearch, customSearch)}
						  style={{color : "#8F91DA", flexGrow : 0, background : "#F3F8FF", width : "100%", height : "44px"}}
						  onClick={clearSearchFn} type="button"
						>
							<OverlayTrigger overlay={<Tooltip id="tooltip-activos">
								{_.isEqual(defaultSearch, customSearch) ? "Filtros" : "Limpiar"}
							</Tooltip>}>
								<FontAwesomeIcon icon={_.isEqual(defaultSearch, customSearch) ? solid("filter") : solid("times")} />
							</OverlayTrigger>
						</button></td>
						<td style={{width : "100%"}}>
							<input
							  placeholder="Búsqueda..."
							  type="text"
							  disabled={idleLocked || clearSearchRequested}
							  name="q"
							  style={{flexGrow : 1, background : "#F3F8FF", width : "100%", height : "44px"}}
							  maxLength={50}
							  id="q"
							  value={customSearch.q || ""}
							  onChange={searchChangeFn}
							  onKeyPress={(event : any) => keyHandling(!idleLocked && event, searchButtonRef)}
							/>
						</td>
						{
							advancedSearchForm == null ? "" : <td>
								<OverlayTrigger overlay={<Tooltip id="tooltip-activos">{additionalOptions ? "Opciones" : "Búsqueda Avanzada"}</Tooltip>}>
								{
									additionalOptions == null
											?
										<button className={"btn btn-default"} onClick={() => setExtendedFilterShown(true)}
										  type="button"
										>
											<FontAwesomeIcon icon={solid("ellipsis")} />
										</button>
											:
										<div><Popover isOpen={advancedSearchOptionsShown} reposition={false} positions={["bottom"]}
										  onClickOutside={() => setAdvancedSearchOptionsShown(false)} content=
										  {
											<div style={{background : "#FFFFFF"}}>
												<div><button onClick={() => setExtendedFilterShown(true)} type="button"
												  disabled={idleLocked} style={{width : "100%", textAlign : "left"}}
												  className="btn btn-defaults"
												>
													<span style={{paddingLeft : "10px"}}>
														Búsqueda avanzada...
													</span>
												</button></div>
												<div>
													{additionalOptions}
												</div>
											</div>
										  }
										><button type="button" onClick={() => setAdvancedSearchOptionsShown(true)}
										  className={"btn btn-default"}
										>
											<FontAwesomeIcon icon={solid("ellipsis")} />
										</button></Popover></div>
								}
								</OverlayTrigger>
							</td>
						}
						<td>
							<button
							  disabled={idleLocked}
							  onClick={searchFn}
							  type="button"
							  ref={searchButtonRef}
							  style=
							  {{
								color  : "#8F91DA", padding : "10px", flexGrow : 0, background : "#F3F8FF", width : "100%",
								height : "44px"
							  }}
							>
								<FontAwesomeIcon icon={solid("arrow-turn-down")} transform={{rotate : 90}} />
							</button>
						</td>
					</tr></tbody>
				</table></div>
				{
					managementOverride ? managementOverride :
					(
						<div className="fluentBlockSm">{
							(!(allowed.delete || allowed.create) || (addingPromptFn == null && removalFn == null)) ? <></> : <div
							  style={{display : "flex", float : "right", paddingTop : "5px", position : "sticky"}}
							>
								{
									((!allowed.delete || removalFn == null) && (!allowed.update || extendedMultipleFunctionality == null))
											?
										""
											:
										<Popover isOpen={multipleRecordOptionsShown}
										  onClickOutside={() => setMultipleRecordOptionsShown(false)} positions={["bottom"]}
										  reposition={false} content=
										  {
											<div style={{background : "#FFFFFF"}}>
												<div>
													<button
													  disabled={idleLocked || (selectedRecords < 1)}
													  style={{borderRadius : "25px"}}
													  onClick={removalFn}
													  className="btn btn-warning"
													  type="button"
													>
														<CustomIcon name={"bTrash"} />
														<span style={{paddingLeft : "10px"}}>
															Eliminar seleccionados
														</span>
													</button>
												</div>
												<div>{
													(!allowed.update || extendedMultipleFunctionality == null)
														? ""
															: extendedMultipleFunctionality
												}</div>
											</div>
										}
										>
											<button 
											  disabled={idleLocked} 
											  className="btn btn-outline-default" 
											  style={{display : "flex"}}
											  onClick={() => setMultipleRecordOptionsShown(!multipleRecordOptionsShown)}
											  type="button"
											>
												<FontAwesomeIcon
												  icon={solid("list-check")}
												  style={{margin : "4px"}}
												/>
												<span className="d-xs-none d-sm-none d-lg-none d-xl-block">
													Acciones
												</span>
												<FontAwesomeIcon
												  icon={solid("chevron-down")}
												  style={{margin : "4px"}}
												/>
											</button>
										</Popover>
								}
								{
									!allowed.create || addingPromptFn == null? "" : <button type="button" onClick={addingPromptFn}
									  style={{borderRadius : "25px", display : "flex"}} className="btn btn-outline-success"
									>
										<FontAwesomeIcon icon={solid("plus")} style={{marginTop : "4px"}} />
										<span className="d-xs-none d-sm-none d-lg-none d-xl-block" style={{paddingLeft : "10px"}}>
											Agregar
										</span>
									</button>
								}
							</div>
						}</div>
					)
				}
			</div>
			{
				isEmpty(filterSummary) ? "" : <div className="underlineHeadingContainer" style={props.style}>
					<div style={{display : "inline-flex", flexWrap : "wrap", alignItems : "stretch"}}>
						<div style={{padding : "15px"}}>
							<FontAwesomeIcon icon={solid("filter")} />
						</div>
						{
							filterSummary.map
							(
								(setting : any) => <fieldset key={setting.label} style={{flexGrow : 1}}>
									<legend><label style={{position : "relative", top : "4px"}}>
										{setting.label}
									</label></legend>
									<div>
										{setting.display}
									</div>
								</fieldset>
							)
						}
					</div>
					<br />
					<br />
					<hr />
				</div>
			}
			<div style={{overflow : "auto"}}>
				{children}
			</div>
			{
				(pagination == null || pagination.lastPage < 2) ? "" : <div style={{padding : "26px 0px 75px"}}><nav
				  style={{display : "inline-block", float : "right"}} aria-label="Page pagination"
				><ul className="pagination text-right">
					<button className={"btn btn-default " + (pagination.pageGroup === 0 ? "disabled" : "")} type="button"
					  disabled={idleLocked || pagination.pageGroup == 0} onClick={() => searchFn(pagination.groupInitPage)}
					  aria-label="Previous"
					><span aria-hidden="true">
						&laquo;
					</span></button>
					{
						pagination.pages.map
						(
							(page : any) => <button disabled={idleLocked || page == pagination.currentPage} type="button"
							  className={"btn btn-default " + (page != pagination.currentPage ? "active" : "")} key={page}
							  onClick={() => searchFn(page)}
							>
								{page}
							</button>
						)
					}
					<li className={pagination.pageGroup === pagination.lastGroup ? "disabled" : ""}><button aria-label="Next"
					  className={"btn btn-default " + (pagination.pageGroup === pagination.lastGroup ? "disabled" : "")}
					  disabled={idleLocked || pagination.pageGroup == pagination.lastGroup} type="button"
					  onClick={() => searchFn(pagination.groupLastPage)}
					><span aria-hidden="true">
						&raquo;
					</span></button></li>
				</ul></nav> </div>
			}
		</div>
		{
			extendedFilterShown != true ? "" : <DataDisplayModal entityName={title} searchFn={searchFn} saveLock={idleLocked}
			  searchForm={searchFormRef} wrongValueOnFields={wrongValueOnFields} clearSearchFn={clearSearchFn} UIStatus={UIStatus}
			  headIcon={searchIcon} processingError={searchError} editableRecord={customSearch} saveButtonRef={saveButtonRef} promptCloseFn=
			  {
				()=>
				{
					if(extendedFilterViewClosing)
					{
						extendedFilterViewClosing()
					}
					else
					{
						setExtendedFilterShown(false)
					}
				}
			  } unrequiredEmphasis
			>
				<form ref={searchFormRef} className="form" onSubmit=
				  {
					(event : FormEvent)=>
					{
						event.preventDefault()
						searchFn()
					}
				  }
				>
					<div style={{paddingBottom : "25px"}}>
						<label htmlFor="q" style={{position : "relative"}}>
							{generalPlaceHolder}
						</label>
						<table style={{padding : "unset", background : "#F3F8FF", width : "100%", border : "1px solid #8F91DA"}}>
							<tbody><tr>
								<td style={{padding : "3px"}}><button disabled={idleLocked || _.isEqual(defaultSearch, customSearch)}
								  style={{color : "#8F91DA", flexGrow : 0, background : "#F3F8FF", width : "100%", height : "44px"}}
								  type="button" onClick=
								  {
									() => searchChangeFn
									({
										target:
										{
											name  : "q",
											value : ""
										}
									})
								  }
								>
									<FontAwesomeIcon icon={customSearch.q == "" ? solid("filter") : solid("times")} />
								</button></td>
								<td style={{width : "100%"}}>
									<input style={{flexGrow : 1, background : "#F3F8FF", width : "100%", height : "44px"}} id="q"
									  type="text" disabled={idleLocked || clearSearchRequested} placeholder={generalPlaceHolder}
									  value={customSearch.q || ""} maxLength={50} onChange={searchChangeFn} name="q"
									/>
								</td>
							</tr></tbody>
						</table>
					</div>
					{advancedSearchForm}
				</form>
			</DataDisplayModal>
		}
	</>
}

export default SectionContainer
