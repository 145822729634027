import { AxiosResponse } from "axios";
import { axiosInstance } from "./axiosInstance";
import { ModuleDTO } from "../../models/ModuleDTO";

const RESOURCE_URL_V2 = "/admin/campus/";

export class CourseLevelService {

	getLevelsByCourseId = async (campusId: number, courseId: number, criteria?: any): Promise<AxiosResponse<ModuleDTO>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/courses/${courseId}/_levels`;
		if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
			try {
				const criteriaJson = JSON.stringify(criteria);
				uri = `${uri}?criteria=${encodeURIComponent(criteriaJson)}`;
			} catch (error) {
				console.error("Error serializando criteria as JSON:", error);
				throw new Error("Invalid criteria format");
			}
		}
		const response = await axiosInstance.get<ModuleDTO>(uri);
		return response;
	}
}