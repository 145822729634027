import
{
	FormEvent,
	useEffect,
	useReducer,
	useRef,
	useState
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import {Range}
	from "react-range"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	dismissNotice,
	displayNotice,

	disableIdleLockSwitch,
	enableIdleLockSwitch,

	RootState
}
	from "./../../globals"
import
{
	buttonClickHandling,
	getAccessPermissions,
	imageTypes,
	isEmpty,
	isNotEmpty,
	UIState,
	validateField
}
	from "./../../common"
import
{
	campusStatus,
	tunning,
	country
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import {SelectReact}
	from "./../../components/select"
import UnderlineHeading
	from "./../../components/underlineHeading"
import CatalogService
	from "./../../services/catalog.service"
import CampusesService
	from "./../../services/campuses.service"
import defaultCampusImage
	from "./../../assets/images/campus.png"

import "./../../components/dropdown/style.css"
import "./../../components/input/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action  = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return{...state, isButtonDisabled : action.payload}
		break;
	}
}

const Campuses = ()=>
{
	const CAMPUS_STATUSES = 0

	const campuses     : any[] = []
	const [state, trigger]                                = useReducer(reducer, initialState)
	const dispatch                                        = useDispatch()
	const records      : any[]                            = campuses.map((record : any, index : number) => {return {selected : false, data : record}})
	const [campusStatuses, setCampusStatuses]             = useState<any>([])
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [initSetup, setInitSetup]                       = useState<number | null>(null)
	const [data, setData]                                 = useState<any[]>(records)
	const [filteredData, setFilteredData]                 = useState<any[]>(records)
	const idleLocked   : boolean                          = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                    = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean                          = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any                              = useSelector((state : RootState) => state.basicInfo.value)
	const currentUser  : any                              = useSelector((state : RootState) => state.userProfile.value)
	const [noCityProvided, setNoCityProvided]             = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]           = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]             = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [stateLoaded, setStateLoaded]                   = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus]                         = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [selectedCampus, setSelectedCampus]             = useState<any>(null)
	const [editableCampus, setEditableCampus]             = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                   = useState<boolean>(false)
	const [saveError, setSaveError]                       = useState<any>(null)
	const allowed          : any                          = getAccessPermissions(currentUser)
	let   recordFormRef                                   = useRef<HTMLFormElement >(null)
	let   nameRef                                         = useRef<HTMLInputElement>(null)
	let   codeRef                                         = useRef<HTMLInputElement>(null)
	let   studentIdPrefixRef                              = useRef<HTMLInputElement>(null)
	let   morningShiftNameRef                             = useRef<HTMLInputElement>(null)
	let   afternoonShiftNameRef                           = useRef<HTMLInputElement>(null)
	let   eveningShiftNameRef                             = useRef<HTMLInputElement>(null)
	let   zipcodeRef                                      = useRef<HTMLInputElement>(null)
	let   imageRef                                        = useRef<HTMLInputElement>(null)
	let   observationsRef                                 = useRef<HTMLTextAreaElement>(null)
	let   countrySelRef                                   = useRef<HTMLSelectElement>(null)
	let   stateSelRef                                     = useRef<HTMLSelectElement>(null)
	let   addressRef                                      = useRef<HTMLInputElement>(null)
	let   phone1Ref                                       = useRef<HTMLInputElement>(null)
	let   phone2Ref                                       = useRef<HTMLInputElement>(null)
	let   fileReader : any
	const [newCampus, setNewCampus] = useState<any>(null)

	const linkInfo : any=
	{
		web        : "web",
		googleMaps : "Google Maps",
		facebook   : "Facebook",
		instagram  : "Instagram"
	}

	const unedit = ()=>
	{
		if(editableCampus && editableCampus.id == null)
		{
			setSelectedCampus(null)
		}

		setEditableCampus(null)
	}

	const showPrompt = (recordData : any)=>
	{
		let campus=
		{
			...recordData, imagePreview : recordData.image,
			afternoonInterval           : [recordData.afternoon_shift_start, recordData.evening_shift_start]
		}

		setUIStatus(UIState.NORMAL)
		setSaveError(null)
		setStateLoaded(false)

		if(campus.id == null)
		{
			setEditableCampus(campus)
		}

		setSelectedCampus(campus)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if
				(
					+basicInfo.countryRef["C_" + editableCampus.country_id].preferred_identification_type_id &&
					(editableCampus.city == null || editableCampus.city.id == null)
				)
				{
					if(!unfilledFields && !noCityProvided)
					{
						setUnfilledFields(true)
						setNoCityProvided(true)

						setTimeout
						(
							() =>
							{
								setUnfilledFields(false)
								setNoCityProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(saveError != null)
					{
						setSaveError(null)
					}

					setUIStatus(UIState.LOCKED)
					dispatch(enableIdleLockSwitch());

					const result = CampusesService.saveCampus(editableCampus).then
					(
						()=>
						{
							setSaveSuccess(true)
							setUIStatus(UIState.SUCCESS)

							setTimeout
							(
								()=>
								{
									setCustomSearch(defaultSearch)
									setSelectedCampus(null)
									setEditableCampus(null)
									setSaveSuccess(false)
									setUIStatus(UIState.NORMAL)
									search()
								},
								tunning.MODAL_DISMISS_DELAY
							)
						},
						(error : any)=>
						{
							console.log(error)
							setUIStatus(UIState.ERROR)
							dispatch(disableIdleLockSwitch())
							setSaveError
							(
								error.response.status == 409
										?
									"Hay conflictos en la información proporcionada (nombre). " +
										"Revise que los valores sean únicos en comparación con los registros existentes"
										:
									"La información no pudo ser guardada"
							)
						}
					)
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
			setFilteredData(data.filter((record: any) => dataFilter(record, e.target.value)))
		}
	}

	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(
					<>
						Sólo se permiten imágenes con las siguientes características:
						<br />
						&emsp;
						Tamaño &lt;= 5MB
						<br />
						&emsp;Formato: [BMP, GIF, JPEG, PNG, WEBP]
					</>
				)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let campus : any     = {...editableCampus, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any)=>
				{
					setEditableCampus({...campus, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if(imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		setEditableCampus
		({
			...editableCampus, image : null,
			imagePreview             : null,
		})
	}

	const handleRegistryChange = (event : any)=>
	{
		if(event && event.target)
		{
			setEditableCampus({...editableCampus, [event.target.name] : event.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				const statusRetrievalError : string = "El catálogo de información relativa al plantel no pudo ser extraído, " +
					"por lo que el acceso a este módulo permanecerá deshabilitado"

				dispatch(enableIdleLockSwitch())

				try
				{
					const result0 = await CatalogService.getCampusStatuses(sessionToken)

					if(result0.status == 200 || result0.status == 204)
					{
						setCampusStatuses
						(
							result0.data.map
								((record : any, index: number) => {return {id : record.id, name : record.name}})
						)

						setInitSetup(CAMPUS_STATUSES)
					}
					else
					{
						procedureComplaint(statusRetrievalError)
					}
				}
				catch(error)
				{
					procedureComplaint(statusRetrievalError)
				}
				finally
				{
					dispatch(disableIdleLockSwitch())
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(initSetup == CAMPUS_STATUSES)
				{
					if(campusStatuses.length < 1)
					{
						procedureComplaint
						(
							"No hay estatuses de planteles en el registro, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado"
						)
					}
					else
					{
						setNewCampus
						({
							country_id            : basicInfo.countries[0].id,
							status_id             : campusStatus.ACTIVE,
							state_id              : "",
							city                  : "",
							address               : "",
							zipcode               : "",
							name                  : "",
							phone1                : "",
							phone2                : "",
							email                 : "",
							web                   : "",
							googleMaps            : "",
							facebook              : "",
							instagram             : "",
							morning_shift_name    : "Turno matutino",
							afternoon_shift_name  : "Turno vespertino",
							evening_shift_name    : "Turno nocturno",
							afternoon_shift_start : 120,
							evening_shift_start   : 160
						})

						search()
					}
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect
	(
		()=>
		{
			if(editableCampus != null)
			{
				if(basicInfo.countryRef["C_" + editableCampus.country_id].stateRef["S_" + editableCampus.state_id] == null)
				{
					setEditableCampus({...editableCampus, state_id : ""})
				}
			}
		},
		[editableCampus?.country_id]
	)

	useEffect
	(
		()=>
		{
			if(editableCampus != null)
			{
				if
				(
					(editableCampus.city && editableCampus.city.id)
						&&
					(
						basicInfo.countryRef["C_" + editableCampus.country_id].stateRef["S_" + editableCampus.state_id] == null
							||
						basicInfo
							.countryRef["C_" + editableCampus.country_id]
								.stateRef["S_" + editableCampus.state_id]
									.cityRef["C_" + editableCampus.city.id] == null
					)
				)
				{
					setEditableCampus({...editableCampus, city : null})
				}
			}
		},
		[editableCampus?.state_id]
	)

	useEffect(()=>{setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)
//---------------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async()=>
	{
		let searchError : string = "La búsqueda no pudo ser completada"

		try
		{
			var expand : string | null = "base"

			dispatch(enableIdleLockSwitch())

			const result = await CampusesService.getCampuses(expand)

			if(result.status == 200 || result.status == 204)
			{
				let receivedData : any = result.data.map((record : any) => ({...record, city : record.city == null ? null : {...record.city, label : record.city.name}}))
				setData(receivedData)
				setFilteredData(receivedData.filter((record : any) => dataFilter(record, customSearch.q)))
				setSectionLoaded(true)
			}
			else
			{
				console.log(result)
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const closePrompt = ()=>
	{
		setSelectedCampus(null)
		setEditableCampus(null)
	}

	const confirmRemoval = (recordId : number)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string = "No se pudo eliminar el registro seleccionado. " +
						"Puede que otros registros dependan de esta información, " +
							"por lo que no será posible realizar por completo la operación"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await CampusesService.removeCampus(recordId)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setSelectedRecords(0)
							search()
						}
						else
						{
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const dataFilter = (record : any, textQuery : string)=>
	{
		let emptySearch : boolean = isEmpty(textQuery)
		let allowance   : boolean = emptySearch ||
		(
			(
				(record.name.indexOf(textQuery) >= 0) ||
				(
					(record.city.name.indexOf(textQuery) >= 0 || record.city.state.name.indexOf(textQuery) >= 0) ||
					(record.city.state.country.name.indexOf(textQuery) >= 0)
				)
			)
		)

		if(!emptySearch)
		{
			if(isNotEmpty(record.address))
			{
				allowance ||= record.address.indexOf(textQuery) >= 0
			}

			if(isNotEmpty(record.code))
			{
				allowance ||= record.code.indexOf(textQuery) >= 0
			}

			if(isNotEmpty(record.observations))
			{
				allowance ||= allowance || record.observations.indexOf(textQuery) >= 0
			}
		}

		return allowance
	}

	return !sectionLoaded ? "" : <>
		<SectionContainer
		  allowed={allowed}
		  searchFn={search}
		  removalFn={confirmRemoval}
		  clearSearchFn={clearSearch}
		  customSearch={customSearch}
		  defaultSearch={defaultSearch}
		  searchChangeFn={handleSearchChange}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newCampus)}
		>{
			(filteredData.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					{filteredData.length < data.length ? "Sin coincidencias" : "Sin resultados."}
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="campusesHeader">
					<th style={{"borderBottom" : "1px solid #000000 !important"}}>
						id
					</th>
					<th>
						Nombre
					</th>
					<th>
						Correlativo
					</th>
					<th>
						Ubicación
					</th>
					<th>
						País
					</th>
					<th>
						Observaciones
					</th>
					<th />
				</tr></thead>
				<tbody>{
					filteredData.map
					(
						(record : any, index : number) => <tr key={"campus" + index}>
							<td>
								{record.id}
							</td>
							<td>
								{record.name}
							</td>
							<td>
								{record.code || "-"}
							</td>
							<td>
								{record.address || "-"}
							</td>
							<td>
								{record.country?.name}
							</td>
							<td>
								{record.observations || "-"}
							</td>
							<td><div style={{display : "flex"}}>
								<button style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
								  disabled={idleLocked} className="button btn btn-default" onClick={() => showPrompt(record)}
								  type="button"
								>
									<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
								</button>
								{
									!allowed.delete ? "" : <button className="button btn btn-default" disabled={idleLocked}
									  onClick={() => confirmRemoval(record.id)} type="button" style={{display : "table-column"}}
									>
										<CustomIcon name="bTrash" />
									</button>
								}
							</div></td>
						</tr>
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			selectedCampus == null ? "" : <DataDisplayModal unchangedRecord={unchangedRecord} unfilledFields={unfilledFields}
			  headIcon={<FontAwesomeIcon icon={solid("school-flag")} />} saveLock={state.isButtonDisabled} UIStatus={UIStatus}
			  uneditFn={unedit} saveDataFn={saveRecord} processingError={saveError} saveSuccess={saveSuccess}
			  dataEditInit={() => {setEditableCampus(selectedCampus)}} editableRecord={editableCampus} entityName="Plantel"
			  promptCloseFn={closePrompt} form={recordFormRef} allowedUpdate={allowed.update}
			><form ref={recordFormRef} className="form"><div className="container" style={{padding : "unset"}} onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			><div className="row">
				<div className="col-md-4 text-center" style={{padding : "unset"}}>
					<div style={{padding : "0px 15px"}}>
						<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
							<img className="relatedPicture" src=
							  {
								(editableCampus == null ? selectedCampus.imagePreview : editableCampus.imagePreview)
									|| defaultCampusImage
							  }
							/>
							{
								editableCampus == null ? "" : <>
									<input accept="image/*" style={{display : "none"}} name="image" type="file" ref={imageRef}
									  id="image" onChange={imageLoad}
									/>
									{
										editableCampus.image == null ? "" : <button disabled={idleLocked} onClick={imageDisposal}
										  className="btn btn-danger rounded-pill" type="button" style=
										  {{
											position : "relative",
											bottom   : "40px",
											left     : "15%"
										  }}
										>
											<FontAwesomeIcon icon={solid("times")} size="2x" fill="unset"/>
										</button>
									}
									<button onClick={() => buttonClickHandling(imageRef)} disabled={idleLocked}
									  className="btn btn-default rounded-pill pictureAttach" type="button"
									>
										<FontAwesomeIcon style={{color : "#000000"}} icon={solid("camera")}
										  size="2x" fill="unset"
										/>
									</button>
								</>
							}
						</div>
						<div className="selectContainer">
							<label htmlFor="status_id">
								Estatus
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{selectedCampus.status.name || -""}
								</div>
									:
								<>
									<select value={editableCampus.status_id} id="status_id" name="status_id" style={{height : "30px"}}
									  disabled={idleLocked} onChange={handleRegistryChange} className="btn btn-primary text-start"
									>{
										campusStatuses.map
										(
											(record : any, i : any) => <option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}</select>
									<div className="iconRight" style={{pointerEvents : "none"}}>
										<CustomIcon name="rowDown" />
									</div>
								</>
							}
						</div>
						<fieldset style={{textAlign : "left", padding : "0px"}}>
							<legend><label style={{position : "relative", top : "4px"}}>
								Gestión del día
								&nbsp;
							</label></legend>
							<div style={{padding : "0px 15px 15px 15px"}}>
								<div style={{borderRadius : "15px", border : "3px solid #FFC107", marginBottom : "5px"}}>
									<div className="inputContainer" style={{margin : "unset"}}>
										<div style={{borderRadius : "10px 10px 0px 0px", background : "#FFC107", height : "20px"}}>
											<label htmlFor="morning_shift_name" style={{color : "#000000", position : "relative"}}>
												{editableCampus ? "*" : ""}
												Mañana (Nombre)
											</label>
										</div>
										{
											editableCampus == null
												?
											<div className="roView">
												{selectedCampus.morning_shift_name || "-"}
											</div>
												:
											<input onChange={handleRegistryChange} id="morning_shift_name" placeholder="Nombre"
											  onBlur={validateField} style={{width : "100%"}}
											  onFocus={validateField} disabled={idleLocked}
											  ref={morningShiftNameRef} name="morning_shift_name" maxLength={50} type="text"
											  value={editableCampus.morning_shift_name} required
											/>
										}
									</div>
									<div style={{textAlign : "center", background : "#FFC107", borderRadius : "0px 0px 10px 10px", height : "35px", paddingTop : "7px"}}>
										<div style={{display : "inline-block", borderRadius : "15px", background : "#FFFFFF", fontWeight : "900"}}>
											07:00
											&nbsp;
											-
											&nbsp;
											{(()=>
												{
													let campus  : any = editableCampus || selectedCampus
													let minutes : any = (campus.afternoonInterval[0] % 10)
													let hours   : any = (campus.afternoonInterval[0] - minutes) / 10

													return sprintf("%02d:%02d", hours, minutes * 6)
												}
											)()}
										</div>
									</div>
								</div>
								<div style={{borderRadius : "15px", border : "3px solid #FF8327", marginBottom : "5px"}}>
									<div className="inputContainer" style={{margin : "unset"}}>
										<div style={{borderRadius : "10px 10px 0px 0px", background : "#FF8327", height : "20px"}}>
											<label htmlFor="afternoon_shift_name" style={{color : "#FFFFFF", position : "relative"}}>
												{editableCampus ? "*" : ""}
												Tarde (Nombre)
											</label>
										</div>
										{
											editableCampus == null
												?
											<div className="roView">
												{selectedCampus.afternoon_shift_name || "-"}
											</div>
												:
											<input onChange={handleRegistryChange} id="afternoon_shift_name" maxLength={50}
											  onBlur={validateField} disabled={idleLocked}
											  ref={afternoonShiftNameRef} name="afternoon_shift_name" style={{width : "100%"}}
											  onFocus={validateField} placeholder="Nombre"
											  type="text" value={editableCampus.afternoon_shift_name} required
											/>
										}
									</div>
									<div style={{textAlign : "center", background : "#FF8327", borderRadius : "0px 0px 10px 10px", height : "35px", paddingTop : "7px"}}>
										<div style={{display : "inline-block", borderRadius : "15px", background : "#FFFFFF", fontWeight : "900"}}>
											{(()=>
												{
													let campus  : any    = editableCampus || selectedCampus
													let minutes : number = (campus.afternoonInterval[0] % 10)
													let hours   : number = (campus.afternoonInterval[0] - minutes) / 10

													return sprintf("%02d:%02d", hours, minutes * 6)
												}
											)()}
											&nbsp;
											-
											&nbsp;
											{(()=>
												{
													let campus  : any    = editableCampus || selectedCampus
													let minutes : number = (campus.afternoonInterval[1] % 10)
													let hours   : number = (campus.afternoonInterval[1] - minutes) / 10

													return sprintf("%02d:%02d", hours, minutes * 6)
												}
											)()}
										</div>
									</div>
								</div>
								<div style={{borderRadius : "15px", border : "3px solid #8F91DA", marginBottom : "5px"}}>
									<div className="inputContainer" style={{margin : "unset"}}>
										<div style={{borderRadius : "10px 10px 0px 0px", background : "#8F91DA", height : "20px"}}>
											<label htmlFor="afternoon_shift_name" style={{color : "#FFFFFF", position : "relative"}}>
												{editableCampus ? "*" : ""}
												Noche (Nombre)
											</label>
										</div>
										{
											editableCampus == null
												?
											<div className="roView">
												{selectedCampus.evening_shift_name || "-"}
											</div>
												:
											<input onChange={handleRegistryChange} id="evening_shift_name" placeholder="Nombre"
											  onBlur={validateField} style={{width : "100%"}}
											  ref={eveningShiftNameRef} value={editableCampus.evening_shift_name} maxLength={50}
											  onFocus={validateField} disabled={idleLocked}
											  name="evening_shift_name" type="text" required
											/>
										}
									</div>
									<div style={{textAlign : "center", background : "#8F91DA", borderRadius : "0px 0px 10px 10px", height : "35px", paddingTop : "7px"}}>
										<div style={{display : "inline-block", borderRadius : "15px", background : "#FFFFFF", fontWeight : "900"}}>
											{(()=>
												{
													let campus  : any    = editableCampus || selectedCampus
													let minutes : number = (campus.afternoonInterval[1] % 10)
													let hours   : number = (campus.afternoonInterval[1] - minutes) / 10

													return sprintf("%02d:%02d", hours, minutes * 6)
												}
											)()}
											&nbsp;
											-
											&nbsp;
											22:00
										</div>
									</div>
								</div>
								{
									editableCampus == null ? "" : <label htmlFor="buildingIds" style={{position : "relative"}}>
										Intervalos
									</label>
								}
							</div>
						</fieldset>
					</div>
					{
						editableCampus == null ? "" : <div style={{padding : "0px 7px"}}><table style={{background : "#FFFFFF", position : "relative", bottom : "15px"}}>
							<tbody><tr>
								<td>
									<FontAwesomeIcon icon={solid("sun")} />
								</td>
								<td style={{width : "100%", borderBottom : "1px solid #000000", padding : "0px 10px"}}>
									<Range min={80} max={210} step={5} allowOverlap={false}
									  disabled={idleLocked} values={editableCampus.afternoonInterval} onChange=
									  {
										(values : any)=>
										{
											if((values[0] != values[1]) && ((values[0] + 5) != values[1]))
											{
												setEditableCampus({...editableCampus, afternoonInterval : values})
											}
										}
									  } renderTrack=
									  {
										({props, children} : any) => <div {...props} style=
										  {{
											...props.style, height : "6px", width: "100%", background:
											"linear-gradient(to right, #FFC107 0%, #FFC107 25%, #FF8327 33%, #FF8327 50%, #FF8327 66%, #8F91DA 75%)"
										  }}
										>
											{children}
										</div>
									  } renderThumb=
									  {
										({props} : any) => <div {...props} key={Math.random()} style=
										  {{...props.style, height : "20px", width : "10px", background : "#2F316B", borderRadius : "15px"}}
										/>
									  }
									/>
								</td>
								<td>
									<FontAwesomeIcon icon={solid("moon")} />
								</td>
							</tr></tbody>
						</table></div>
					}
				</div>
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableCampus ? "*" : ""}
								Nombre
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{selectedCampus.name || "-"}
								</div>
									:
								<input onChange={handleRegistryChange} id="name" placeholder="Nombre" style={{width : "100%"}}
								  onBlur={validateField} disabled={idleLocked} type="text"
								  onFocus={validateField} value={editableCampus.name}
								  ref={nameRef} name="name" maxLength={50} required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="code">
								{editableCampus ? "*" : ""}
								Correlativo
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{selectedCampus.code || "-"}
								</div>
									:
								<input onBlur={validateField} style={{width : "100%"}}
								  type="text" onChange={handleRegistryChange} value={editableCampus.code} maxLength={50}
								  onFocus={validateField} placeholder="Correlativo"
								  ref={codeRef} name="code" id="code" disabled={idleLocked} required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="student_id_prefix">
								{editableCampus ? "*" : ""}
								Prefijo para estudiantes
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{selectedCampus.student_id_prefix || "-"}
								</div>
									:
								<input onBlur={validateField} style={{width : "100%"}}
								  type="text" onChange={handleRegistryChange} value={editableCampus.student_id_prefix} maxLength={50}
								  onFocus={validateField} placeholder="Prefijo para estudiantes"
								  ref={studentIdPrefixRef} name="student_id_prefix" id="student_id_prefix" disabled={idleLocked}
								  required
								/>
							}
						</div></div>
					</div>
					<br />
					<UnderlineHeading name={"Datos de contacto"} />
					<div className="row">
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="country_id">
								{editableCampus ? "*" : ""}
								País
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{
										(selectedCampus.city && selectedCampus.country.name)
											|| "-"
									}
								</div>
									:
								<>
									<select
									  value={editableCampus.country_id}
									  style={{height : "30px"}}
									  ref={countrySelRef}
									  className="btn btn-primary text-start"
									  id="country_id"
									  disabled={idleLocked}
									  name="country_id"
									  onChange=
									  {
										(event : any)=>
										{
											let campus = {...editableCampus, country_id : event.target.value}
											if(basicInfo.countryRef["C_" + campus.country_id].preferred_identification_type_id == null)
											{
												campus.state_id = ""
												campus.city     = null
											}

											setEditableCampus({...campus})
										}
									  }
									>{
										basicInfo.countries.filter((record : any) => basicInfo.countryRef["C_" + record.id].company).map
										(
											(record : any, i : any) => <option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}</select>
									<div className="iconRight" style={{pointerEvents : "none"}}>
										<CustomIcon name="rowDown" />
									</div>
								</>
							}
						</div></div>
						{
							(
								(editableCampus && basicInfo.countryRef["C_" + editableCampus.country_id].preferred_identification_type_id == null)
									||
								(selectedCampus.id && basicInfo.countryRef["C_" + selectedCampus.country_id].preferred_identification_type_id == null)
							) ? "" : <>
								<div className="col-md-6"><div className="selectContainer">
									<label htmlFor="country_id">
										{editableCampus ? "*" : ""}
										Estado/Depto./Prefectura...
									</label>
									{
										editableCampus == null
											?
										<div className="roView">
											<br />
											{(selectedCampus.city && selectedCampus.city.state.name) || "-"}
										</div>
											:
										(
											<>
												<select onFocus={validateField} disabled={idleLocked}
												  className="btn btn-primary text-start" onChange={handleRegistryChange} name="state_id"
												  onBlur={validateField} style={{height : "30px"}}
												  key={"countryStates_" + editableCampus.country_id} value={editableCampus.state_id}
												  id="state_id" ref={stateSelRef} required
												>
													<option key={"SOC_" + editableCampus.country_id} value="">
														Seleccione...
													</option>
													{
														basicInfo.countryRef["C_" + editableCampus.country_id] &&
														basicInfo.countryRef["C_" + editableCampus.country_id].states.map
														(
															(record : any, i : number)=> <option value={record.id} key={record.id}>
																{record.name}
															</option>
														)
													}
												</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
										)
									}
								</div></div>
								<div className="col-md-6">
									<label htmlFor="city" style={{position : "unset"}}>
										{editableCampus ? "*" : ""}
										Ciudad
									</label>
									{
										editableCampus == null
											?
										<div className="roView">
											{(selectedCampus.city && selectedCampus.city.name) || "-"}
										</div>
											:
										(
											(editableCampus.state_id == null || basicInfo.countryRef["C_" + editableCampus.country_id].stateRef["S_" + editableCampus.state_id] == null)
												?
													<div className="text-center">
														&lt;Elija estado&gt;
													</div>
												:
											<Popover positions={["bottom"]} isOpen={noCityProvided} content=
											  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
												<div className="requirementAlert">
													<FontAwesomeIcon icon={solid("exclamation")}/>
												</div>
												&nbsp;
												Requerido: Elija una opción
											  </div>}
											><div>
												<SelectReact placeholder="Ciudad" id="city" name="city" disabled={idleLocked}
												  children={Object.values(basicInfo.countryRef["C_" + editableCampus.country_id].stateRef["S_" + editableCampus.state_id].cityRef)}
												  onChange={handleRegistryChange} value={editableCampus.city}
												/>
											</div></Popover>
										)
									}
								</div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name" style={{position : "relative"}}>
										Dirección
									</label>
									{
										editableCampus == null
											?
										<div className="roView">
											{selectedCampus.address || "-"}
										</div>
											:
										<div style={{paddingTop : "10px"}}>
											<input onFocus={validateField} maxLength={50} placeholder="Dirección actual" id="address"
											  ref={addressRef} onChange={handleRegistryChange} name="address" disabled={idleLocked}
											  style={{width : "100%", position : "relative"}} value={editableCampus.address} type="text"
											  onBlur={validateField}
											/>
										</div>
									}
								</div></div>
								<div className="col-md-6"><div className="inputContainer">
									<label htmlFor="name">
										Código postal
									</label>
									{
										editableCampus == null
											?
										<div className="roView">
											<br />
											{selectedCampus.zipcode}
										</div>
											:
										<input value={editableCampus.zipcode} placeholder="Código postal" min={10000} name="zipcode"
										  onChange={handleRegistryChange} ref={zipcodeRef} id="zipcode" type="number"
										  onBlur={validateField} style={{width : "100%"}}
										  max={999999999}
										/>
									}
								</div></div>
							</>
						}
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Teléfono 1
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{selectedCampus.phone1 || "-"}
								</div>
									:
								<input value={editableCampus.phone1} id="phone1" style={{width : "100%"}} disabled={idleLocked}
								  onBlur={validateField} ref={phone1Ref} maxLength={50} name="phone1" onFocus={validateField}
								  placeholder="Teléfono 1" onChange={handleRegistryChange} minLength={8} type="tel"
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Teléfono 2
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{selectedCampus.phone2 || "-"}
								</div>
									:
								<input style={{width : "100%"}} type="tel" name="phone2" disabled={idleLocked} maxLength={50}
								  value={editableCampus.phone2} placeholder="Teléfono 2" onChange={handleRegistryChange}
								  onBlur={validateField} ref={phone2Ref} id="phone2" minLength={8}
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="email">
								Correo electrónico
							</label>
							{
								editableCampus == null
									?
								<div className="roView">
									<br />
									{selectedCampus.email || "-"}
								</div>
									:
								<input name="email" style={{width : "100%"}} disabled={idleLocked} value={editableCampus.email}
								  onFocus={validateField} minLength={8} id="email" ref={phone2Ref} maxLength={50} type="email"
								  onBlur={validateField} placeholder="Correo electrónico" onChange={handleRegistryChange}
								/>
							}
						</div></div>
						{
							Object.keys(linkInfo).map
							(
								(record : any, index : number) => <div className="col-md-6" key={record}><div
								  className="inputContainer"
								>
										<label htmlFor={record}>
										{linkInfo[record]}
									</label>
									{
										editableCampus == null
											?
										<div className="roView">
											<br />
											{selectedCampus[record] || "-"}
										</div>
											:
										<input style={{width : "100%"}} disabled={idleLocked}
										  value={editableCampus[record]} type="text" id={record}
										  placeholder={linkInfo[record]} name={record} maxLength={50}
										  onChange={handleRegistryChange}
										/>
									}
								</div></div>
							)
						}
					</div>
					<br />
					<>
						<UnderlineHeading name={"Observaciones"} />
						{
							editableCampus == null ? selectedCampus.observations || "-" : <textarea name="observations"
							  onBlur={validateField} className="notesField form-control"
							  value={editableCampus.observations} onChange={handleRegistryChange}
							  disabled={idleLocked} id="observations" maxLength={255} ref={observationsRef}
							/>
						}
					</>
				</div>
			</div></div></form></DataDisplayModal>
		}
	</>
}

export default Campuses
