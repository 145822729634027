import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../globals";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V2,
    timeout: 600000,
});

const axiosInstanceV1 = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
});

const useAxiosInterceptor = () => {
    const token = useSelector((state: RootState) => state.rootReducer.sessionState.user?.token);

    useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
            (config: any) => {
                if (token) {
                    // console.log('Request Interceptor for axiosInstance', config);
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                console.error('Request Error for axiosInstance', error);
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => {
                // console.log('Response Interceptor for axiosInstance', response);
                return response;
            },
            (error) => {
                console.error('Response Error for axiosInstance', error);
                return Promise.reject(error);
            }
        );

        // clean interceptor when component is unmnounted
        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [token]);

    // AXIOS INSTANCE FOR API V1
    useEffect(() => {
        const requestInterceptor = axiosInstanceV1.interceptors.request.use(
            (config: any) => {
                // console.log('Request Interceptor for axiosInstanceV1', config);
                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                console.error('Request Error for axiosInstanceV1', error);
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axiosInstanceV1.interceptors.response.use(
            (response) => {
                // console.log('Response Interceptor for axiosInstanceV1', response);
                return response;
            },
            (error) => {
                console.error('Response Error for axiosInstanceV1', error);
                return Promise.reject(error);
            }
        );

        // clean interceptor when component is unmnounted
        return () => {
            axiosInstanceV1.interceptors.request.eject(requestInterceptor);
            axiosInstanceV1.interceptors.response.eject(responseInterceptor);
        };
    }, [token]);
};

export { axiosInstance, axiosInstanceV1, useAxiosInterceptor };
