import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Constants, { INPUT_TYPES } from "../../constants";
import { InputTypes } from "../../types";

export interface InputColumnFilterProps extends React.HTMLAttributes<HTMLDivElement> {
    onFilterChange: (e: any) => void;
    type?: InputTypes;
    placeholder?: string;
    defaultValue?: string | number;
}

const InputColumnFilterComponent: React.FC<InputColumnFilterProps> = ({
    onFilterChange,
    type = INPUT_TYPES.INPUT_TEXT,
    placeholder,
    defaultValue
}) => {

    const [value, setValue] = useState<string>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onFilterChange(value);
        }
    };

    return (
        <div style={{
            display: "flex",
            gap: "10px",
            width: "100%"
        }}>
            <input
                type={type === INPUT_TYPES.INPUT_TEXT ? "text" : "number"}
                value={value || defaultValue || ""}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder ?? "Enter value"}
                style={{
                    textAlign: "right",
                    width: "70%",
                    borderBottom: `2px solid ${Constants.Styles.text.colors.gray}`,
                    marginBottom: "5px"
                }} />
            <button
                disabled={value === ""}
                onClick={() => onFilterChange(value)}
                style={{
                    width: "30%",
                    color: "#8F91DA",
                    padding: "10px",
                    background: "#F3F8FF",
                    height: "44px"
                }}>
                <FontAwesomeIcon
                    icon={solid("arrow-turn-down")}
                    transform={{ rotate: 90 }}
                />
            </button>
        </div>
    );
};

export default InputColumnFilterComponent;