import React,
{
    useEffect,
    useState
} from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";
import Constants, { PERMISSIONS_CODES } from "../../../../constants";
import { UserState } from "../../../../redux/reducers/sessionReducer";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../../globals";
import HttpManager from "../../../../services/HttpManager";
import { getErrorMessage } from "../../../../common";
import { default as SelectComponent } from "react-select";
import UnderlineHeading from "../../../underlineHeading";
import FileUploadComponent from "../../../fileUpload/fileUpload";
import { styles } from "./style";
import { StudentDTO } from "../../../../models/StudentDTO";


export interface EmitPaymentProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: StudentDTO
    /**
     * Content to render inside the card.
     */
    invoice: any;
    /**
     * items in invoice.
     */
    itemsInInvoice: any;
    /**
     * total to paid.
     */
    totalToPaid: number;
    /**
     * Is partial payment
     */
    isPartialPayment: boolean;
    /**
     * Is partial payment
     */
    isNewInvoice: boolean;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function to Cancel emit payment operation
     */
    cancelEmitPayment: () => void
    /**
     * 
     * @function to go apply credit modal
     */
    showApplyCreditModal: () => void
    /**
     * 
     * @function to Handle success payment
     */
    onSuccess: () => void
}

interface PaymentFormData {
    paidAmount: number,
    paymentMethodId: number,
    paymentDate: string,
    accountId: number,
    reference: string;
    notes: string;
    attachedFiles: File[],
    invoiceItems: {
        tuitions: { id: number; discount: number; typeOfDiscount: number }[];
        articles: { id: number; discount: number; typeOfDiscount: number }[];
    };
}

const EmitPaymentModalComponent: React.FC<EmitPaymentProps> = ({
    student,
    invoice,
    itemsInInvoice,
    totalToPaid,
    isPartialPayment,
    isNewInvoice,
    closeModal,
    cancelEmitPayment,
    showApplyCreditModal,
    onSuccess,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const http = HttpManager.getInstance();
    const permissions: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.permissions);
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const paymentMethodOptions: any[] = useSelector((state: RootState) => state.rootReducer.catalog.allowedPaymentMethods);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const [startDate, setStartDate] = useState<string>(
        new Date().toLocaleDateString('en-CA')
    );
    const [recipientAccountOptions, setRecipientAccountOptions] = useState<any[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>({});
    const [selectedAccount, setSelectedAccount] = useState<any>({});
    const defaultpaymentMethod = paymentMethodOptions[0];
    const [total, setTotal] = useState<number>(isNewInvoice ? totalToPaid : invoice?.pendingAmount);

    const allowedFiletypes: any[] = [
        { file: "image/png", type: "png" },
        { file: "image/jpeg", type: "jpeg" },
        { file: "application/pdf", type: "pdf" },
        { file: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", type: "xlxs" },
        { file: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", type: "docx" }
    ];

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm<PaymentFormData>();

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    const handleFileChange = (files: File[]) => {
        setValue("attachedFiles", files);
    }

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    }

    const payOwedItems = async (data: PaymentFormData) => {
        try {
            dispatch(enableIdleLockSwitch());
            const invoiceItems: any = {
                tuitions: Array.from(itemsInInvoice.tuitions),
                articles: Array.from(itemsInInvoice.articles)
            };
            const payload = {
                ...data,
                invoiceItems
            };
            const response = isPartialPayment && !isNewInvoice ?
                await http.invoiceService.addPayment(
                    userData?.token ?? "",
                    currentCampus.id,
                    student.id,
                    invoice.id,
                    payload
                )
                : await http.paymentService.payOwedItems(
                    userData?.token ?? "",
                    currentCampus.id,
                    student.id,
                    payload
                )
            reset();
            onSuccess();
        } catch (error) {
            console.error("Error submitting form:", error);
            onError(getErrorMessage(error));
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const validateReference = (value: string) => {
        if (!selectedPaymentMethod.isReferenceNull) {
            if (!value) {
                return Constants.validations.fieldIsRequired(Constants.Common.reference);
            }
            if (value.length < 10) {
                return Constants.validations.fieldMinLength(Constants.Common.reference, 10);
            }
        }
        return true;
    }

    const validatePartialAmount = (value: any) => {
        if (value < 1) {
            return 'El valor no puede ser menor a 1';
        }
        if (isNewInvoice) {
            if (value > totalToPaid) {
                return `El valor no puede ser mayor a ${totalToPaid}`;
            }
        } else {
            if (value > invoice.pendingAmount) {
                return `El valor no puede ser mayor a ${invoice.pendingAmount}`;
            }
        }
        return true;
    }

    const handlePartialAmount = (e: any) => {
        let value = parseFloat(e.target.value);
        if (isNewInvoice) {
            if (value > totalToPaid) {
                value = totalToPaid;
            }
        } else {
            if (value > invoice.pendingAmount) {
                value = invoice.pendingAmount;
            }
        }
        if (value < 1) {
            value = 1;
        }
        setValue('paidAmount', value);
        setTotal(value);
    }

    const validateApplyCredit = () => {
        if (isPartialPayment) {
            return student
                && student?.generalBalance?.positiveBalance?.value > 0 
                && student?.generalBalance?.positiveBalance?.value >= total;
        }

        return student
            && student?.generalBalance?.positiveBalance?.value > 0
            && (!isPartialPayment && student?.generalBalance?.positiveBalance?.value >= total)
    }

    const renderUsePositiveBalance = () => {
        if (permissions.includes(PERMISSIONS_CODES.CREDITS_PERMISSIONS.CREATE)) {
            return validateApplyCredit() ?
                <div style={{
                    width: "50%",
                    display: "flex",
                    alignItems: "baseline",
                    paddingTop: "2px"
                }}>
                    <button
                        onClick={showApplyCreditModal}
                        disabled={idleLocked}
                        type="button"
                        className="btn btn-outline-danger rounded-pill"
                        style={{
                            width: "100%"
                        }} >
                        {Constants.Common.usePositiveBalance}
                    </button>
                </div>
                : <React.Fragment />
        }
        return <React.Fragment />;
    }

    useEffect(() => {
        setSelectedPaymentMethod(paymentMethodOptions[0]);
        setValue('paymentMethodId', paymentMethodOptions[0].id);
    }, []);

    useEffect(() => {
        if (selectedPaymentMethod) {
            setRecipientAccountOptions(selectedPaymentMethod.accounts);
        }
    }, [selectedPaymentMethod]);

    useEffect(() => {
        if (recipientAccountOptions && recipientAccountOptions.length === 1) {
            setSelectedAccount(recipientAccountOptions[0]);
            setValue('accountId', recipientAccountOptions[0].id);
        } else {
            setSelectedAccount({});
            setValue('accountId', 0);
        }
    }, [recipientAccountOptions]);

    useEffect(() => {
        if (!isPartialPayment || (isPartialPayment && invoice?.invoiceItems?.length > 1)) {
            setValue('paidAmount', totalToPaid);
        }
    }, [isPartialPayment]);

    useEffect(() => {
        register("attachedFiles", {
            validate: {
                required: (value) =>
                    selectedPaymentMethod?.attachmentRequire
                        ? value.length > 0 || Constants.validations.fieldIsRequired(Constants.Common.attachedFiles)
                        : true,
            },
        });
    }, [selectedPaymentMethod, register]);

    return (
        student &&
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{
                    flexGrow: 1
                }}>
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.invoicePayment}
                        &nbsp;
                        {isNewInvoice ? "nueva" : invoice?.id}
                    </span>
                </h2>
                <button
                    type="button"
                    onClick={closeModal}
                    style={{
                        ...styles.btnCloseModal,
                        flexGrow: 0,
                        zIndex: 2
                    }}
                >
                    <FontAwesomeIcon
                        icon={solid("times")}
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }} />
                </button>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px"
            }}>
                <div
                    style={{
                        width: "100%",
                        paddingTop: "2px",
                        marginBottom: "10px"
                    }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "baseline",
                            paddingTop: "2px"
                        }}>
                        <div style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "baseline",
                            paddingTop: "2px"
                        }}>
                            <label htmlFor="name"
                                style={{
                                    position: "relative",
                                    ...styles.smallGrayText
                                }}>
                                {Constants.Common.student}
                            </label>
                        </div>
                        {
                            renderUsePositiveBalance()
                        }
                    </div>
                    <div style={{
                        width: "50%",
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {student.firstName}
                        &nbsp;
                        {student.lastName}
                    </div>
                </div>

                <form onSubmit={handleSubmit(payOwedItems)}
                    className="form"
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}>
                    <div style={{
                        background: "#F1F1FF",
                        padding: "15px",
                        display: "flex"
                    }}>
                        <h5
                            style={{
                                display: "inline-block",
                                width: "70%",
                                textAlign: "center"
                            }}>
                            {
                                `${Constants.Common.paymentReceive}(${currentCampus.currencyCode})`
                            }
                        </h5>
                        <div style={{
                            display: "inline-block",
                            width: "30%",
                            textAlign: "right",
                            borderBottom: "1px solid #7678BF"
                        }}>
                            {isPartialPayment && invoice?.invoiceItems?.length === 1
                                || isPartialPayment && isNewInvoice ?
                                <input
                                    id="total"
                                    {
                                    ...register('paidAmount', {
                                        required: Constants.validations.fieldIsRequired(Constants.Common.totalText),
                                        min: {
                                            value: 1,
                                            message: Constants.validations.fieldMinValue(Constants.Common.totalText, 0),
                                        },
                                        max: {
                                            value: total,
                                            message: Constants.validations.fieldMaxValue(Constants.Common.totalText, total),
                                        },
                                        validate: value => validatePartialAmount(value),
                                    })}
                                    onChange={(e: any) => handlePartialAmount(e)}
                                    value={total}
                                    type="number"
                                    style={{
                                        display: "inline-block",
                                        width: "100%",
                                        textAlign: "right"
                                    }}
                                />
                                : <h5 style={{
                                    marginBottom: "unset"
                                }}>
                                    {sprintf("%.02f", totalToPaid)}
                                </h5>
                            }
                        </div>
                    </div>
                    {errors.paidAmount &&
                        <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            width: "100%"
                        }}>
                            <p style={{
                                ...styles.errorMessage,
                            }}>
                                {errors.paidAmount.message}
                            </p>
                        </div>
                    }
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "50%"
                        }}>
                            <label htmlFor="date"
                                style={{
                                    ...styles.smallGrayText,
                                    position: "relative"
                                }}>
                                {Constants.Common.paymentDate}
                            </label>
                            <div style={{
                                paddingRight: "10px",
                                width: "100%"
                            }}>
                                <input
                                    id="paymentDate"
                                    {
                                    ...register('paymentDate',
                                        {
                                            required: Constants.validations.fieldIsRequired(Constants.Common.paymentDate)
                                        }
                                    )}
                                    type="date"
                                    disabled={idleLocked || selectedPaymentMethod.paymentDateUnmodifiable}
                                    defaultValue={startDate}
                                    onClick={(e) => e.currentTarget.showPicker()}
                                    onChange={handleDateChange}
                                    style={{
                                        width: "100%",
                                        border: "2px solid #cccccc",
                                        padding: "2px",
                                        borderRadius: "4px",
                                        cursor: selectedPaymentMethod.paymentDateUnmodifiable ? "not-allowed" : "pointer"
                                    }} />
                                {errors.paymentDate &&
                                    <p style={{
                                        ...styles.errorMessage
                                    }}>
                                        {errors.paymentDate.message}
                                    </p>
                                }
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%"
                        }}>
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: "10px",
                                width: "50%"
                            }}>
                                <label htmlFor="name"
                                    style={{
                                        ...styles.smallGrayText,
                                        position: "relative"
                                    }}>
                                    {Constants.Common.paymentMethod}
                                    {"*"}
                                </label>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                    paddingRight: "10px",
                                }}>
                                    <SelectComponent
                                        id="paymentMethodId"
                                        {
                                        ...register('paymentMethodId',
                                            {
                                                required: Constants.validations.fieldIsRequired(Constants.Common.paymentMethod)
                                            }
                                        )}
                                        defaultValue={defaultpaymentMethod}
                                        options={paymentMethodOptions}
                                        isDisabled={idleLocked}
                                        onChange={(value) => {
                                            setSelectedPaymentMethod(value);
                                            setValue('paymentMethodId', value.id);
                                        }}
                                    />
                                    {errors.paymentMethodId &&
                                        <p style={{
                                            ...styles.errorMessage
                                        }}>
                                            {errors.paymentMethodId.message}
                                        </p>
                                    }
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: "10px",
                                width: "50%"
                            }}>
                                <label htmlFor="name"
                                    style={{
                                        ...styles.smallGrayText,
                                        marginLeft: "10px",
                                        position: "relative"
                                    }}>
                                    {Constants.Screens.Finances.modalPayments.payment.depositTo}
                                    {"*"}
                                </label>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                    paddingLeft: "10px",
                                }}>
                                    <SelectComponent
                                        id="accountId"
                                        {
                                        ...register('accountId',
                                            {
                                                required: Constants.validations.fieldIsRequired(Constants.Common.recipientAccount)
                                            }
                                        )}
                                        value={selectedAccount}
                                        options={recipientAccountOptions}
                                        isDisabled={idleLocked || Object.keys(selectedPaymentMethod).length === 0}
                                        onChange={(value) => {
                                            setSelectedAccount(value);
                                            setValue('accountId', value.id);
                                        }}
                                    />
                                    {errors.accountId &&
                                        <p style={{
                                            ...styles.errorMessage
                                        }}>
                                            {errors.accountId.message}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                        {selectedPaymentMethod.isReferenceNull ? "" :
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%"
                            }}>
                                <div style={{
                                    paddingRight: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "50%"
                                }}>
                                    <label
                                        style={{
                                            ...styles.smallGrayText,
                                            position: "relative"
                                        }}
                                        htmlFor="reference_number"
                                    >
                                        {`${Constants.Common.reference}*`}
                                    </label>
                                </div>
                                <div style={{
                                    verticalAlign: "bottom",
                                    width: "100%"
                                }}>
                                    <input id="reference"
                                        {
                                        ...register('reference',
                                            {
                                                validate: value => validateReference(value)
                                            })}
                                        placeholder="Referencia"
                                        disabled={idleLocked}
                                        type="text"
                                        style={{
                                            width: "100%",
                                            border: "1px solid #7678BF",
                                            borderRadius: "5px",
                                            padding: "5px"
                                        }}
                                    />
                                    {errors.reference &&
                                        <p style={{
                                            ...styles.errorMessage
                                        }}>
                                            {errors.reference.message}
                                        </p>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        <label htmlFor="notes"
                            style={{
                                ...styles.smallGrayText,
                                position: "relative"
                            }}>
                            {Constants.Common.notes}
                        </label>
                        <textarea id="notes"
                            {
                            ...register('notes',
                                {
                                    required: Constants.validations.fieldIsRequired(Constants.Common.notes),
                                    minLength: {
                                        value: 10,
                                        message: Constants.validations.fieldMinLength(Constants.Common.notes, 10)
                                    }
                                }
                            )}
                            name="notes"
                            placeholder={Constants.Common.notes}
                            style={{
                                resize: "vertical",
                                width: "100%",
                                border: "1px solid #7678BF",
                                borderRadius: "5px",
                                minHeight: "150px",
                                padding: "5px"
                            }}
                        />
                        {errors.notes &&
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {errors.notes.message}
                            </p>
                        }
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        <UnderlineHeading
                            name={
                                selectedPaymentMethod?.attachmentRequire
                                ? Constants.Common.attachments + "*"
                                : Constants.Common.attachments
                            }
                            style={{
                                marginTop: "20px"
                            }} />

                        <div style={{
                            paddingRight: "10px",
                            display: "flex",
                            flexDirection: "column",
                            width: "50%"
                        }}>
                            <FileUploadComponent
                                allowedFiletypes={allowedFiletypes}
                                onFilesChange={handleFileChange}
                            />
                        </div>
                        {errors.attachedFiles &&
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {errors.attachedFiles.message}
                            </p>
                        }
                    </div>

                    <br />
                    <div style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "10px"
                    }}>
                        <button className="btn btn-outline-danger rounded-pill"
                            type="submit"
                            disabled={idleLocked}
                            style={{
                                width: "50%"
                            }}>
                            {Constants.Screens.Finances.modalPayments.btnPayment}
                        </button>
                        <button
                            onClick={cancelEmitPayment}
                            disabled={idleLocked}
                            type="button"
                            className="btn btn-outline-default rounded-pill"
                            style={{
                                width: "50%",
                                border: "1px solid #000000"
                            }} >
                            {Constants.Common.cancel}
                        </button>
                    </div>
                </form>
            </div>


        </React.Fragment>
    );

}

export default EmitPaymentModalComponent;