import {
configureStore,
createSlice,
PayloadAction
} from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer
	from "./redux/reducers/rootReducer";

const persistConfig: any = {
	key: "root",
	storage,
	whitelist: [
		"accountStatement",
		"catalog",
		"sessionState",
		"schedule",
		"payments",
		"invoices",
		"credits"
	]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


//counterReducer: an example of a simple reducer (unused)--------------------------------------------------------------------
const UpdateCounterAction: string = "Counter"
const counterInit: { value: number } = { value: 0 }
export const counterSlice = createSlice
	({
		name: UpdateCounterAction,
		initialState: counterInit,
		reducers:
		{
			incrementCounter: (state) => {
				state.value += 1;
			},
			decrementCounter: (state) => {
				state.value -= 1;
			},
			incrementCounterByAmount: (state, action: PayloadAction<number>) => {
				state.value += action.payload
			},
			setCounter: (state, action: PayloadAction<number>) => {
				state.value = action.payload
			},
		},
	})
//themeToggleReducer---------------------------------------------------------------------------------------------------------
const UpdateThemeToggleAction: string = "ThemeToggle"
const themeToggleInit: { value: boolean } = { value: false }
export const themeToggleSlice = createSlice
	({
		name: UpdateThemeToggleAction,
		initialState: themeToggleInit,
		reducers: { toggleTheme: (state) => { state.value = !state.value } }
	})
//idleLockSwitchReducer------------------------------------------------------------------------------------------------------
const UpdateIdleLockSwitchAction: string = "IdleLockSwitch"
const idleLockSwitchInit: { value: boolean } = { value: false }
export const idleLockSwitchSlice = createSlice
	({
		name: UpdateIdleLockSwitchAction,
		initialState: idleLockSwitchInit,
		reducers:
		{
			enableIdleLockSwitch: (state) => { state.value = true },
			disableIdleLockSwitch: (state) => { state.value = false }
		},
	})
//authenticatedReducer-------------------------------------------------------------------------------------------------------
const UpdateAuthenticatedAction: string = "Authenticated"
const authenticatedInit: { value: boolean } = { value: false }
export const authenticatedSlice = createSlice
	({
		name: UpdateAuthenticatedAction,
		initialState: authenticatedInit,
		reducers: { setAuthenticated: (state, action: PayloadAction<boolean>) => { state.value = action.payload } }
	});
//userProfileReducer---------------------------------------------------------------------------------------------------------
const UpdateUserProfileAction: string = "UserProfile"
const userProfileInit: { value: string | null } = { value: null }
export const userProfileSlice = createSlice
	({
		name: UpdateUserProfileAction,
		initialState: userProfileInit,
		reducers: { setUserProfile: (state, action: PayloadAction<string | null>) => { state.value = action.payload } }
	});
//basicInfoReducer-----------------------------------------------------------------------------------------------------------
const UpdateBasicInfoAction: string = "BasicInfo"
const basicInfoInit: { value: any } = { value: null }
export const basicInfoSlice = createSlice
	({
		name: UpdateBasicInfoAction,
		initialState: basicInfoInit,
		reducers: { setBasicInfo: (state, action: PayloadAction<any | null>) => { state.value = action.payload } }
	});
//currentUserRoleReducer------------------------------------------------------------------------------------------------------
const UpdateCurrentUserRoleAction: string | null = "CurrentUserRole"
const CurrentUserRoleInit: { value: string | null } = { value: null }
export const currentUserRoleSlice = createSlice
	({
		name: UpdateCurrentUserRoleAction,
		initialState: CurrentUserRoleInit,
		reducers: { setCurrentUserRole: (state, action: PayloadAction<string | null>) => { state.value = action.payload } }
	});
//currentUserCampusReducer------------------------------------------------------------------------------------------------------
const UpdateCurrentUserCampusAction: string = "CurrentUserCampus"
const CurrentUserCampusInit: { value: string | null } = { value: null }
export const currentUserCampusSlice = createSlice
	({
		name: UpdateCurrentUserCampusAction,
		initialState: CurrentUserCampusInit,
		reducers: { setCurrentUserCampus: (state, action: PayloadAction<string | null>) => { state.value = action.payload } }
	});
//currentUserCampusIdReducer------------------------------------------------------------------------------------------------------
const UpdateCurrentUserCampusIdAction: string = "CurrentUserCampusId"
const CurrentUserCampusIdInit: { value: number | null } = { value: null }
export const currentUserCampusIdSlice = createSlice
	({
		name: UpdateCurrentUserCampusIdAction,
		initialState: CurrentUserCampusIdInit,
		reducers: { setCurrentUserCampusId: (state, action: PayloadAction<number | null>) => { state.value = action.payload } }
	});
//campusRefReducer--------------------------------------------------------------------------------------------------------
const UpdateCampusRefAction: any = "CampusRef"
const CampusRefInit: { value: any | null } = { value: null }
export const campusRefSlice = createSlice
	({
		name: UpdateCampusRefAction,
		initialState: CampusRefInit,
		reducers: { setCampusRef: (state, action: PayloadAction<any | null>) => { state.value = action.payload } }
	});
//sessionTokenReducer--------------------------------------------------------------------------------------------------------
const UpdateSessionTokenAction: string = "SessionToken"
const sessionTokenInit: { value: string | null } = { value: null }
export const sessionTokenSlice = createSlice
	({
		name: UpdateSessionTokenAction,
		initialState: sessionTokenInit,
		reducers: { setSessionToken: (state, action: PayloadAction<string | null>) => { state.value = action.payload } }
	});
//userMenuReducer------------------------------------------------------------------------------------------------------------
const UpdateUserMenuAction: any = "UserMenu"
const userMenuInit: { value: any | null } = { value: null }
export const userMenuSlice = createSlice
	({
		name: UpdateUserMenuAction,
		initialState: userMenuInit,
		reducers: { setUserMenu: (state, action: PayloadAction<any | null>) => { state.value = action.payload } }
	});
//menuRefReducer-------------------------------------------------------------------------------------------------------------
const UpdateMenuRefAction: any = "MenuRef"
const menuRefInit: { value: any | null } = { value: null }
export const menuRefSlice = createSlice
	({
		name: UpdateMenuRefAction,
		initialState: menuRefInit,
		reducers: { setMenuRef: (state, action: PayloadAction<any | null>) => { state.value = action.payload } }
	});
//navigationReducer----------------------------------------------------------------------------------------------------------
const UpdateNavigationAction: any = "Navigation"
const navigationInit: { value: any | null } = { value: null }
export const navigationSlice = createSlice
	({
		name: UpdateNavigationAction,
		initialState: navigationInit,
		reducers: { setNavigation: (state, action: PayloadAction<any | null>) => { state.value = action.payload } }
	});
//commonNoticeReducer--------------------------------------------------------------------------------------------------------
const UpdateCommonNoticeAction: string = "ModalContent"
const commonNoticeInit: { heading: any | null; message: string | null; procedure?: any; optOutProcedure?: any; cornerClose?: boolean } =
	{ heading: null, message: null, procedure: null, optOutProcedure: null, cornerClose: true }
export const commonNoticeSlice = createSlice
	({
		name: UpdateSessionTokenAction,
		initialState: commonNoticeInit,
		reducers:
		{
			displayNotice: (state, action: PayloadAction<{ heading: any | null; message: string | null; procedure?: any; optOutProcedure?: any; cornerClose?: boolean }>) => {
				state.heading = action.payload.heading;
				state.message = action.payload.message;

				if (action.payload.procedure) {
					state.procedure = action.payload.procedure;
				}

				if (action.payload.optOutProcedure) {
					state.optOutProcedure = action.payload.optOutProcedure;
				}

				if (action.payload.cornerClose != null) {
					state.cornerClose = action.payload.cornerClose;
				}
			},
			dismissNotice: (state) => {
				state.heading = null
				state.message = null
				state.procedure = null
			}
		}
	})
//store----------------------------------------------------------------------------------------------------------------------
const store = configureStore
	({
		reducer:
		{
			counter: counterSlice.reducer,
			sessionToken: sessionTokenSlice.reducer,
			userProfile: userProfileSlice.reducer,
			basicInfo: basicInfoSlice.reducer,
			currentUserRole: currentUserRoleSlice.reducer,
			currentUserCampus: currentUserCampusSlice.reducer,
			currentUserCampusId: currentUserCampusIdSlice.reducer,
			campusRef: campusRefSlice.reducer,
			authenticated: authenticatedSlice.reducer,
			commonNotice: commonNoticeSlice.reducer,
			idleLockSwitch: idleLockSwitchSlice.reducer,
			themeToggle: themeToggleSlice.reducer,
			userMenu: userMenuSlice.reducer,
			menuRef: menuRefSlice.reducer,
			navigation: navigationSlice.reducer,
			rootReducer: persistedReducer
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}),
	})

export const persistor = persistStore(store);

export const { incrementCounter, decrementCounter, incrementCounterByAmount, setCounter } = counterSlice.actions
export const { toggleTheme } = themeToggleSlice.actions
export const { displayNotice, dismissNotice } = commonNoticeSlice.actions
export const { enableIdleLockSwitch, disableIdleLockSwitch } = idleLockSwitchSlice.actions
export const { setSessionToken } = sessionTokenSlice.actions
export const { setUserProfile } = userProfileSlice.actions
export const { setBasicInfo } = basicInfoSlice.actions
export const { setCurrentUserRole } = currentUserRoleSlice.actions
export const { setCurrentUserCampus } = currentUserCampusSlice.actions
export const { setCurrentUserCampusId } = currentUserCampusIdSlice.actions
export const { setCampusRef } = campusRefSlice.actions
export const { setAuthenticated } = authenticatedSlice.actions
export const { setUserMenu } = userMenuSlice.actions
export const { setMenuRef } = menuRefSlice.actions
export const { setNavigation } = navigationSlice.actions
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store
