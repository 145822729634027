import { CSSProperties } from "react";

const common = {
    title: <CSSProperties> {
        font: "normal 16px Roboto",
        color: "#8F91DA",
        opacity: 1,
        paddingTop: "10px",
        paddingBottom: "10px",
        position: "relative"
    },
    description: <CSSProperties> {
        font: "bold 16px Roboto"
    }
}


export const styles = {
    updateCampusInfo: {
        student: {
            title:{
                v: {...common.title}
            },
            name:{
                v: <CSSProperties> {
                    ...common.description,
                    alignSelf: "center",
                    marginLeft: "12px",
                    marginBottom: 0
                }
            }
        },
        activeCampuses: {
            title: {
                v : {...common.title}
            },
            campus: {
                v: {
                    ...common.description,
                    marginBottom: "10px"
                }
            }
        },
        availableCampuses: {
            title:{
                v: {...common.title}
            }
        }
    }
}