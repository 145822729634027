import {
    createAsyncThunk,
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';
import HttpManager from '../../services/HttpManager';

interface Tuition {
    id: number;
    discount: number;
    typeOfDiscount: number;
}

interface Article {
    id: number;
    discount: number;
    typeOfDiscount: number;
}

interface InvoiceItems {
    tuitions: Set<Tuition>;
    articles: Set<Article>;
}

const invoiceItems: InvoiceItems = {
    tuitions: new Set<Tuition>(),
    articles: new Set<Article>()
}

const initialState: any = {
    refresh: false,
    showModal: false,
    modalContent: 0,
    studentData: {},
    selectedInvoice: null,
    selectedCredit: null,
    refundItems: new Set<number>(),
    totalToPaid: 0,
    isPartialPayment: false,
    isCancelPayment: false,
    selectedPayment: {},
    error: null,
    invoiceItems
}

export const handlePaymentXlsx = createAsyncThunk(
    "payments/handlePaymentXlsx",
    async ({ campusId, criteria }: any, { rejectWithValue }) => {
        try {
            const response = await http.fileService.getPaymentReport(campusId, criteria);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Reporte de pagos.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { campusId, criteria };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const http = HttpManager.getInstance();
export const handleSelectedPayment = createAsyncThunk(
    "payments/handleSelectedPayment",
    async ({ sessionToken, campusId, paymentId }: any, { rejectWithValue }) => {
        try {
            const response = await http.paymentService.getPaymentById(sessionToken, campusId, paymentId);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const handlePaymentPdf = createAsyncThunk(
    "accountStatement/handlePaymentPdf",
    async ({ campusId, paymentId }: any, { rejectWithValue }) => {
        try {
            const response = await http.fileService.getPaymentPDF(campusId, paymentId);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pago_${paymentId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return paymentId;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const handleDownloadPaymentAttachment = createAsyncThunk(
    "accountStatement/handleDownloadPaymentAttachment",
    async ({ campusId, paymentId, documentId, fileName }: any, { rejectWithValue }) => {
        try {
            const response = await http.fileService.getPaymentAttachment(
                campusId,
                paymentId,
                documentId
            );
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url); // Limpia la URL cuando ya no se necesita
            return paymentId;
        } catch (error: any) {
            return rejectWithValue(error.response?.data);
        }
    }
);

const payments = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setRefresh: (state, action) => {
            state.refresh = action.payload;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
        setModalContent: (state, action) => {
            state.modalContent = action.payload;
        },
        setStudentData: (state, action) => {
            state.studentData = action.payload;
        },
        setSelectedInvoice: (state, action) => {
            state.selectedInvoice = action.payload;
        },
        setSelectedPayment: (state, action) => {
            state.selectedPayment = action.payload;
        },
        setSelectedCredit: (state, action) => {
            state.selectedCredit = action.payload;
        },
        setIsNewInvoice: (state, action) => {
            state.isNewInvoice = action.payload;
        },
        setTotalToPaid: (state, action) => {
            state.totalToPaid = action.payload;
        },
        setIsPartialPayment: (state, action) => {
            state.isPartialPayment = action.payload;
        },
        setIsCancelPayment: (state, action) => {
            state.isCancelPayment = action.payload;
        },
        setRefundItems: (state, action: PayloadAction<any>) => {
            state.refundItems = action.payload;
        },
        handleRefundItemsSelection: (state, action: PayloadAction<any>) => {
            const item = action.payload;
            if (state.refundItems.has(item.id)) {
                state.refundItems.delete(item.id);
            } else {
                state.refundItems.add(item.id);
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(handleSelectedPayment.pending, (state: any) => {
                    state.loadingPayment = true;
                })
                .addCase(handleSelectedPayment.fulfilled, (state: any) => {
                    state.loadingPayment = false;
                })
                .addCase(handleSelectedPayment.rejected, (state: any, action: any) => {
                    state.loadingPayment = false;
                    state.error = action.payload;
                })
                .addCase(handlePaymentPdf.pending, (state: any) => {
                    state.loadingPaymentPdf = true;
                })
                .addCase(handlePaymentPdf.fulfilled, (state: any) => {
                    state.loadingPaymentPdf = false;
                })
                .addCase(handlePaymentPdf.rejected, (state: any, action: any) => {
                    state.loadingPaymentPdf = false;
                    state.error = action.payload;
                });
        }
    }
});


export const {
    setShowModal,
    setRefresh,
    setModalContent,
    setStudentData,
    setSelectedInvoice,
    setSelectedCredit,
    setRefundItems,
    handleRefundItemsSelection,
    setSelectedPayment,
    setTotalToPaid,
    setIsPartialPayment,
    setIsCancelPayment,
    setIsNewInvoice,
} = payments.actions;

export default payments.reducer;
