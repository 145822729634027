import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { sprintf } from "sprintf-js";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import UnderlineHeading from "../../../underlineHeading";
import Constants,
{
    paymentStatus,
    PERMISSIONS_CODES
} from "../../../../constants";

import { styles } from "./style";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../../globals";
import { UserState } from "../../../../redux/reducers/sessionReducer";
import ButtonComponent from "../../../buttons/button";
import {
    handleDownloadPaymentAttachment,
    handlePaymentPdf
} from "../../../../redux/reducers/paymentsReducer";


export interface PaymentDetailProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The student data to show.
     */
    student: any;
    /**
     * Payment data to show.
     */
    payment: any;
    /**
     * 
     * @function to close Modal
     */
    closeModal: () => void
    /**
     * 
     * @function to close Modal
     */
    handleCancelPayment: () => void
}

const PaymentDetailModalComponent: React.FC<PaymentDetailProps> = ({
    student,
    payment,
    closeModal,
    handleCancelPayment
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const permissions: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.permissions);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currencies: any = useSelector((state: RootState) => state.rootReducer.catalog.currencies || []);
    const [currency, setCurrency] = useState<any>({});

    const handleDownloadClick = async () => {
        try {
            dispatch(enableIdleLockSwitch());
            await dispatch(handlePaymentPdf({
                campusId: currentCampus.id,
                paymentId: payment.id
            })).unwrap();   // unwrap para manejar correctamente errores en createAsyncThunk;
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    const downloadPaymentFileAttachment = async (document: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            await dispatch(handleDownloadPaymentAttachment({
                campusId: currentCampus.id,
                paymentId: payment.id,
                documentId: document.id,
                fileName: document.name
            })).unwrap(); // unwrap para manejar correctamente errores en createAsyncThunk;
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    useEffect(() => {
        const newCurrency = currencies.find((currency: any) => currency.id === payment.currencyId) || {};
        setCurrency(newCurrency);
    }, [currencies]);

    return (
        student &&
        <React.Fragment>
            <div style={{
                display: "flex",
                alignItems: "stretch"
            }}>
                <h2 style={{
                    flexGrow: 1
                }}>
                    <span style={{
                        paddingLeft: "10px"
                    }}>
                        {Constants.Common.invoicePayment} {payment.invoiceId}
                    </span>
                </h2>
                <button style={{
                    ...styles.btnCloseModal,
                    flexGrow: 0,
                    zIndex: 2
                }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon
                        icon={solid("times")}
                        style={{
                            height: "100%",
                            position: "relative",
                            bottom: "1px"
                        }}
                    />
                </button>
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                gap: "10px",
                padding: "10px"
            }}>
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <label htmlFor="name"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.student}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {student.firstName}
                        &nbsp;
                        {student.lastName}
                    </div>
                </div>
                <div style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right"
                }}>
                    <label htmlFor="name"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.number}
                    </label>
                    <div style={{
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {payment.id}
                    </div>
                </div>
            </div>

            <div style={{
                background: "#F1F1FF",
                padding: "15px"
            }}>
                <h5 style={{
                    display: "inline-block",
                    width: "70%",
                    textAlign: "center"
                }}>
                    {
                        `${Constants.Common.paymentReceive} (${currency.code}) `
                    }
                </h5>
                <div style={{
                    display: "inline-block",
                    width: "30%",
                    textAlign: "right",
                    borderBottom: "1px solid #7678BF"
                }}>
                    <h5 style={{
                        marginBottom: "unset"
                    }}>
                        {sprintf("%.02f", payment.amount)}
                    </h5>
                </div>
            </div>

            <div style={{
                display: "flex",
                padding: "10px",
                gap: "10px"
            }}>
                <div style={{ width: "50%" }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.date}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.paymentDate}
                    </div>
                </div>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="status"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.status}
                    </label>
                    <div className="roView"
                        style={{
                            color: payment.statusId === paymentStatus.CANCELLED
                                ? Constants.Styles.text.colors.redLine
                                : Constants.Styles.text.colors.blackPrimary,
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.status}
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                padding: "10px",
                gap: "10px"
            }}>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.method}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.paymentMethod}
                    </div>
                </div>
                <div style={{
                    width: "50%"
                }}>
                    <label htmlFor="status"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Screens.Finances.modalPayments.payment.depositTo}
                    </label>
                    <div className="roView"
                        style={{
                            borderBottom: "1px solid #7678BF"
                        }}>
                        {payment.account}
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                padding: "10px",
                marginBottom: "20px"
            }}>
                <div style={{
                    width: "100%"
                }}>
                    <label htmlFor="date"
                        style={{
                            ...styles.smallGrayText,
                            position: "relative"
                        }}>
                        {Constants.Common.notes}
                    </label>
                    <p style={{
                        padding: "5px",
                        margin: "0",
                        wordBreak: "break-word",
                        borderBottom: "1px solid #7678BF"
                    }}>
                        {payment.notes}
                    </p>
                </div>
            </div>
            <UnderlineHeading
                name={Constants.Common.attachments} />
            <div style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px"
            }}
            >{
                    payment.files.length === 0 ? "-" : payment.files.map((file: any) => {
                        return <div
                            key={file.name}
                            style={{
                                display: "flex"
                            }} >
                            <div style={{
                                paddingTop: "10px",
                                width: "100%"
                            }}>
                                <ButtonComponent
                                    className="wa-btn sm link"
                                    disabled={idleLocked}
                                    onClick={() => downloadPaymentFileAttachment(file)}
                                    tooltip={`Descargar archivo ${file.name}`}
                                    style={{
                                        padding: 0,
                                        paddingLeft: "10px",
                                        opacity: idleLocked ? 0.5 : 1,
                                        backgroundColor: "transparent",
                                        cursor: idleLocked ? "not-allowed" : "pointer"
                                    }}>
                                    {file.name}
                                </ButtonComponent>
                            </div>
                        </div>
                    })
                }</div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "20px"
                }}>
                    <OverlayTrigger overlay={
                        <Tooltip>
                            {Constants.Common.downloadProofOfPayment}
                        </Tooltip>
                    }>
                        <button
                            className="btn btnOutlinePurple rounded-pill"
                            type="button"
                            disabled={idleLocked}
                            onClick={handleDownloadClick}
                            style={{
                                width: "50%"
                            }}>
                            <FontAwesomeIcon
                                icon={regular("circle-down")}
                                style={{
                                    paddingRight: "10px"
                                }}
                            />
                            {Constants.Screens.Finances.modalPayments.downloadFile}
                        </button>
                    </OverlayTrigger>
                </div>
                {permissions.includes(PERMISSIONS_CODES.PAYMENT_PERMISSIONS.DELETE) ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingRight: "10px",
                        flexDirection: "column",
                        width: "100%",
                        marginBottom: "20px"
                    }}>
                        {!payment.isCancelable && payment.statusId !== paymentStatus.CANCELLED ?
                            <p style={{
                                ...styles.errorMessage
                            }}>
                                {Constants.Common.paymentIsNoCancelable}
                            </p> : <React.Fragment />
                        }
                        <button
                            disabled={
                                idleLocked
                                || payment.statusId === paymentStatus.CANCELLED
                                || !payment.isCancelable
                            }
                            className="btn btn-outline-dark rounded-pill"
                            type="button"
                            onClick={handleCancelPayment}
                            style={{
                                width: "100%",
                                cursor: payment.statusId === paymentStatus.CANCELLED ? "not-allowed" : "pointer"
                            }}>
                            {Constants.Common.cancel}
                        </button>
                    </div>
                    : <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px"
                    }}>
                        <p style={{
                            ...styles.errorMessage,
                            textAlign: "center"
                        }}>
                            {Constants.permissions.payments.cancel}
                        </p>
                    </div>
                }
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <button className="btn btn-outline-default rounded-pill"
                        onClick={closeModal}
                        disabled={idleLocked}
                        type="button"
                        style={{
                            width: "50%",
                            border: "1px solid #000000"
                        }}
                    >
                        {Constants.Common.close}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );

}

export default PaymentDetailModalComponent;