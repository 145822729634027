import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import data from '../../assets/js/static.json';

interface Currency {
    id: number;
    name: string;
    code: string;
    symbol: string;
}

const initialState: any = {
    currencies: data.CURRENCIES,
    campuses: [],
    countries: [],
    recipientAccounts: [],
    paymentMethods: [],
    allowedPaymentMethods: [],
    paymentStatuses: data.PAYMENT_STATUS.map((paymentStatus: any) => ({
        ...paymentStatus,
        id: paymentStatus.id,
        value: paymentStatus.id,
        label: paymentStatus.name,
    })),
    invoiceStatuses: data.INVOICE_STATUS.map((invoiceStatus: any) => ({
        ...invoiceStatus,
        id: invoiceStatus.id,
        value: invoiceStatus.id,
        label: invoiceStatus.name
    })),
    enrollmentStatuses: data.ENROLLMENT_STATUS.map((enrollmentStatus: any) => ({
        ...enrollmentStatus,
        id: enrollmentStatus.id,
        value: enrollmentStatus.id,
        label: enrollmentStatus.name
    })),
    appliedCreditStatuses: data.APPLIED_CREDITS_STATUS.map((creditStatus: any) => ({
        ...creditStatus,
        id: creditStatus.id,
        value: creditStatus.id,
        label: creditStatus.name,
    })),
    APPLIED_CREDIT_TYPES: data.APPLIED_CREDITS_TYPES.map((creditType: any) => ({
        ...creditType,
        id: creditType.id,
        value: creditType.id,
        label: creditType.name,
        description: creditType.description
    })),
    studentStatuses: data.STUDENT_STATUSES.map((studentStatus: any) => ({
        ...studentStatus,
        id: studentStatus.id,
        value: studentStatus.id,
        label: studentStatus.name,
    })),
};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setCurrencies(state, action: PayloadAction<Currency[]>) {
            state.currencies = action.payload;
        },
        setPaymentStatuses: (state, action: PayloadAction<any>) => {
            const paymentStatuses = action.payload.map((paymentStatus: any) => ({
                ...paymentStatus,
                id: paymentStatus.id,
                value: paymentStatus.id,
                label: paymentStatus.name
            }));
            state.paymentStatuses = paymentStatuses;
        },
        setInvoiceStatuses: (state, action: PayloadAction<any>) => {
            const invoiceStatuses = action.payload.map((invoiceStatus: any) => ({
                ...invoiceStatus,
                id: invoiceStatus.id,
                value: invoiceStatus.id,
                label: invoiceStatus.name
            }));
            state.invoiceStatuses = invoiceStatuses;
        },
        setEnrollmentStatuses: (state, action: PayloadAction<any>) => {
            const enrollmentStatuses = action.payload.map((enrollmentStatus: any) => ({
                ...enrollmentStatus,
                id: enrollmentStatus.id,
                value: enrollmentStatus.id,
                label: enrollmentStatus.name
            }));
            state.enrollmentStatuses = enrollmentStatuses;
        },
        setStudentStatuses: (state, action: PayloadAction<any>) => {
            const studentStatuses = action.payload.map((studentStatus: any) => ({
                ...studentStatus,
                id: studentStatus.id,
                value: studentStatus.id,
                label: studentStatus.name,
            }));
            state.studentStatuses = studentStatuses;
        },
        setCampuses(state, action: PayloadAction<any>) {
            const campuses = action.payload.map((campus: any) => ({
                ...campus,
                id: campus.id,
                value: campus.id,
                label: campus.name,
            }));
            state.campuses = campuses;
        },
        setRecipientAccounts(state, action: PayloadAction<any>) {
            const recipientAccounts = action.payload.map((recipientAccount: any) => ({
                ...recipientAccount,
                id: recipientAccount.id,
                value: recipientAccount.id,
                label: recipientAccount.name,
            }));
            state.recipientAccounts = recipientAccounts;
        },
        setPaymentMethods(state, action: PayloadAction<any>) {
            state.paymentMethods = action.payload;
            const paymentMethods = action.payload.map((paymentMethod: any) => ({
                ...paymentMethod,
                value: paymentMethod.id,
                label: paymentMethod.name,
                id: paymentMethod.id,
                accounts: paymentMethod.accounts.map((account: any) => ({
                    ...account,
                    label: account.name,
                    value: account.id,
                    description: account.description
                }))
            }));
            state.allowedPaymentMethods = paymentMethods;
        }
    }
});

export const {
    setCurrencies,
    setPaymentStatuses,
    setInvoiceStatuses,
    setEnrollmentStatuses,
    setCampuses,
    setRecipientAccounts,
    setPaymentMethods,
}
    = catalogSlice.actions;
export default catalogSlice.reducer;