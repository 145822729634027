//IMPORTS
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
    useEffect,
    useState
} from "react";
import Modal from "react-modal";

//STYLES
import { styles }  from "./style";

//INTERFACE COMPONENT
export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    showModal: boolean;
    saveLock?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

//COMPONENT
const ModalComponent: React.FC<ModalProps> = ({ title, showModal, saveLock = true, onSave, onClose, children }) => {

    //useState
    const [isOpen, setIsOpen] = useState(showModal);

    //useEffect
    useEffect(() => {
        setIsOpen(showModal);
    }, [showModal]);

    //FUNCTIONS
    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    //VIEW
    return (
        <React.Fragment>
            <Modal
                appElement={document.getElementById('root') as HTMLElement}
                style={styles.modal.v}
                isOpen={isOpen}
                onRequestClose={closeModal}>
                <div 
                    className="modalContainer"
                    style={styles.modal.container.v}>
                    <div
                        className="header"
                        style={styles.modal.container.header.v}>
                        <div
                            className="title"
                            style={styles.modal.container.header.title.v}>
                            <span className="d-none d-sm-inline-block" >
                                {title}
                            </span>
                        </div>
                        <div
                            className="close"
                            style={styles.modal.container.header.close.v}>
                            <button
                                className="button btn btn-default"
                                type="button"
                                onClick={onClose}
                                style={styles.modal.container.header.close.button.v}>
                                <FontAwesomeIcon icon={solid("times")} size="2x" />
                            </button>
                        </div>
                    </div>
                    {
                        !onSave? null :
                        <div
                            className="buttons"
                            style={styles.modal.container.buttons.v}>
                            <div
                                className="content"
                                style={styles.modal.container.buttons.content.v}>
                                <div className="hexL">
                                    <div className="hexL0" />
                                </div>
                                <div className="hex">
                                    <div
                                        className="content"
                                        style={styles.modal.container.buttons.content.hex.content.v}>
                                        <div
                                            className="buttonList"
                                            style={styles.modal.container.buttons.content.hex.content.buttonList.v}>
                                            <div style={styles.modal.container.buttons.content.hex.content.buttonList.cardButton.v}>
                                                <button
                                                    className="rounded-pill btn btnPurple d-table-row"
                                                    disabled={saveLock}
                                                    onClick={onSave}
                                                    type="button"
                                                    id="mainAction">
                                                    <span className="d-table-cell">
                                                        <FontAwesomeIcon icon={solid("check-circle")} />
                                                    </span>
                                                    <span className="d-none d-md-table-cell ps-2">
                                                        Guardar
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hexR">
                                    <div className="hexR0" />
                                </div>
                            </div>
                        </div>
                    }
                    <div
                        className="body"
                        style={styles.modal.container.body.v}>
                        <div
                            className="content"
                            style={styles.modal.container.body.content.v}>
                            {children}
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default ModalComponent;