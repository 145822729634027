import store
	from "./../../globals"
import { axiosInstance }
	from "./axiosInstance"

export class EnrollmentService {

	async updateStudentEnrollment(
		studentId: number,
		enrollmentId: number,
		payload: any
	) {
		const campusId = store.getState().rootReducer.sessionState.currentCampusId;
		const URL: string = `/admin/
            campus/${campusId}/
            students/${studentId}/
            enrollments/${enrollmentId}/_update`.replace(/\s+/g, '');
		const response = await axiosInstance.put(URL, payload);
		return response;
	}

	async searchEnrollments(scheduleId: number) {
		const campusId = store.getState().rootReducer.sessionState.currentCampusId;
		const URL: string = `/admin/
            campus/${campusId}/
            schedules/${scheduleId}/
            _students`.replace(/\s+/g, "");
		const response = await axiosInstance.get(URL);
		return response;
	}
}