import React, {useState, useEffect, forwardRef, ForwardedRef}
	from "react"
import {useNavigate}
	from "react-router-dom"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid, regular}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"
import {Popover}
	from "react-tiny-popover"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice
}
	from "./../../globals"
import EnrollmentPrompt
	from "./../management/enrollments/enrollmentPrompt"
import {DataDisplayModal, UserPrompt}
	from "../../components/sectionContainer"
import CalendarSideFilter, {unrestrictedResultsDisplay}
	from "./calendarSideFilter"
import LeDatePicker
	from "./../../components/leDatePicker"
import CustomIcon
	from "./../../components/customIcon"
import ClassroomPrompt
	from "../../components/classroomPrompt"
import
{
	baseRole,
	weekDays,
	monthRef,
	retardWeekDayOffset,
	count,
	attendanceStatus,
	isEmpty,
	enrollingSet
}
	from "./../../common"
import HttpManager
	from "./../../services/HttpManager"
import CoursesService
	from "./../../services/campus/courses.service"
import PeriodsService
	from "./../../services/campus/periods.service"
import BuildingsService
	from "./../../services/campus/buildings.service"
import CalendarService
	from "./../../services/campus/calendar.service"
import FinancesService
	from "./../../services/campus/finances.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import AttendancesService
	from "./../../services/campus/attendances.service"
import CatalogService
	from "./../../services/catalog.service"
import Constants, {employeeStatus}
	from "./../../constants"
import {useCapacityStyles, usePendingBalancesStyles, usePendingKitsBalancesStyles, useEnrollmentStyles, useAttendanceStyles}
	from "./../../hooks/useDailyViewStyles"
import courseImage
	from "./../../assets/images/course.png"

import "./../../components/dropdown/style.css"

const CapacityComponent = (props : any)=>
{
	const {currentCapacity, maxCapacity} = props

	return <span style={useCapacityStyles(currentCapacity, maxCapacity)}>
		{currentCapacity}/{maxCapacity}
	</span>
}

const EnrollmentsComponent = (props : any)=>
{
	const {currentEnrollments, minEnrollments} = props

	return <span style={useEnrollmentStyles(currentEnrollments, minEnrollments)}>
		{currentEnrollments}/{minEnrollments}
	</span>
}

const AttendanceComponent = (props : any)=>
{
	const {currentAttendance, idealAttendance} = props

	return <span style={useAttendanceStyles(currentAttendance, idealAttendance)}>
		{currentAttendance}/{idealAttendance}
	</span>
}

const PendingBalanceComponent = (props : any)=>
{
	const {pendingBalancesCount} = props

	return <span style={usePendingBalancesStyles(pendingBalancesCount)}>
		{pendingBalancesCount}
	</span>
}

const PendingKitsBalanceComponent = (props : any)=>
{
	const {pendingKitsBalancesCount} = props

	return <span style={usePendingKitsBalancesStyles(pendingKitsBalancesCount)}>
		{pendingKitsBalancesCount}
	</span>
}

const DailyView = ()=>
{
	const TUITION_STATUSES : number = 0
	const KIT_STATUSES     : number = 1
	const COURSES          : number = 2
	const MENTORS          : number = 3
	const PERIODS          : number = 4
	const ENDING           : number = 5

	const DAY_HALF_HOURS   = 30

	const navigate           : any = useNavigate()
	const [courses, setCourses] = useState<any[]>([])
	const [periods, setPeriods] = useState<any[]>([])
	const [mentors, setMentors] = useState<any[]>([])
	const [courseRef, setCourseRef] = useState<any>({})
	const [distributionRef, setDistributionRef] = useState<any>({})
	const [periodRef, setPeriodRef] = useState<any>({})
	const [spaceRef, setSpaceRef] = useState<any>({})
	const [currentDay, setCurrentDay] = useState<any>(null)
	const [currentWeekDay, setCurrentWeekDay] = useState<any>(null)
	const dispatch = useDispatch()
	const [initSetup, setInitSetup] = useState<number | null>(null)
	const [noAttendancesFoundOn, setNoAttendancesFoundOn] = useState<string | null>(null)
	const [currentDayCountInPeriod, setCurrentDayCountInPeriod] = useState<number>(0)
	const [totalRecords, setTotalRecords] = useState<number>(0)
	const [data, setData] = useState<any[]>([])
	const [buildings, setBuildings] = useState<any[]>([])
	const [yearsRange, setYearsRange] = useState<any>([])
	const [minDate, setMinDate] = useState<any>([])
	const [maxDate, setMaxDate] = useState<any>([])
	const [buildingRef, setBuildingRef] = useState<any>({})
	const [tuitionStatusRef, setTuitionStatusRef] = useState<any>({})
	const [kitStatusRef, setKitStatusRef] = useState<any>({})
	const [dayAttendanceRef, setDayAttendanceRef] = useState<any>({})
	const icons             : any = [solid("chevron-left"), solid("chevron-right")]
	const idleLocked        : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean = useSelector((state : RootState) => state.themeToggle.value)
	const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any = useSelector((state : RootState) => state.campusRef.value)
	const basicInfo         : any = useSelector((state : RootState) => state.basicInfo.value)
	const [selectedUser, setSelectedUser] = useState<any>(null)
	const [selectedEnrollment, setSelectedEnrollment] = useState<any>(null)
	const [selectedClassroom, setSelectedClassroom] = useState<any>(null)
	const [today, setToday] = useState<any>(null)
	const [userCampus, setUserCampus] = useState<number>(currentUserCampus)
	const [currencySymbol, setCurrencySymbol] = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
	const [currencyCode, setCurrencyCode] = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code)
	const [sectionLoaded, setSectionLoaded] = useState<boolean>(false)
	const [notToday, setNotToday] = useState<boolean>(false)
	const [notADayBefore, setNotADayBefore] = useState<boolean>(false)
	const [notADayAfter, setNotADayAfter] = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [noResultsFound, setNoResultsFound] = useState<boolean>(false)
	const [unrestrictedDisplay, setUnrestrictedDisplay] = useState<boolean>(true)
	const [availabilityDisplay, setAvailabilityDisplay] = useState<boolean>(true)
	const [selectedSchedule, setSelectedSchedule] = useState<any>(null)
	const [tuitionFinance, setTuitionFinance] = useState<any>(null)
	const [kitFinance, setKitFinance] = useState<any>(null)
	const [compensationStudentRef, setCompensationStudentRef] = useState<any>(null)
	const [compensationDayStudentRef, setCompensationDayStudentRef] = useState<any>(null)
	const [dateRef, setDateRef] = useState<any>(null)
	const [attendanceStudentRef, setAttendanceStudentRef] = useState<any>(null)
	const [dateCount, setDateCount] = useState<any>(null)
	const [attendanceCountRef, setAttendanceCountRef] = useState<any>(null)
	const [dateList, setDateList] = useState<any>(null)
	const [monthDateCount, setMonthDateCount] = useState<any>(null)
	const defaultSearch : any=
	{
		q : "",
		period : null,
		capacity : [0, 126],
		attendance : [0, 101],
		subsMin : [0, 126],
		buildings : [],
		courses : [], 
		mentors : [],
		onlyPendingTuitions : false,
		onlyPendingKits : false
	}

	const [customSearch, setCustomSearch]       = useState<any>(defaultSearch)
	const [currentSearch, setCurrentSearch]     = useState<any>(defaultSearch)
	const [dayDistribution, setDayDistribution] = useState<any>([])
	const [scheduleRef, setScheduleRef]         = useState<any>([])
	var   errorMessage  : string

	const events = [{title : "Meeting", start : new Date()}]

	let eventGuid = 0;
	const createEventId = () => String(eventGuid++);

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const rangeFilterRef : any=
	{
		capacity:
		{
			label    : "Capacidad",
			limit    : 125,
			gradient : "#00FF00 0%, #0000FF 56%, #FFFF00 80%, #FF0000 100%"
		},
		attendance:
		{
			label    : "Asistencia",
			limit    : 100,
			gradient : "#FF0000 0%, #FFFF00 50%, #0000FF 75%, #00FF00 100%"
		},
		subsMin:
		{
			label    : "Ins/Min",
			limit    : 125,
			gradient : "#FF0000 0%, #FFFF00 75%, #0000FF 100%"
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search(customSearch.period)
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
						"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
						"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					dispatch(enableIdleLockSwitch())

					errorMessage = "Los estatuses de colegiaturas no pudieron ser extraídos"

					try
					{
						const expand : number = 1
						const search : any    = null
						const result : any    = await CatalogService.getOwedTuitionStatuses(sessionToken)

						if(result.status == 200 || result.status == 204)
						{
							let tuitionStatusIndex : any = {}

							setCourses
							(
								result.data.map
								(
									(tuition : any)=>
									{
										tuitionStatusIndex["TS_" + tuition.id] = tuition

										return {...tuition}
									}
								)
							)

							setTuitionStatusRef(tuitionStatusIndex)
							setInitSetup(TUITION_STATUSES)
						}
						else
						{
							procedureComplaint(errorMessage)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(errorMessage)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case TUITION_STATUSES:
						errorMessage = "Los estatuses de colegiaturas no pudieron ser extraídos"

						try
						{
							const expand : number = 1
							const result : any    = await CatalogService.getOwedArticleStatuses(sessionToken)

							if(result.status == 200 || result.status == 204)
							{
								let kitStatusIndex : any = {}

								setCourses
								(
									result.data.map
									(
										(tuition : any)=>
										{
											kitStatusIndex["KS_" + tuition.id] = tuition

											return {...tuition}
										}
									)
								)

								setKitStatusRef(kitStatusIndex)
								setInitSetup(KIT_STATUSES)
							}
							else
							{
								procedureComplaint(errorMessage)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(errorMessage)
							dispatch(disableIdleLockSwitch())
						}
					break;
					case KIT_STATUSES:
						errorMessage = "La lista de cursos registrados no pudo ser extraída"

						try
						{
							const expand : number = 1
							const search : any    = null
							const result : any    = await CoursesService.searchCourses
								(campusRef[currentUserCampus], search, expand)

							if(result.status == 200 || result.status == 204)
							{
								let courseIndex : any = {}
								setCourses
								(
									result.data.map
									(
										(course : any)=>
										{
											courseIndex["C_" + course.id] = course

											return {...course, levels : []}
										}
									)
								)

								setCourseRef(courseIndex)
								setInitSetup(COURSES)
							}
							else
							{
								procedureComplaint(errorMessage)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(errorMessage)
							dispatch(disableIdleLockSwitch())
						}
					break;
					case COURSES:
						errorMessage = "La lista de profesores no pudo ser extraída"

						dispatch(enableIdleLockSwitch())

						try
						{
							const result = await EmployeesService.searchEmployees
								({roleIds : [baseRole.MENTOR]})

							if(result.status == 200 || result.status == 204)
							{
								setMentors(result.data.map((mentor : any) =>({...mentor, name : mentor.first_name + " " + mentor.last_name})))

								setInitSetup(MENTORS)
							}
							else
							{
								procedureComplaint(errorMessage)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(errorMessage)
							dispatch(disableIdleLockSwitch())
						}
					break
					case MENTORS:
						errorMessage = "La lista de periodos registrados no pudo ser extraída"
					
						try
						{
							const result = await PeriodsService.searchPeriods(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								let periodIndex : any = {}

								setPeriods
								(
									result.data.map
									(
										(period : any)=>
										{
											periodIndex["P_" + period.id] = period

											return{
												...period, label : period.name +
												" [" +
													monthRef[parseInt(period.start.split("-")[1]) - 1] + " - " +
													monthRef[parseInt(period.end.split("-")[1]) - 1] +
												"]"
											}
										}
									)
								)

								setPeriodRef(periodIndex)
								setInitSetup(PERIODS)
							}
							else
							{
								procedureComplaint(errorMessage)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(errorMessage)
							dispatch(disableIdleLockSwitch())
						}
					break
					case PERIODS:
						if(periods.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
								("No hay periodos registrados, por lo que el acceso a este módulo permanecerá deshabilitado")
						}
						else
						{
							errorMessage = "El listado de edificios y aulas no pudo ser extraído"

							try
							{
								let expand   : string = "classrooms"
								const result : any    = await BuildingsService.searchBuildings
									(campusRef[currentUserCampus].id, {}, expand)

								if(result.status == 200 || result.status == 204)
								{
									let buildingIndex : any    = {}
									let j             : number = 0
									let dayArrange    : any    = []
									let i             : number

									setBuildings
									(
										result.data.map
										(
											(building : any)=>
											{
												buildingIndex["B_" + building.id] = building

												return{...building, classrooms : []}
											}
										)
									)

									setBuildingRef(buildingIndex)
									setYearsRange(basicInfo.futureRange)
									dayArrange.fill(undefined, 0, 29)//Half hours between 07:00 and 21:30 [(215 - 70)/10] * 2
									setDayDistribution(dayArrange)
									setCustomSearch(defaultSearch)
									setInitSetup(ENDING)
								}
								else
								{
									procedureComplaint(errorMessage)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(errorMessage)
								dispatch(disableIdleLockSwitch())
							}
						}
					break
					case ENDING:
						setSectionLoaded(true)
						search(customSearch.period)
					break
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(()=>{setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				setClearSearchRequested(false)
				search(customSearch.period)
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			if(currentUserCampus && (currentUserCampus != userCampus))
			{
				setCustomSearch(defaultSearch)
				setSectionLoaded(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setCurrencyCode(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code)
				setCurrencySymbol(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
				setUserCampus(currentUserCampus)
				setInitSetup(COURSES)
			}
		},
		[currentUserCampus, sectionLoaded]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const periodSearch = (event : any)=>
	{
		if(event && event.target)
		{
			const period = event.target.value

			let startYear  : number
			let endYear    : number
			let noPeriod   : boolean = isEmpty(period)
			let minSetDate : Date
			let maxSetDate : Date

			if(noPeriod)
			{
				startYear = basicInfo.futureRange[0]
				endYear = basicInfo.futureRange[basicInfo.futureRange.length - 1]
				minSetDate = new Date(startYear + "/01/01 23:00:00")
				maxSetDate = new Date(endYear   + "/12/31 23:00:00")
			}
			else
			{
				startYear = parseInt(period.start.split("-")[0])
				endYear = parseInt(period.end.split("-")[0])
				minSetDate = (new Date(period.start.replaceAll("-", "/") + " 23:00:00"))
				maxSetDate = (new Date(period.end.replaceAll("-", "/") + " 23:00:00"))
			}

			setMinDate(minSetDate)
			setMaxDate(maxSetDate)
			setYearsRange
			(
				startYear == endYear
					? [startYear]
						: (noPeriod ? basicInfo.futureRange : Array((endYear - startYear) + 1).fill(undefined).map((element, index) => index + startYear))
			)

			search(period, currentDay)
		}
	}

	const search = async (period : any, date? : any)=>
	{
		const searchError : string  = "La búsqueda no pudo ser completada"
		let proceedingSearch : boolean = true
		let newSearch : any = {...customSearch, period : period}

		setCustomSearch(newSearch)

		if(period && period.id)
		{
			const periodStart : any = new Date(period.start + " 23:00:00")
			const periodEnd   : any = new Date(period.end   + " 23:00:00")

			if(Math.floor((date - periodStart) / 86400000) == -1)
			{
				if(!notADayBefore)
				{
					setNotADayBefore(true)

					setTimeout(() => setNotADayBefore(false), 3000)
				}
			}
			else
			{
				if(Math.floor((date - periodEnd) / 86400000) == 1)
				{
					if(!notADayAfter)
					{
						setNotADayAfter(true)

						setTimeout(() => setNotADayAfter(false), 3000)
					}
				}
			}
		}

		if(!proceedingSearch)
		{
			setCurrentDay(date)
			setCurrentWeekDay(retardWeekDayOffset[date.getDay()])
		}
		else
		{
			try
			{
				dispatch(enableIdleLockSwitch())

				const result = await CalendarService.getDailyView(date, newSearch, rangeFilterRef)

				if(result.status == 200 || result.status == 204)
				{
					setCustomSearch(newSearch)
					setCurrentSearch(newSearch)

					if(date == "today" && result.data.itIsToday == false)
					{
						setNotToday(true)
						setTimeout(() => setNotToday(false), 3000)
					}
					else
					{
						/*
							A structure is prepared containing all involved buildings referred by a key (string "B_" followed by its id
							Inside each classroom there is an array of a full day 
							Finally that array is dumped into an array of 3 arrays to enqueue all schedules for each turn (morning, afternoon and evening).
						*/
						let distributionIndex : any = {}
						let classroomIndex : any = {}
						let scheduleIndex : any = {}
						let index : number
						let startingHour : number =  70
						let i : number
						let sessionIndex : any
						let totalDays : number
						let day : Date = new Date(result.data.date + " 23:00:00")
						let attendanceMap : any
						let displayUnrestricted : boolean = unrestrictedResultsDisplay(newSearch)
						let currentDayCount : number = 0
						let tuitionIndebtedCount : number
						let kitIndebtedCount : number
						let availableSpacesCount : number = 0
						let availableSpace : any = null
						let availableSpaces : any = []
						let studentRef : any = {}
						let availableSpaceIndex : any = {}
						let externalIndex : number = 0
						let length : number = 0
						let hours : number
						let distributionKeys : string[]
						let shiftIndexListing : number[]=
						[
							(campusRef[currentUserCampus].afternoon_shift_start - 70) / 5,
							(campusRef[currentUserCampus].evening_shift_start - 70) / 5
						]//Positions for 120 and 160 code hours (12:00 and 16:00 respectively)

						setUnrestrictedDisplay(displayUnrestricted)
						setToday(new Date(result.data.today + " 23:00:00"))
						setCurrentDay(day)
						setCurrentWeekDay(retardWeekDayOffset[day.getDay()])
						result.data.classrooms.map
						(
							(classroom : any)=>
							{
								distributionIndex["C_" + classroom.id]=
								{
									turnList : [[], [], []],
									day      : []
								}

								classroomIndex["C_" + classroom.id] = classroom
							}
						)

						result.data.sessions.map
						(
							(session : any)=>
							{
								if(scheduleIndex["S_" + session.schedule_id] == null)
								{
									sessionIndex = {}

									session.schedule.sessions.map
									(
										(session : any)=>
										{
											sessionIndex["S_" + session.week_day] = session
										}
									)

									studentRef = {}
									tuitionIndebtedCount = 0
									kitIndebtedCount = 0

									session
										.schedule
										.enrollments
										.sort((a : any, b : any) => +(a.created_at < b.created_at) - +(a.created_at > b.created_at))
										.map
										(
											(enrollment : any)=>
											{
												if(+enrollment.expiredMonthlyEnrollmentBalance > 0)
												{
													tuitionIndebtedCount++
												}

												if(+enrollment.kitsEnrollmentBalance > 0)
												{
													kitIndebtedCount++
												}

												if(studentRef["S_" + enrollment.student_id] == null)
												{
													studentRef["S_" + enrollment.student_id] = {...enrollment.student, enrollments : [enrollment]}
												}
												else
												{
													studentRef["S_" + enrollment.student_id].enrollments.push(enrollment)
												}
											}
										)

									attendanceMap = {}

									session.schedule.attendances.map
									(
										(attendance : any)=>
										{
											attendanceMap["S_" + attendance.student_id] = attendance.status_id
										}
									)

									scheduleIndex["S_" + session.schedule_id]=
									{
										...session.schedule, days : totalDays,
										tuitionIndebtedCount : tuitionIndebtedCount,
										kitIndebtedCount : kitIndebtedCount,
										attendanceRef : attendanceMap,
										sessionRef : sessionIndex,
										tuitionIndebtedShown : false,
										kitIndebtedShown : false,
										students : Object.values(studentRef)
									}
								}

								startingHour = session.start_hours
								i = startingHour

								do
								{
									index = (i - 70) / 5
									distributionIndex["C_" + session.schedule.classroom_id].day[index] = "S_" + session.schedule_id
									i += 5
								}
								while(i < session.end_hours)

								return session
							}
						)
						//Use of any of the function contemplated filters makes the available hours space display to be skipped
						distributionKeys = Object.keys(distributionIndex)

						if(displayUnrestricted)
						{
							if(distributionKeys.length > 0)
							{
								distributionKeys.map
								(
									(key : string)=>
									{
										if(availableSpace)
										{
											availableSpace.end_hours = ((externalIndex + 1) * 5) + 70
											availableSpaceIndex["A_" + availableSpacesCount] = availableSpace

											availableSpaces.push(availableSpace)
											availableSpacesCount++

											availableSpace = null
										}

										for(i = 0; i < DAY_HALF_HOURS; i++)//30
										{
											externalIndex = i

											if(distributionIndex[key].day[i] == null)
											{
												if(availableSpace == null)
												{
													hours = (externalIndex * 5) + 70

													availableSpace=
													{
														classroom   : classroomIndex[key],
														start_hours : (externalIndex * 5) + 70
													}
												}

												distributionIndex[key].day[i] = availableSpacesCount
											}
											else
											{
												if(availableSpace)
												{
													availableSpace.end_hours                         = (externalIndex * 5) + 70
													availableSpaceIndex["A_" + availableSpacesCount] = availableSpace

													availableSpaces.push(availableSpace)
													availableSpacesCount++

													availableSpace = null
												}
											}
										}

										if(availableSpace)
										{
											availableSpace.end_hours                         = ((externalIndex + 1) * 5) + 70
											availableSpaceIndex["A_" + availableSpacesCount] = availableSpace

											availableSpaces.push(availableSpace)
											availableSpacesCount++

											availableSpace = null
										}
									}
								)
							}

							if(availableSpace)
							{
								availableSpace.end_hours                         = ((externalIndex + 1) * 5) + 70
								availableSpaceIndex["A_" + availableSpacesCount] = availableSpace

								availableSpaces.push(availableSpace)
								availableSpacesCount++

								availableSpace = null
							}
						}

						distributionKeys.map
						(
							(key : string)=>
							{
								let j : number = 0

								for(i = 0; i < DAY_HALF_HOURS; i++)//30
								{
									if(i == shiftIndexListing[0] || i == shiftIndexListing[1])
									{
										j++
									}

									length = distributionIndex[key].turnList[j].length

									if(length < 1 || distributionIndex[key].turnList[j][length -1] != distributionIndex[key].day[i])
									{
										distributionIndex[key].turnList[j].push(distributionIndex[key].day[i])
									}
								}
							}
						)

						if(Object.keys(distributionIndex).length < 1 || (!unrestrictedResultsDisplay(customSearch) && Object.keys(scheduleIndex).length < 1))
						{
							if(!noResultsFound)
							{
								setNoResultsFound(true)
								setTimeout(() => setNoResultsFound(false), 3000)
							}
						}

						Object.keys(availableSpaceIndex).map((key : string) => {scheduleIndex[key] = availableSpaceIndex[key]})
						setDayAttendanceRef({...result.data.attendanceDays})
						setCurrentDayCountInPeriod(currentDayCount)
						setScheduleRef({...scheduleIndex})
						setDistributionRef({...distributionIndex})
						setSpaceRef({...availableSpaceIndex})
						setSectionLoaded(true)
					}
				}
				else
				{
					procedureComplaint(searchError)
				}
			}
			catch(error : any)
			{
				console.log(error)

				if(error.response.status == 400)
				{
					setNotToday(true)
					setDistributionRef({})
					setCurrentDay(date)
					setCurrentWeekDay(retardWeekDayOffset[date.getDay()])
					setTimeout(() => setNotToday(false), 3000)
				}
				else
				{

					procedureComplaint(searchError)
				}
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
	}

	const getScheduleAttendances = async (schedule : any, index? : number)=>
	{
		const startYear : number = parseInt
		(
			schedule
				.period
				.start
				.split("/")[0]
		)

		const endYear   : number = parseInt
		(
			schedule
				.period
				.end
				.split("/")[0]
		)

		setYearsRange
		(
			startYear == endYear ? [startYear] : Array(endYear - startYear).fill(undefined).map((element, index) => index + startYear)
		)

		let   hours               : number
		let   minutesPart         : number
		let   dateSelectedWeekDay : number
		const searchError         : string = "La consulta de horarios de asistencia solicitada no pudo ser ejecutada"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await AttendancesService.getScheduleAttendances(campusRef[currentUserCampus].id, schedule.id)

			if(result.status == 200|| result.status == 204)
			{
				if((result.data.regularAttendances.length + result.data.compensationAttendances.length) < 1)
				{
					setNoAttendancesFoundOn("S_" + schedule.id)
					setTimeout(() => setNoAttendancesFoundOn(null), 3000)
				}
				else
				{
					let dateListing              : any = {}
					let monthDateCounting        : any = {}
					let attendanceStudentIndex   : any = {}
					let studentIndex             : any = {}
					let compensationStudentIndex : any = {}
					let compensationDayList      : any = {}
					let dayAttendanceIndex       : any = {}
					let compensationRef          : any = {}
					let datePart                 : string
					let monthPart                : string
					let dayPart                  : string
					let dateKeys                 : string[]
					let ymd                      : any
					let compensateDayStudIndex   : any = {}
					let attendanceCountIndex     : any = {}

					result.data.regularAttendances.map
					(
						(attendance : any)=>
						{
							datePart  = attendance.attendance_datetime.split(" ")[0]
							ymd       = datePart.split("-")
							monthPart = monthRef[parseInt(ymd[1]) - 1]

							if(dateListing[datePart] == null)
							{
								dateListing[datePart] = weekDays
									[retardWeekDayOffset[(new Date(attendance.attendance_datetime)).getDay()]].substring(0, 2) + ymd[2]

								if(monthDateCounting[monthPart] == null)
								{
									monthDateCounting[monthPart] = 0
								}

								monthDateCounting[monthPart]++
							}

							if(attendanceCountIndex["S_" + attendance.student_id] == null)
							{
								attendanceCountIndex["S_" + attendance.student_id] = 0
							}

							if(attendance.status_id != attendanceStatus.ABSENT)
							{
								attendanceCountIndex["S_" + attendance.student_id]++
							}

							attendanceStudentIndex[datePart + "S_" + attendance.student_id] = attendance.status_id
						}
					)

					schedule.enrollments.map
					(
						(enrollment : any)=>
						{
							if(attendanceCountIndex["S_" + enrollment.student_id] == null)
							{
								attendanceCountIndex["S_" + enrollment.student_id] = 0
								studentIndex["S_" + enrollment.student_id]         = enrollment.student
							}
						}
					)

					result.data.compensationAttendances.map
					(
						(attendance : any)=>
						{
							datePart  = attendance.attendance_datetime.split(" ")[0]
							ymd       = datePart.split("-")
							monthPart = monthRef[parseInt(ymd[1]) - 1]

							if(dateListing[datePart] == null)
							{
								dateListing[datePart] = weekDays[retardWeekDayOffset[(new Date(attendance.attendance_datetime)).getDay()]].substring(0, 2) + ymd[2]

								if(monthDateCounting[monthPart] == null)
								{
									monthDateCounting[monthPart] = 0
								}

								monthDateCounting[monthPart]++
							}

							attendanceStudentIndex[datePart + "S_" + attendance.student_id] = attendance.status_id

							if(compensationDayList[datePart] == null)
							{
								compensationDayList[datePart] = []
							}

							compensationDayList[datePart].push("S_" + attendance.student_id)
						}
					)

					dateKeys = Object.keys(dateListing).sort()

					setDateRef(dateListing)
					setMonthDateCount(monthDateCounting)
					setAttendanceCountRef(attendanceCountIndex)
					setDateList(dateKeys)
					setDateCount(dateKeys.length)
					setAttendanceStudentRef(attendanceStudentIndex)
					setCompensationDayStudentRef(compensateDayStudIndex)
					setCompensationStudentRef(result.data.compensationStudentRef)

					setSelectedSchedule
					({
						...schedule, studentRef : studentIndex,
						compensationStudents    : result.data.compensationstudents
					})
				}
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	const searchOwedTuitions = async (schedule : any)=>
	{
		const searchError : string = "La consulta de colegiaturas para el horario seleccionado no pudo ser ejecutada"

		dispatch(enableIdleLockSwitch())

		try
		{
			const result : any = await FinancesService.searchOwedTuitions(campusRef[currentUserCampus].id, {scheduleId : schedule.id})

			if(result.status == 200 || result.status == 204)
			{
				let amount               : number
				let taxInPercentage      : boolean
				let discountInPercentage : boolean
				let taxId                : number
				let taxValue             : number
				let discountValue        : number
				let taxAmount            : number
				let discountAmount       : number
				let subTotal             : number

				setTuitionFinance
				({
					schedule : schedule,
					records  : result.data.data.map
					(
						(record : any)=>
						{
							amount               = +record.amount
							taxInPercentage      = record.tax_value.substr(-1) == "%"
							discountInPercentage = record.discount.substr(-1) == "%"
							taxId                = record.tax_id == null ? 0 : record.tax_id
							taxValue             = record.tax_id < 1 ? 0 : +(record.tax_value.replace("%", ""))
							discountValue        = +(record.discount.replace("%", ""))
							taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
							discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
							subTotal             = record.amount + taxAmount - discountAmount

							return {
								...record, taxId     : taxId,
								amount               : amount,
								refIndex             : "OA_" + record.id,
								taxInPercentage      : taxInPercentage,
								discountInPercentage : record.discount.substr(-1) == "%",
								taxValue             : taxValue,
								discountValue        : discountValue,
								taxAmount            : taxAmount,
								discountAmount       : discountAmount,
								subTotal             : subTotal
							}
						}
					)
				})
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	const searchOwedKits = async(schedule : any)=>
	{
		const searchError : string = "La consulta de kits para el horario seleccionado no pudo ser ejecutada"

		dispatch(enableIdleLockSwitch())

		try
		{
			const result : any = await FinancesService.searchOwedArticles
				(campusRef[currentUserCampus].id, {scheduleId : schedule.id})

			if(result.status == 200 || result.status == 204)
			{
				let amount               : number
				let taxInPercentage      : boolean
				let discountInPercentage : boolean
				let taxId                : number
				let taxValue             : number
				let discountValue        : number
				let taxAmount            : number
				let discountAmount       : number
				let subTotal             : number

				setKitFinance
				({
					schedule : schedule,
					records  : result.data.data.map
					(
						(record : any)=>
						{
							amount               = +record.amount
							taxInPercentage      = record.tax_value.substr(-1) == "%"
							discountInPercentage = record.discount.substr(-1) == "%"
							taxId                = record.tax_id == null ? 0 : record.tax_id
							taxValue             = record.tax_id < 1 ? 0 : +(record.tax_value.replace("%", ""))
							discountValue        = +(record.discount.replace("%", ""))
							taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
							discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
							subTotal             = record.amount + taxAmount - discountAmount

							return {
								...record, taxId     : taxId,
								amount               : amount,
								refIndex             : "OA_" + record.id,
								taxInPercentage      : taxInPercentage,
								discountInPercentage : record.discount.substr(-1) == "%",
								taxValue             : taxValue,
								discountValue        : discountValue,
								taxAmount            : taxAmount,
								discountAmount       : discountAmount,
								subTotal             : subTotal
							}
						}
					)
				})
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	const promptEnrolling = async(course : any, level : any, schedule : any)=>
	{
		let retrievalError : string = "La lista de matrículas no pudo ser extraída"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await HttpManager.getInstance().enrollmentService.searchEnrollments(schedule.id)

			if(result.status == 200 || result.status == 204)
			{
				setSelectedEnrollment(enrollingSet(course, level, schedule, result.data, campusRef[currentUserCampus].company_id))
			}
			else
			{
				procedureComplaint(retrievalError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(retrievalError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	const DateInput = forwardRef
	(
		({value, onClick} : any, ref : ForwardedRef<HTMLButtonElement>)=>
		{
			const dayLabel   : string = weekDays[retardWeekDayOffset[currentDay.getDay()]]
			const monthLabel : string = monthRef[currentDay.getMonth()]
			const yearLabel  : string = currentDay.getFullYear() + ""

			return (currentDay) && <button className="text-center btn btn-default" ref={ref} onClick={onClick} type="button"
			  style={{paddingTop : "15px", height : "100%", border : "1px solid #C7C7C7"}} disabled={idleLocked}
			><h4>
				<span className="d-xl-none d-lg-none d-md-none d-sm-none d-xs-none">
					Calendario
				</span>
				<span style={{display : "inline-block", color : "#8F91DA"}}>
					<span className="d-none d-md-inline-block">
						{dayLabel.substring(0, 2)}
					</span>
					<span className="d-none d-lg-inline-block">
						{dayLabel.substring(2)}
					</span>
					&nbsp;
					{sprintf("%02d", currentDay.getDate())}
					&nbsp;
					<span>
						{monthLabel.substring(0, 3)}
					</span>
					<span className="d-none d-lg-inline-block">
						{monthLabel.substring(3)}
					</span>
					&nbsp;
					<span className="d-none d-lg-inline-block">
						{yearLabel.substring(0, 2)}
					</span>
					<span>
						{yearLabel.substring(2)}
					</span>
				</span>
			</h4></button>
		}
	)

	return !sectionLoaded ? "" : <>
		<div style={{margin : "0px", padding : "15px"}}>
			<table><tbody><tr>
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell"
				  style={{verticalAlign : "bottom", paddingRight : "5px"}} 
				><h1>
					Calendario
				</h1></td>
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell" style={{width : "50px"}} />
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell"
				  style={{paddingTop : "5px"}}
				>
					<Popover positions={["bottom"]} isOpen={notToday} content=
					  {<div style={{background : "var(--second-bg)", padding : "5px 5px 0px 5px"}}>
						<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")}/>
						</div>
						&nbsp;
						El día se encuentra fuera del periodo seleccionado
					  </div>}
					><button style={{width : "100px", height : "50px", border : "1px solid #000000"}} onClick={() => search(customSearch.period, "today")}
					  disabled={idleLocked || notToday} className="btn btn-default rounded-pill" type="button"
					>
						Hoy
					</button></Popover>
				</td>
				<td style={{width : "50px"}} className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell" />
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell"><Popover positions={["bottom"]}
				  isOpen={notADayBefore || notADayAfter} content=
				  {<div style={{background : "var(--second-bg)", padding : "5px 5px 0px 5px"}}>
					<div className="requirementAlert">
						<FontAwesomeIcon icon={solid("exclamation")}/>
					</div>
					&nbsp;
					El día {notADayBefore ? "anterior" :  "posterior"} se encuentra fuera del periodo seleccionado
				  </div>}
				><div style={{display : "flex"}}>{
					count.map
					(
						(step : number, index : number) => <button key={"D" + step} style={{color : "#5154AE"}} type="button"
						  className="btn btn-default" disabled={idleLocked} onClick=
						  {
							()=>
							{
								let date = new Date(currentDay)

								date.setDate(currentDay.getDate() + step)
								search(customSearch.period, new Date(date))
							}
						  }
						><b>
							<FontAwesomeIcon icon={icons[index]} size="2x" />
						</b></button>
					)
				}</div></Popover></td>
				<td>{
					((yearsRange && yearsRange.length < 1) || currentDay == null) ? "" : <LeDatePicker disabled={idleLocked}
					  onChange={(e : any) => search(customSearch.period, e.target.value)} months={monthRef} name="birthdayValue"
					  maxDate={maxDate} years={yearsRange} value={currentDay} minDate={minDate} customInput={<DateInput />}
					  id="birthdayValue" required
					/>
				}</td>
			</tr></tbody></table>
			<div style={{display : "inline-block", position : "fixed", zIndex : 2, background : "var(--second-bg)", width : "310px", paddingTop : "15px"}}>
				<table><tbody><tr>
					<td style={{width : "100%"}}><Popover positions={["bottom"]} isOpen={noResultsFound} content=
					  {<div style={{background : "var(--second-bg)", padding : "5px 5px 0px 5px"}}>
						<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")}/>
						</div>
						&nbsp;
						Sin resultados
					  </div>}
					><button
					  style={{border : "1px solid #000000", width : "100%"}}
					  onClick={() => search(customSearch.period, currentDay)}
					  className="btn btn-default"
					  type="button"
					  disabled={idleLocked}
					>
						Buscar
						&nbsp;
						<FontAwesomeIcon icon={solid("magnifying-glass-arrow-right")} />
					</button></Popover></td>
					<td><div style={{width : "50px"}}>{
						_.isEqual(currentSearch, customSearch) && _.isEqual(currentSearch, defaultSearch) ? "" : <button
						  type="button"
						  className="btn btn-default"
						  disabled={idleLocked}
						  onClick={clearSearch}
						>
							<FontAwesomeIcon icon={solid("times")} />
						</button>
					}</div></td>
				</tr></tbody></table>
			</div>
		</div>
		<div style={{display : "flex", height : "calc(100vh - 149px)"}}>
			<CalendarSideFilter distributionRef={distributionRef} searchFn={search} periods={periods} periodRef={periodRef}
			  courses={courses} courseRef={courseRef} buildings={buildings} buildingRef={buildingRef} customSearch={customSearch}
			  searchChangeFn={handleSearchChange} mentors={mentors} scheduleRef={scheduleRef} periodSearch={periodSearch}
			  unrestrictedDisplay={unrestrictedDisplay} availabilityDisplay={availabilityDisplay}
			  setAvailabilityDisplay={setAvailabilityDisplay}
			/>
			<div style={{height : "calc(100vh - 146px)", width : "calc(100vw - 340px)", overflow : "auto"}}><table
			  style={{background : "#F2F2FF", minWidth : "1500px", height : "100%", width : "100%"}}
			><tbody>
				<tr style={{height : "0px"}}>
					<td style={{background : "#5154AE", textAlign : "center", padding: "5px", width : "500px", color : "#FFFFFF", border : "1px solid #C6C7EE"}}>
						{campusRef[currentUserCampus].morning_shift_name}
						<br />
						07:00
						&nbsp;
						-
						&nbsp;
						{(()=>
							{
								let minutes = (campusRef[currentUserCampus].afternoon_shift_start % 10)
								let hours   = (campusRef[currentUserCampus].afternoon_shift_start - minutes) / 10

								return sprintf("%02d:%02d", hours, minutes * 6)
							}
						)()}
					</td>
					<td style={{background : "#8F91DA", width : "50px"}} />
					<td style={{background : "#5154AE", textAlign : "center", padding: "5px", width : "500px", color : "#FFFFFF", border : "1px solid #C6C7EE"}}>
						{campusRef[currentUserCampus].afternoon_shift_name}
						<br />
						{(()=>
							{
								let minutes = (campusRef[currentUserCampus].afternoon_shift_start % 10)
								let hours   = (campusRef[currentUserCampus].afternoon_shift_start - minutes) / 10

								return sprintf("%02d:%02d", hours, minutes * 6)
							}
						)()}
						&nbsp;
						-
						&nbsp;
						{(()=>
							{
								let minutes = (campusRef[currentUserCampus].evening_shift_start % 10)
								let hours   = (campusRef[currentUserCampus].evening_shift_start - minutes) / 10

								return sprintf("%02d:%02d", hours, minutes * 6)
							}
						)()}
					</td>
					<td style={{background : "#8F91DA", width : "50px"}} />
					<td style={{background : "#5154AE", textAlign : "center", padding: "5px", width : "500px", color : "#FFFFFF", border : "1px solid #C6C7EE"}}>
						{campusRef[currentUserCampus].evening_shift_name}
						<br />
						{(()=>
							{
								let minutes = (campusRef[currentUserCampus].evening_shift_start % 10)
								let hours   = (campusRef[currentUserCampus].evening_shift_start - minutes) / 10

								return sprintf("%02d:%02d", hours, minutes * 6)
							}
						)()}
						&nbsp;
						-
						&nbsp;
						22:00
					</td>
				</tr>
				{
					Object.values(distributionRef).length < 1
						?
					<tr>
						<td style={{display : "table-cell", overflow : "hidden", height : "100%", width : "500px", border : "1px solid #9D9FDF"}} />
						<td style={{display : "table-cell", overflow : "hidden", height : "100%", width : "50px"}} />
						<td style={{display : "table-cell", overflow : "hidden", height : "100%", width : "500px", border : "1px solid #9D9FDF"}} />
						<td style={{display : "table-cell", overflow : "hidden", height : "100%", width : "50px"}} />
						<td style={{display : "table-cell", overflow : "hidden", height : "100%", width : "500px", border : "1px solid #9D9FDF"}} />
					</tr>
						:
					Object.keys(distributionRef).map
					(
						(key : any) => <tr key={key}>{
							distributionRef[key].turnList.map
							(
								(turn : any, index : number) => <React.Fragment key={key + "T_" + index}>
									{index < 1 ? "" : <td style={{display : "table-cell"}} />}
									<td style={{border : "1px solid #9D9FDF", display : "table-cell", height : "100%", overflow : "hidden", width : "500px", verticalAlign : "top"}}>
										<div style={{display : "flex", flexDirection : "column", alignItems : "flex-start", height : "100%"}}>{
											turn.length < 1 ? "" : turn.filter((key : any) => key != null).map
											(
												(key : any) => <div style={{padding : "5px 0px", width : "100%", flex : 1}} key={key + "T_" + index + key}>
													<div style={{border : "1px solid #9D9FDF", borderRadius : "5px", height : "100%", display : "flex", flexDirection : "column", alignItems : "stretch"}}>{
														isNaN(key)
															?
														<div style={{background : "#FFFFFF", borderTop : "10px solid #5154AE", flex : 1}}>
															<div style={{padding : "15px", display : "flex", flexDirection : "column", alignItems : "stretch", height : "100%"}}>
																<table style={{fontWeight : "900", width : "100%"}}><tbody>
																	<tr>
																		<td style={{verticalAlign : "top", paddingRight : "10px"}}>
																			<img src={scheduleRef[key].level.course.image || courseImage}
																			  style={{width : "25px", borderRadius : "50%", border : "3px solid #8F91DA"}}
																			/>
																		</td>
																		<td colSpan={2} style={{width : "100%"}}>
																			{scheduleRef[key].level.course.name}
																			<div style={{color : "#FF6600"}}>
																				<div>
																					{scheduleRef[key].period.name}
																				</div>
																			</div>
																			<div style={{color : "#8F91DA"}}>
																				{scheduleRef[key].level.name}
																				&nbsp;
																				{scheduleRef[key].name}
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td style={{color : "#8F91DA", height : "38px"}}>
																			<FontAwesomeIcon icon={regular("clock")} />
																		</td>
																		<td colSpan={2} style={{width : "100%"}}>
																			<span style={{color : "#C7C7C7"}}>
																				Horario:
																				&nbsp;
																			</span>
																			{
																				sprintf
																				(
																					"%02d:%02d-%02d:%02d",
																					(
																						scheduleRef[key].sessionRef["S_" + currentWeekDay].start_hours-
																							(scheduleRef[key].sessionRef["S_" + currentWeekDay].start_hours % 10)
																					) / 10,
																					(scheduleRef[key].sessionRef["S_" + currentWeekDay].start_hours % 10) * 6,
																					(
																						scheduleRef[key].sessionRef["S_" + currentWeekDay].end_hours-
																							(scheduleRef[key].sessionRef["S_" + currentWeekDay].end_hours % 10)
																					) / 10,
																					(scheduleRef[key].sessionRef["S_" + currentWeekDay].end_hours % 10) * 6
																				)
																			}
																		</td>
																	</tr>
																	<tr>
																		<td style={{color : "#8F91DA", height : "38px"}}>
																			<FontAwesomeIcon icon={solid("chalkboard-user")} />
																		</td>
																		<td>
																			<span style={{color : "#C7C7C7"}}>
																				Profesor:
																				&nbsp;
																			</span>
																			{
																				scheduleRef[key].mentor == null ? "-" :
																					scheduleRef[key].mentor.first_name + " " +
																						scheduleRef[key].mentor.last_name
																			}
																		</td>
																		<td style={{width : "1px"}}>{
																			scheduleRef[key].mentor == null ? "" : <button
																			  disabled={idleLocked} className="btn btn-default"
																			  type="button" onClick=
																			  {
																				() => setSelectedUser
({
	...scheduleRef[key].mentor, employeeView : true,
	imagePreview                             : scheduleRef[key].mentor.image,
	asProfessor                              : true,
	status_id                                : scheduleRef[key].mentor.bool_enabled ? employeeStatus.DISABLED : scheduleRef[key].mentor.employee.status_id
})
																			  }
																			>
																				<FontAwesomeIcon icon={solid("ellipsis")} />
																			</button>
																		}</td>
																	</tr>
																	<tr>
																		<td style={{color : "#8F91DA", height : "38px"}}>
																			<FontAwesomeIcon icon={solid("users")} />
																		</td>
																		<td>
																			<span style={{color : "#C7C7C7"}}>
																				Capacidad:
																				&nbsp;
																			</span>	
																			<CapacityComponent currentCapacity={+scheduleRef[key].enrolled_students}
																			  maxCapacity={+scheduleRef[key].classroom.students_limit} 
																			/>
																		</td>
																		<td />
																	</tr>
																	<tr>
																		<td style={{color : "#8F91DA", height : "38px"}}>
																			<FontAwesomeIcon icon={solid("address-book")} />
																		</td>
																		<td>
																			<span style={{color : "#C7C7C7"}}>
																				Ins/min:
																				&nbsp;
																			</span>
																			<EnrollmentsComponent minEnrollments={scheduleRef[key].students_minimum}
																			  currentEnrollments={scheduleRef[key].enrolled_students}
																			/>
																		</td>
																		<td />
																	</tr>
																	<tr>
																		<td style={{color : "#8F91DA", height : "38px"}}>
																			<FontAwesomeIcon icon={solid("list-check")} />
																		</td>
																		<td>
																			<span style={{color : "#C7C7C7"}}>
																				Asistencias:
																				&nbsp;
																			</span>
																			<AttendanceComponent idealAttendance={dayAttendanceRef[key][1]}
																			  currentAttendance={dayAttendanceRef[key][0]}
																			/>
																		</td>
																		<td style={{width : "1px"}}>{
																			scheduleRef[key].enrollments.length < 1 ? "" : <Popover
																			  isOpen={noAttendancesFoundOn == key} positions={["bottom"]}
																			  content=
																			  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
																				<div className="requirementAlert">
																					<FontAwesomeIcon icon={solid("exclamation")}/>
																				</div>
																				&nbsp;
																				No se encontraron asistencias en este horario
																			  </div>}
																			><button type="button" className="btn btn-default"
																			  disabled={idleLocked} onClick=
																			  {
																				()=>
																				{
																					if(noAttendancesFoundOn != key)
																					{
																						getScheduleAttendances(scheduleRef[key])
																					}
																				}
																			  }
																			>
																				<FontAwesomeIcon icon={solid("arrow-right")} />
																			</button></Popover>
																		}</td>
																	</tr>
																	<tr>
																		<td style={{color : "#8F91DA", height : "38px"}}>
																			<FontAwesomeIcon icon={solid("money-bills")} />
																		</td>
																		<td>
																			<span style={{color : "#C7C7C7"}}>
																				Saldos pendientes:
																				&nbsp;
																			</span>
																			<PendingBalanceComponent
																			  pendingBalancesCount={scheduleRef[key].tuitionIndebtedCount}
																			/>
																		</td>
																		<td style={{width : "1px"}}>{
																			scheduleRef[key].tuitionIndebtedCount < 1 ? "" : <button
																			  className="btn btn-default" disabled={idleLocked}
																			  type="button" onClick=
																			  {
																				()=>
																				{
																					setScheduleRef
																					({
																						...scheduleRef, [key] : {...scheduleRef[key],
																						tuitionIndebtedShown  : !scheduleRef[key].tuitionIndebtedShown}
																					})
																				}
																			  }
																			>
																				<FontAwesomeIcon icon={scheduleRef[key].tuitionIndebtedShown ? solid("chevron-down") : solid("chevron-up")} />
																			</button>
																		}</td>
																	</tr>
{
	!scheduleRef[key].tuitionIndebtedShown || scheduleRef[key].tuitionIndebtedCount < 1 ? "" : <tr>
		<td />
		<td colSpan={2}><div style={{maxHeight : "260px", overflowY : "auto"}}>
			<table style={{width : "100%", fontWeight : 300, borderCollapse : "separate", borderSpacing : "0px 2px"}}>
				<tbody>{
					scheduleRef[key]
						.students
						.filter((student : any) => (+student.enrollments[0].expiredMonthlyEnrollmentBalance) > 0)
						.sort((a : any, b : any)=> b.enrollments[0].expiredMonthlyEnrollmentBalance - a.enrollments[0].expiredMonthlyEnrollmentBalance)
						.map
						(
							(student : any) => <tr
							  style={{color : "#FFFFFF"}}
							  key={"t" + student.id}
							>
								<td style={{paddingLeft : "5px", background : "#BA0000", borderRadius : "15px 0px 0px 15px", width : "24px"}}>{
									(
										scheduleRef[key].attendanceRef["S_" + student.id] == null ||
											scheduleRef[key].attendanceRef["S_" + student.id] < attendanceStatus.ON_TIME
									) ? "" : <FontAwesomeIcon
									  icon={solid("circle-exclamation")}
									/>
								}</td>
								<td style={{background : "#BA0000"}}>{
									student.first_name + " " +
										student.last_name
								}</td>
								<td style={{width : "1px", paddingRight : "5px", background : "#BA0000", borderRadius : "0px 15px 15px 0px", textAlign : "right"}}>
									<div style={{display : "flex", alignItems : "stretch"}}>
										<span style={{paddingRight : "5px"}}>
											{currencySymbol}</span>
										<span style={{flexGrow : 1, whiteSpace : "nowrap"}}>{
											sprintf("%.02f", student.enrollments[0].expiredMonthlyEnrollmentBalance)
										}</span>
									</div>
								</td>
							</tr>
						)
				}</tbody>
			</table>
		</div></td>
	</tr>
}
																	{
																		scheduleRef[key].tuitionIndebtedCount < 1 ? "" : <tr>
																			<td />
																			<td colSpan={2}
																			  style={{color : "#8F91DA", padding : "5px", fontWeight : "300"}}
																			><u style={{cursor : "pointer"}}
																			  onClick={() => !idleLocked && searchOwedTuitions(scheduleRef[key])}
																			>
																				Finanzas...
																			</u></td>
																		</tr>
																	}
																	<tr>
																		<td style={{color : "#8F91DA", height : "38px"}}>
																			<FontAwesomeIcon icon={solid("toolbox")} />
																		</td>
																		<td>
																			<span style={{color : "#C7C7C7"}}>
																				Saldos kits:
																				&nbsp;
																			</span>
																			<PendingKitsBalanceComponent 
																			  pendingKitsBalancesCount={scheduleRef[key].kitIndebtedCount}
																			/>
																		</td>
																		<td style={{width : "1px"}}>{
																			scheduleRef[key].kitIndebtedCount < 1 ? "" : <button
																			  className="btn btn-default" disabled={idleLocked}
																			  onClick={() => setScheduleRef({...scheduleRef, [key] : {...scheduleRef[key], kitIndebtedShown : !scheduleRef[key].kitIndebtedShown}})}
																			  type="button"
																			>
																				<FontAwesomeIcon icon={scheduleRef[key].kitIndebtedShown ? solid("chevron-down") : solid("chevron-up")} />
																			</button>
																		}</td>
																	</tr>
																	{
																		!scheduleRef[key].kitIndebtedShown || scheduleRef[key].kitIndebtedCount < 1 ? "" : <tr>
																			<td />
																			<td colSpan={2}><div style={{maxHeight : "260px", overflowY : "auto"}}>
																				<table style={{width : "100%", fontWeight : 300, borderCollapse : "separate", borderSpacing : "0px 2px"}}>
																					<tbody>{
																						scheduleRef[key]
																							.students
																							.filter((student : any) => +student.enrollments[0].kitsEnrollmentBalance > 0)
																							.sort
																							(
																								(a : any, b : any)=>
																								{
																									return (+b.enrollments[0].kitsEnrollmentBalance) -
																										(+a.enrollments[0].kitsEnrollmentBalance)
																								}
																							)
																							.map
																							(
																								(enrollment : any) => <tr
																								  style={{color : "#FFFFFF"}}
																								  key={"k" + enrollment.id}
																								>
																									<td style={{paddingLeft : "5px", background : "#BA0000", borderRadius : "15px 0px 0px 15px", width : "24px"}}>{
																										(
																											scheduleRef[key].attendanceRef["S_" + enrollment.student_id] == null ||
																												scheduleRef[key].attendanceRef["S_" + enrollment.student_id] < attendanceStatus.ON_TIME
																										) ? "" : <FontAwesomeIcon
																										  icon={solid("circle-exclamation")}
																										/>
																									}</td>
																									<td style={{background : "#BA0000"}}>
																										{enrollment.student.first_name + " " + enrollment.student.last_name}
																									</td>
																									<td style={{width : "1px", paddingRight : "5px", background : "#BA0000", borderRadius : "0px 15px 15px 0px", textAlign : "right"}}>
																										<div style={{display : "flex", alignItems : "stretch"}}>
																											<span style={{paddingRight : "5px"}}>
																												{currencySymbol}
																											</span>
																											<span style={{flexGrow : 1}}>{
																												sprintf
																												(
																													"%.02f",
																														+enrollment.kit_debt_settlement
																												)
																											}</span>
																											<span style={{flexGrow : 1, whiteSpace : "nowrap"}}>{
																												sprintf
																												(
																													" / %.02f",
																														+enrollment.kit_debt
																												)
																											}</span>
																										</div>
																									</td>
																								</tr>
																							)
																					}</tbody>
																				</table>
																			</div></td>
																		</tr>
																	}
																	{
																		scheduleRef[key].kitIndebtedCount < 1 ? "" : <tr>
																			<td />
																			<td colSpan={2}
																			  style={{color : "#8F91DA", padding : "5px", fontWeight : "300"}}
																			><u style={{cursor : "pointer"}}
																			  onClick={() => !idleLocked && searchOwedKits(scheduleRef[key])}
																			>
																				Finanzas...
																			</u></td>
																		</tr>
																	}
																</tbody></table>
																<div style={{height : "calc(100% - 160px)"}} />
																<div style={{paddingTop : "25px", display : "inline-flex"}}>
																	<div style=
																	  {{display : "inline-block", border : "1px solid #9D9FDF", borderRadius : "5px", height : "38px"}}
																	><div style={{display : "flex"}}>
																		<div style={{width : "25px", height : "36px", background : "#3945FF", display : "inline-block", borderRadius : "5px 0px 0px 5px"}} />
																		<div style={{padding : "0px 10px", borderLeft : "1px solid #9D9FDF"}}>
																			<h5 style={{paddingTop : "7px", marginBottom : "unset"}}>
																				{scheduleRef[key].classroom.building.name}
																			</h5>
																		</div>
																	</div></div>
																	<div style={{display : "inline-block", border : "1px solid #9D9FDF", borderRadius : "5px", height : "38px", marginLeft : "10px"}}>
																		<div style={{display : "flex"}}>
																			<div style={{width : "25px", height : "36px", background : "#39FF45", display : "inline-block", borderRadius : "5px 0px 0px 5px"}} />
																			<div style={{padding : "0px 10px", borderLeft : "1px solid #9D9FDF"}}>
																				<h5 style={{paddingTop : "7px", marginBottom : "unset"}}>
																					{scheduleRef[key].classroom.name}
																				</h5>
																			</div>
																		</div>
																	</div>
																	<div style={{display : "inline-block", paddingLeft : "10px"}}>
																		<button type="button" className="btn btn-default"
																		  disabled={idleLocked} onClick=
																		  {
																			()=>
																			{
																				let hours : any = {}

																				scheduleRef[key].sessions.map
																				(
																					(session : any)=>
																					{
																						let currentHours : number = session.start_hours

																						while(currentHours < session.end_hours)
																						{
																							hours[sprintf("DH%d%03d", session.week_day, currentHours)] = true
																							currentHours += 5
																						}
																					}
																				)

																				promptEnrolling
																				(
																					scheduleRef[key].level.course,
																					{
																						...scheduleRef[key].level, kits : [],
																						kitRef : {}
																					},
																					{...scheduleRef[key], hours : hours}
																				)
																			}
																		  }
																		>
																			<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
																		</button>
																	</div>
																</div>
															</div>
																
														</div>
															:
														(
															!availabilityDisplay ? "" : <div
															  style={{border : "1px solid #9D9FDF", borderRadius : "5px",flex : 1}}
															>
																<div style={{padding : "15px", background : "#B8FFBC", border : "10px dashed #2F316B", height : "100%"}}>
																	<table style={{fontWeight : "900", width : "100%"}}><tbody>
																		<tr>
																			<td style={{verticalAlign : "top", paddingRight : "10px"}}>
																				<CustomIcon name="books2" style={{fill : "#8F91DA"}} />
																			</td>
																			<td style={{width : "100%"}}>
																				Espacio Libre
																			</td>
																		</tr>
																		<tr>
																			<td style={{color : "#8F91DA", height : "38px"}}>
																				<FontAwesomeIcon icon={regular("clock")} />
																			</td>
																			<td style={{width : "100%"}}>
																				<span style={{color : "#C7C7C7"}}>
																					Horario:
																					&nbsp;
																				</span>
																				{
																					sprintf
																					(
																						"%02d:%02d-%02d:%02d",
																						(
																							scheduleRef["A_" + key].start_hours-
																								(scheduleRef["A_" + key].start_hours % 10)
																						) / 10,
																						(scheduleRef["A_" + key].start_hours % 10) * 6,
																						(
																							scheduleRef["A_" + key].end_hours-
																								(scheduleRef["A_" + key].end_hours % 10)
																						) / 10,
																						(scheduleRef["A_" + key].end_hours % 10) * 6
																					)
																				}
																			</td>
																		</tr>
																		<tr>
																			<td style={{color : "#8F91DA", height : "38px"}}>
																				<FontAwesomeIcon icon={solid("users")} />
																			</td>
																			<td style={{width : "100%"}}>
																				<span style={{color : "#C7C7C7"}}>
																					Cupo:
																					&nbsp;
																				</span>
																				{scheduleRef["A_" + key].classroom.students_limit}
																			</td>
																		</tr>
																	</tbody></table>
																	<div style={{height : "calc(100% - 160px)"}} />
																	<div style={{paddingTop : "25px", display : "inline-flex"}}>
																		<div style=
																		  {{display : "inline-block", border : "1px solid #9D9FDF", borderRadius : "5px", height : "38px"}}
																		><div style={{display : "flex"}}>
																			<div style={{width : "25px", height : "36px", background : "#3945FF", display : "inline-block", borderRadius : "5px 0px 0px 5px"}} />
																			<div style={{padding : "0px 10px", borderLeft : "1px solid #9D9FDF"}}>
																				<h5 style={{paddingTop : "7px", marginBottom : "unset"}}>
																					{scheduleRef["A_" + key].classroom.building.name}
																				</h5>
																			</div>
																		</div></div>
																		<div style={{display : "inline-block", border : "1px solid #9D9FDF", borderRadius : "5px", height : "38px", marginLeft : "10px"}}>
																			<div style={{display : "flex"}}>
																				<div style={{width : "25px", height : "36px", background : "#39FF45", display : "inline-block", borderRadius : "5px 0px 0px 5px"}} />
																				<div style={{padding : "0px 10px", borderLeft : "1px solid #9D9FDF"}}>
																					<h5 style={{paddingTop : "7px", marginBottom : "unset"}}>
																						{scheduleRef["A_" + key].classroom.name}
																					</h5>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														)
													}</div>
												</div>
											)
										}</div>
									</td>
								</React.Fragment>
							)
						}</tr>
					)
				}
			</tbody></table></div>
		</div>
		{
			selectedEnrollment == null || selectedUser ? "" : <EnrollmentPrompt closeFn={() => {setSelectedEnrollment(null)}}
			  afterCloseFn={search} selectedEnrollment={selectedEnrollment} profileViewingFn={setSelectedUser}
			/>
		}
		{
			selectedUser == null ? "" : <UserPrompt promptCloseFn={() => setSelectedUser(null)} selectedUser={selectedUser}
			  employeeView={selectedUser.employeeView}
			/>
		}
		{
			selectedClassroom == null ? "" : <ClassroomPrompt
			  closeFn={() => setSelectedClassroom(null)}
			  selectedClassroom={selectedClassroom}
			/>
		}
		{
			selectedSchedule == null ? "" : <DataDisplayModal headIcon={<FontAwesomeIcon icon={solid("list-check")} />}
			  promptCloseFn={() => setSelectedSchedule(null)} entityName={"Asistencias"} editableRecord={null} unrequiredEmphasis
			>
				<div className="card card-default" style={{paddingTop : "unset"}}>
					<div style={{background : selectedSchedule.color, width : "100%", height : "30px", borderRadius : "0px 0px 15px 15px"}} />
					<div style={{paddingTop : "15px"}}><div style={{display : "flex", flexWrap : "wrap"}}>
						<div style={{borderBottom : "1px solid #FA0000", flex : 1}}><table style={{width : "100%"}}>
							<tbody>	
								<tr><td><div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
									<div style={{flex : 1}}><h5 style={{fontWeight : "unset", color : "#8F91DA"}}>
										{selectedSchedule.level.course.name}
										&nbsp;
										{selectedSchedule.level.name}
									</h5></div>
									<div>
										<div style={{display : "inline-flex"}}>
											<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
												Horario
											</label>
											{selectedSchedule.name}
										</div>
										<div style={{display : "inline-flex"}}>
											<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
												Aula
											</label>
											{selectedSchedule.classroom.name}
										</div>
									</div>
								</div></td></tr>
							</tbody>
						</table></div>
					</div></div>
					<div style={{overflow : "auto"}}>
						<table className="specialTable">
							<thead>
								<tr>
									<td colSpan={4} className="text-center">
										Alumno
									</td>
									{
										monthRef.map
										(
											(month : string)=>
											{
												if(monthDateCount[month])
												{
													return <td colSpan={monthDateCount[month]} key={month}>
														{month}
													</td>
												}
											}
										)
									}
									<td colSpan={2} className="text-center">
										Asistencia
									</td>
								</tr>
								<tr>
									<th />
									<th style={{width : "1px"}}>
										Matrícula
									</th>
									<th>
										Nombres
									</th>
									<th>
										Apellidos
									</th>
									{
										dateList.map
										(
											(date : string) => <th key={date} style={{width : "1px"}}>
												{dateRef[date]}
											</th>
										)
									}
									<th style={{width : "1px"}}>
										Total
									</th>
									<th style={{width : "1px", textAlign : "center"}}>
										%
									</th>
								</tr>
							</thead>
							<tbody>{
								selectedSchedule.enrollments.map
								(
									(enrollment : any) => <tr key={"S_" + enrollment.student.id}>
										<td />
										<td style={{borderBottom : "1px solid #C7C7C7"}}>
											{enrollment.student.id}
										</td>
										<td>
											{enrollment.student.first_name}
										</td>
										<td>
											{enrollment.student.last_name}
										</td>
										{
											dateList.map
											(
												(date : string) => <td key={date} className="text-center"
												  style={{color : attendanceStudentRef[date + "S_" + enrollment.student.id] == attendanceStatus.ABSENT ? "#FA0000" : "#8F91DA"}}
												>{
													<FontAwesomeIcon  icon=
													  {
														attendanceStudentRef[date + "S_" + enrollment.student.id] == null
																?
															regular("square")
																:
															(
																attendanceStudentRef[date + "S_" + enrollment.student.id] == attendanceStatus.COMPENSATION
																		?
																	solid("bandage")
																		:
																	(
																		attendanceStudentRef[date + "S_" + enrollment.student.id] == attendanceStatus.ABSENT
																				?
																			regular("square-minus")
																				:
																			regular("square-check")
																	)
															)
													  }
													/>
												}</td>
											)
										}
										<td className="text-end"><div style={{display : "flex"}}>
											<div style={{flex : 1}}>
												{attendanceCountRef["S_" + enrollment.student.id]}
											</div>
											<div style={{padding : "0px 5px"}}>
												/
											</div>
											<div>
												{dateCount}
											</div>
										</div></td>
										<td className="text-end" style={{textAlign : "right"}}
										  ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}
										><div style={{display : "flex"}}>
											<div style={{flex : 1}}>{
												~~(
													(
														attendanceCountRef["S_" + enrollment.student.id]
															/ dateCount
													)
													* 100
												)
											}</div>
											<div style={{paddingLeft : "5px"}}>
												%
											</div>
										</div></td>
									</tr>
								)
							}</tbody>
						</table>
					</div>
				</div>
			</DataDisplayModal>
		}
		{
			tuitionFinance == null ? "" : <DataDisplayModal promptCloseFn={() => setTuitionFinance(null)} editableRecord={null}
			  entityName={"Colegiaturas"} headIcon={<CustomIcon name="tuition" />} unrequiredEmphasis
			>
				<div style={{display : "flex", flexWrap : "wrap"}}><div style={{borderBottom : "1px solid #FA0000", flex : 1}}>
					<table style={{width : "100%"}}><tbody><tr><td>
					<div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
						<div style={{flex : 1}}><h5 style={{fontWeight : "unset", color : "#8F91DA"}}>
						{tuitionFinance.schedule.level.course.name}
						&nbsp;
						{tuitionFinance.schedule.level.name}
					</h5></div>
					<div>
						<div style={{display : "inline-flex"}}>
							<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
								Horario
							</label>
							{tuitionFinance.schedule.name}
						</div>
						<div style={{display : "inline-flex"}}>
							<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
								Aula
							</label>
							{tuitionFinance.schedule.classroom.name}
						</div>
					</div>
				</div></td></tr></tbody></table></div></div>
				{
					(tuitionFinance.records.length < 1)
						?
					<>
						<hr />
						<div style={{padding : "25px"}}>
							Sin resultados
						</div>
						<hr />
					</>
						:
					<table className="commonTable">
						<thead><tr key="periodsHeader">
							<th ref={el=>{if(el)el.style.setProperty("border-bottom", "1px solid #000000", "important")}}>
								id
							</th>
							<th>
								Alumno
							</th>
							<th>
								Factura
							</th>
							<th>
								Concepto
							</th>
							<th>
								{Constants.Common.price}
							</th>
							<th>
								Descuento
							</th>
							<th>
								{Constants.Common.tax}
							</th>
							<th>
								Sub-total
							</th>
							<th>
								Pagado
							</th>
							<th ref={el=>{if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
								Estatus
							</th>
						</tr></thead>
						<tbody>{
							tuitionFinance.records.map
							(
								(record : any, index : number)=>
								{
									let recordAmount               : number  = +record.amount
									let recordTaxId                : number  = record.tax_id || 0
									let recordTaxInPercentage      : boolean = record.tax_value.substr(-1) == "%"
									let recordDiscountInPercentage : boolean = record.discount.substr(-1) == "%"
									let recordTaxValue             : number  = recordTaxId < 1 ? 0 : +(record.tax_value.replace("%", ""))
									let recordDiscountValue        : number  = +(record.discount.replace("%", ""))
									let recordTaxAmount            : number  = recordTaxValue == 0 ? 0 : (!recordTaxInPercentage ? recordTaxValue : recordAmount * (recordTaxValue / 100))
									let recordDiscountAmount       : number  = (!recordDiscountInPercentage ? recordDiscountValue : recordAmount * (recordDiscountValue / 100))
									let recordSubTotal             : number  = record.amount + recordTaxAmount - recordDiscountAmount

									return <tr key={"period" + index}>
										<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
											{record.id}
										</td>
										<td>
											{record.student && (record.student.first_name + " " + record.student.last_name)}
										</td>
										<td style={{textAlign : "right"}}>
											{record.invoice_id}
										</td>
										<td>
											{record.title}
											{
												record.description == null ? "" : <>
													<div style={{padding : "0px 25px"}}>
														<hr style={{margin : "unset"}} />
													</div>
													<label style={{position : "relative"}}>
														{record.description}
													</label>
												</>
											}
										</td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +record.amount)}
											</span>
										</div></td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}>
											{
												recordDiscountAmount == 0 || !recordDiscountInPercentage ? "" : <div
												  style={{textAlign : "right"}}
												>
													{"(" + sprintf("%.02f", +recordDiscountValue) + " " + (recordDiscountInPercentage ? "%" : currencyCode) + ")"}
												</div>
											}
											<div style={{display : "flex", alignItems : "stretch"}}>
												<span style={{paddingRight : "5px"}}>
													{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
												</span>
												<span style={{flexGrow : 1}}>
													{sprintf(" %.02f", +recordDiscountAmount)}
												</span>
											</div>
										</td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}>
											{
												recordTaxAmount == 0 || !recordTaxInPercentage ? "" : <div
												  style={{textAlign : "right", display : "flex"}}
												>
													{"(" + sprintf("%.02f", +recordTaxValue) + " " + (recordTaxInPercentage ? "%" : currencyCode) + ")"}
												</div>
											}
											<div style={{display : "flex", alignItems : "stretch"}}>
												<span style={{paddingRight : "5px"}}>
													{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
												</span>
												<span style={{flexGrow : 1}}>
													{sprintf(" %.02f", +recordTaxAmount)}
												</span>
											</div>
										</td>
										<td style={{verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +recordSubTotal)}
											</span>
										</div></td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +record.paid_amount)}
											</span>
										</div></td>
										<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}
										  style={{textAlign : "right", verticalAlign : "bottom"}}
										>
											{tuitionStatusRef["TS_" + record.status_id].name}
										</td>
									</tr>
								}
							)
						}
					</tbody>
				</table>
			}</DataDisplayModal>
		}
		{
			kitFinance == null ? "" : <DataDisplayModal headIcon={<CustomIcon name="kit" />} promptCloseFn={() => setKitFinance(null)}
			  entityName={"Kits"} editableRecord={null} unrequiredEmphasis
			>
				<div style={{display : "flex", flexWrap : "wrap"}}><div style={{borderBottom : "1px solid #FA0000", flex : 1}}>
					<table style={{width : "100%"}}><tbody><tr><td><div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
						<div style={{flex : 1}}><h5 style={{fontWeight : "unset", color : "#8F91DA"}}>
							{kitFinance.schedule.level.course.name}
							&nbsp;
							{kitFinance.schedule.level.name}
						</h5></div>
						<div>
							<div style={{display : "inline-flex"}}>
								<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
									Horario
								</label>
								{kitFinance.schedule.name}
							</div>
							<div style={{display : "inline-flex"}}>
								<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
									Aula
								</label>
								{kitFinance.schedule.classroom.name}
							</div>
						</div>
					</div></td></tr></tbody></table></div>
				</div>
				{
					(kitFinance.records.length < 1)
						?
					<>
						<hr />
						<div style={{padding : "25px"}}>
							Sin resultados
						</div>
						<hr />
					</>
						:
					<table className="commonTable">
						<thead><tr key="kitsHeader">
							<th ref={el=>{if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
								id
							</th>
							<th>
								Alumno
							</th>
							<th>
								Factura
							</th>
							<th>
								Concepto
							</th>
							<th>
								Cantidad
							</th>
							<th>
								Precio
							</th>
							<th>
								{Constants.Common.price}
							</th>
							<th>
								Descuento
							</th>
							<th>
								{Constants.Common.tax}
							</th>
							<th>
								Sub-total
							</th>
							<th>
								Pagado
							</th>
							<th>
								Estatus
							</th>
						</tr></thead>
						<tbody>{
							kitFinance.records.map
							(
								(record : any, index : number)=>
								{
									return <tr key={"period" + index}>
										<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
											{record.id}
										</td>
										<td>
											{record.student && (record.student.first_name + " " + record.student.last_name)}
										</td>
										<td style={{textAlign : "right"}}>
											{record.invoice_id}
										</td>
										<td>
											{record.title}
											{
												record.description == null ? "" : <>
													<div style={{padding : "0px 25px"}}>
														<hr style={{margin : "unset"}} />
													</div>
													<label style={{position : "relative"}}>
														{record.description}
													</label>
												</>
											}
										</td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}>
											{record.quantity}
										</td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +record.price)}
											</span>
										</div></td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +record.amount)}
											</span>
										</div></td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}>
											{
												record.discountAmount > 0 && record.discountInPercentage
													?
													<div style={{textAlign : "right"}}>
														{"(" + sprintf("%.02f", +record.discountValue) + " " + (record.discountInPercentage ? "%" : currencyCode) + ")"}
													</div>
														:
													""
											}
											<div style={{display : "flex", alignItems : "stretch"}}>
												<span style={{paddingRight : "5px"}}>
													{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
												</span>
												<span style={{flexGrow : 1}}>
													{sprintf(" %.02f", +record.discountAmount)}
												</span>
											</div>
										</td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}>
											{
												record.taxAmount > 0 && record.taxInPercentage
													?
													<div style={{textAlign : "right"}}>
														{"(" + sprintf("%.02f", +record.taxValue) + " " + (record.taxInPercentage ? "%" : currencyCode) + ")"}
													</div>
														:
													""
											}
											<div style={{display : "flex", alignItems : "stretch"}}>
												<span style={{paddingRight : "5px"}}>
													{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
												</span>
												<span style={{flexGrow : 1}}>
													{sprintf(" %.02f", +record.taxAmount)}
												</span>
											</div>
										</td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +record.subTotal)}
											</span>
										</div></td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +record.paid_amount)}
											</span>
										</div></td>
										<td style={{textAlign : "right", verticalAlign : "bottom"}}
										  ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}
										>
											{kitStatusRef["KS_" + record.status_id].name}
										</td>
									</tr>
								}
							)
						}
					</tbody>
				</table>
			}</DataDisplayModal>
		}
	</>
}

export default DailyView
