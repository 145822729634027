import { CSSProperties } from "react"
import Constants from "../../../../constants"

const tableHeaderStyle: CSSProperties = {
    textAlign: "left",
    minWidth: "150px",
    color: "black",
    borderBottom: "1px solid black",
    font: Constants.Styles.text.fonts.robotoBold14
}

export const styles = {
    tableHeaderStyle
}