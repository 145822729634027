import "../../../assets/css/variables.css";
import { CSSProperties } from "react";

export const styles  ={
    modal: {
        v: {
            overlay: {
                zIndex: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "start"
            },
            content: {
                height: 'min-content',
                background: '#FAFAFB',
                borderRadius: '15px',
                padding: "0",
                overflow: "hidden",
                inset: "unset",
                border: "none",
                boxShadow: "0px 0px 10px -5px rgba(0, 0, 0, 0.75)"
            }
        },
        container: {
            v: <CSSProperties> {
                flexDirection: "column",
                display: "flex"
            },
            header: {
                v: <CSSProperties> {
                    display: "flex",
                    padding: "24px",
                },
                title: {
                    v: <CSSProperties> {
                        display: "flex",
                        color: "var(--txt-color)",
                        width: "100%",
                    }
                },
                close: {
                    v: <CSSProperties> {
                        display: "flex",
                        verticalAlign: "top"
                    },
                    button: {
                        v: <CSSProperties> {
                            color: "var(--txt-color)",
                            background: "var(--main-bg)",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            border: "1px solid #CCCCCC",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            left: "1px",
                            position: "relative"
                        }
                    }
                }
            },
            buttons: {
                v: <CSSProperties> {
                    display : "inline-block",
                    zIndex : 2,
                    height: "0px",
                    position: "relative",
                    padding: "0 32px",
                },
                content: {
                    v : <CSSProperties> {
                        transform: "translate( 0 , -50%)",
                        display : "flex"
                    },
                    hex: {
                        content: {
                            v: <CSSProperties> {
                                textAlign: "right",
                                position: "sticky",
                                left: "0px"
                            },
                            buttonList: {
                                v: <CSSProperties> {
                                    display : "inline-flex"
                                },
                                cardButton: {
                                    v: <CSSProperties> {
                                        display : "inline-grid",
                                        margin : "0px 5px",
                                        width : "fit-content"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            body: {
                v: <CSSProperties> {
                    padding: "32px",
                    border: "1px solid rgba(0, 0, 0, 0.175)",
                    borderRadius: "15px",
                    background: '#FFF'
                },
                content: {
                    v: <CSSProperties> {
                        width: "100%",
                        maxHeight: "70vh",
                        minHeight: "50vh",
                        display: "flex",
                        flexDirection: "column",
                        overflowX: "hidden",
                        overflowY: "auto",
                    }
                }
            }
        }
    }
}