import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"

import {isNotEmpty, currency, removeRecords}
	from "./../../common"

const API_URL      = process.env.REACT_APP_API_URL
const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/"

class CourseLevelsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchLevels(campus : any, search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(search)
		{
			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(search.levelStatusId >= 0)
			{
				params.push("levelStatusId=" + search.levelStatusId)
			}

			if(search.categoryId > 0)
			{
				params.push("categoryId=" + search.categoryId)
			}

			if(isNotEmpty(search.mentors))
			{
				params.push("mentorIds=" + _.map(search.mentors, "id").join(","))
			}

			if(isNotEmpty(search.courses))
			{
				params.push("courseIds=" + _.map(search.courses, "id").join(","))
			}

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if(isNotEmpty(search.mentorRef))
			{
				params.push("mentorIds=" + _.map(Object.values(search.mentorRef), "id").join(","))
			}
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + "campus/" + campus.id + "/levels" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	saveLevel(campus : any, selectedLevel : any, editedLevel : any, mentorProcedures : any, kitProcedures : any, assignmentRefs : any)
	{
		const saveFunction = editedLevel.id == null ? this.registerLevel : this.updateLevel

		let record : any=
		{
			courseId        : editedLevel.course_id,
			name            : editedLevel.name,
			code            : editedLevel.code,
			statusId        : editedLevel.status_id,
			sessionMinHours : editedLevel.session_min_hours,
			weekHours       : editedLevel.week_hours,
			weekMinDays     : editedLevel.week_min_days,
			weekMaxDays     : editedLevel.week_max_days,
			mentors         : mentorProcedures,
			kits            : kitProcedures,
			assignments     : assignmentRefs
		}

		if(campus == null)
		{
			record.subscriptionBasePriceMXN           = editedLevel.subscription_base_price_mxn
			record.subscriptionBasePriceHNL           = editedLevel.subscription_base_price_hnl
			record.subscriptionBasePriceUSD           = editedLevel.subscription_base_price_usd
			record.monthlyBillingBasePriceMXN         = editedLevel.monthly_billing_base_price_mxn
			record.monthlyBillingBasePriceHNL         = editedLevel.monthly_billing_base_price_hnl
			record.monthlyBillingBasePriceUSD         = editedLevel.monthly_billing_base_price_usd
			record.subscriptionSurchargeBaseCostMXN   = editedLevel.subscription_surcharge_base_cost_mxn
			record.subscriptionSurchargeBaseCostHNL   = editedLevel.subscription_surcharge_base_cost_hnl
			record.subscriptionSurchargeBaseCostUSD   = editedLevel.subscription_surcharge_base_cost_usd
			record.monthlyBillingSurchargeBaseCostMXN = editedLevel.monthly_billing_surcharge_base_cost_mxn
			record.monthlyBillingSurchargeBaseCostHNL = editedLevel.monthly_billing_surcharge_base_cost_hnl
			record.monthlyBillingSurchargeBaseCostUSD = editedLevel.monthly_billing_surcharge_base_cost_usd
			record.secondSurchargeBaseCostMXN         = editedLevel.second_surcharge_base_cost_mxn
			record.secondSurchargeBaseCostHNL         = editedLevel.second_surcharge_base_cost_hnl
			record.secondSurchargeBaseCostUSD         = editedLevel.second_surcharge_base_cost_usd
		}
		else
		{
			switch(campus.company.currency_id)
			{
				case currency.MXN:
					record.subscriptionBasePriceMXN           = editedLevel.subscription_base_price_mxn
					record.monthlyBillingBasePriceMXN         = editedLevel.monthly_billing_base_price_mxn
					record.subscriptionSurchargeBaseCostMXN   = editedLevel.subscription_surcharge_base_cost_mxn
					record.monthlyBillingSurchargeBaseCostMXN = editedLevel.monthly_billing_surcharge_base_cost_mxn
					record.secondSurchargeBaseCostMXN         = editedLevel.second_surcharge_base_cost_mxn
				break;
				case currency.HNL:
					record.subscriptionBasePriceHNL           = editedLevel.subscription_base_price_hnl
					record.monthlyBillingBasePriceHNL         = editedLevel.monthly_billing_base_price_hnl
					record.subscriptionSurchargeBaseCostHNL   = editedLevel.subscription_surcharge_base_cost_hnl
					record.monthlyBillingSurchargeBaseCostHNL = editedLevel.monthly_billing_surcharge_base_cost_hnl
					record.secondSurchargeBaseCostHNL         = editedLevel.second_surcharge_base_cost_hnl
				break;
				case currency.USD:
					record.subscriptionBasePriceUSD           = editedLevel.subscription_base_price_usd
					record.monthlyBillingBasePriceUSD         = editedLevel.monthly_billing_base_price_usd
					record.subscriptionSurchargeBaseCostUSD   = editedLevel.subscription_surcharge_base_cost_usd
					record.monthlyBillingSurchargeBaseCostUSD = editedLevel.monthly_billing_surcharge_base_cost_usd
					record.secondSurchargeBaseCostUSD         = editedLevel.second_surcharge_base_cost_usd
				break;
			}
		}

		if(isNotEmpty(editedLevel.description))
		{
			record.description = editedLevel.description
		}

		return saveFunction
		(
			campus, selectedLevel.course_id, record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			editedLevel.id
		)
	}

	async registerLevel(campus : any, courseId : number, registry : any, options : any)
	{
		return axios.post
		(
			RESOURCE_URL + (campus == null ? "" : "campus/" + campus.id + "/") + "courses/" + courseId + "/levels",
			registry,
			options
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async updateLevel(campus : any, courseId : number, registry : any, options : any, id : number)
	{
		return axios.put
		(
			RESOURCE_URL + (campus == null ? "" : "campus/" + campus.id + "/") + "courses/" + courseId + "/levels/" + id,
			registry,
			options
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async removeLevels(campusId : number, recordIds? : any)
	{
		return removeRecords(RESOURCE_URL + "campus/" + campusId + "/levels", recordIds)
	}
}

export default new CourseLevelsService()